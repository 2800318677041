(function() {
    'use strict';

    angular
        .module('app')
        .component('uploadContratosAutentique', {
            templateUrl: 'app/components/uploadContratosAutentique/uploadContratosAutentique.template.html',
            bindings: {
                dados: '=',
                local: '=',
                callbackAtd: '&'
            },
            controller: function($element, $filter, $timeout, API, $http, $window, RC, modelosEmailService) {

                const $ctrl = this;

                $ctrl.userData = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));

                /*console.log("$ctrl.dados");
                console.log($ctrl.dados);*/

                $ctrl.buscarDocs = function() {
                    $ctrl.contratoUploaded = [];
                    $ctrl.loadingContratosUploaded = true;
                    var dados = {
                        'user_hash': Cookies.get('crm_funil_hash'),
                        'id': $ctrl.dados.id,
                        'tipo': $ctrl.dados.cliente_empresa.tipo,
                        'cliente_empresa': $ctrl.dados.cliente_empresa.id,
                    }
                    $http.post(API.url + 'app/components/uploadContratosAutentique/service.php?f=buscarDocs', dados).then(function(response) {
                        console.log("buscarDocs");
                        console.log(response.data);
                        //return null;
                        if (response.data.documento) {
                            $timeout(function() {
                                $ctrl.contratoUploaded = response.data;
                                $ctrl.loadingContratosUploaded = false;
                                $('[data-toggle="tooltip"]').tooltip();
                            });
                        } else {
                            $ctrl.contratoUploaded = [];
                            $ctrl.loadingContratosUploaded = false;
                        }
                    }, function(error) {
                        console.log("error");
                        console.error(error);
                    });
                }

                function fileValidation() {
                    var fileInput = document.getElementById('file-upload-autentique');
                    var filePath = fileInput.value;
                    var allowedExtensions = /(\.pdf|\.txt|\.doc|\.docx|\.xls|\.xlsx|\.ppt|\.pptx|\.odt|\.ods|\.odp|\.rtf|\.html)$/i;
                    if (!allowedExtensions.exec(filePath)) {
                        return false;
                    } else {
                        return true
                    }
                }

                $ctrl.initWatchSelectFile = function() {
                    $('#file-upload-autentique').change(function() {
                        if (fileValidation()) {
                            var file = document.getElementById('file-upload-autentique').files[0];
                            $("#nome-contrato-autentique").html(file.name);
                            $(".box_contrato_selecionado").fadeIn();
                            $(".text_formato_arquivo").hide();
                            $(".text_nenhum_arquivo").hide();
                            $(".button_upload span").html('Alterar documento');
                        } else {
                            $(".box_contrato_selecionado").hide();
                            $(".text_formato_arquivo").fadeIn();
                        }
                    });
                }

                $ctrl.acabouFiles = function() {
                    $timeout(function() {
                        $ctrl.loadingContratosUploaded = false;
                        $('[data-toggle="tooltip"]').tooltip();
                    });
                }

                $ctrl.clickSelectFiles = function() {
                    $('#file-upload-autentique').val('');
                    $('#file-upload-autentique').trigger('click');
                }

                $ctrl.cancelarModalContrato = function() {
                    const file = document.getElementById('file-upload-autentique').files[0];
                    if (file || $ctrl.enviarEmail.para.length || $ctrl.diasVencimento) {
                        swal({
                                title: 'Atenção!</br>Dados não salvo serão perdidos.',
                                text: 'Deseja continuar?',
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#DD6B55',
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não',
                                closeOnConfirm: true,
                                html: true
                            },
                            function() {
                                $window.onkeydown = null;
                                $window.onfocus = null;
                                $("#modalNovoContrato").modal('hide');
                            });
                    } else {
                        $("#modalNovoContrato").modal('hide');
                    }
                }

                $ctrl.cadastrarContrato = function() {
                    $ctrl.enviandoContrato = true;
                    const file = document.getElementById('file-upload-autentique').files[0];
                    var valido = false;
                    if ($ctrl.acaoAssinatura == 'link') {
                        if (file && $ctrl.diasVencimento) {
                            valido = true;
                        } else {
                            if (!file) {
                                $(".text_nenhum_arquivo").fadeIn();
                            }
                            if (!$ctrl.diasVencimento) {
                                $ctrl.validar.vencimento = true;
                            }
                            $ctrl.enviandoContrato = false;
                            valido = false;
                        }
                    } else {
                        if (file && $ctrl.enviarEmail.para.length && $ctrl.diasVencimento) {
                            valido = true;
                        } else {
                            if (!file) {
                                $(".text_nenhum_arquivo").fadeIn();
                            }
                            if (!$ctrl.enviarEmail.para.length) {
                                $ctrl.validar.para = true;
                            }
                            if (!$ctrl.diasVencimento) {
                                $ctrl.validar.vencimento = true;
                            }
                            $ctrl.enviandoContrato = false;
                            valido = false;
                        }
                    }

                    if (valido) {
                        $(".text_nenhum_arquivo").hide();
                        $ctrl.validar.para = false;
                        $(".txt_vencimento").hide();

                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('user_hash', Cookies.get('crm_funil_hash'));
                        formData.append('id', $ctrl.dados.id);
                        formData.append('tipo', $ctrl.dados.cliente_empresa.tipo);
                        formData.append('cliente_empresa', $ctrl.dados.cliente_empresa.id);
                        formData.append('nome_cli', $ctrl.dados.cliente_empresa.nome);
                        formData.append('emails', JSON.stringify($ctrl.enviarEmail.para));
                        formData.append('vencimento', $ctrl.diasVencimento);
                        formData.append('acao_assinatura', $ctrl.acaoAssinatura);

                        $http({
                                url: API.url + 'app/components/uploadContratosAutentique/service.php?f=cadastrarContrato',
                                method: 'POST',
                                data: formData,
                                headers: {
                                    'Content-Type': undefined
                                    //'Content-Type': 'application/json'
                                },
                                transformRequest: angular.identity,
                            })
                            .then((result) => {
                                console.log("cadastrarContrato");
                                console.log(result.data);
                                /*$ctrl.enviandoContrato = false;
                                return null;*/
                                if ($ctrl.acaoAssinatura == 'link') {
                                    swal({
                                        title: 'Cadastrado com sucesso!',
                                        type: 'success',
                                        text: 'Documento cadastrado com sucesso!<br><b>Acesse o menu no card do documento para copiar o link.</b>',
                                        showConfirmButton: true,
                                        html: true
                                    });
                                } else {
                                    swal({
                                        title: 'Cadastrado com sucesso!',
                                        type: 'success',
                                        text: 'Documento cadastrado com sucesso!',
                                        showConfirmButton: false,
                                        timer: 1000
                                    });
                                }
                                $timeout(function() {
                                    $("#modalNovoContrato").modal('hide');
                                    $ctrl.enviandoContrato = false;
                                }, 1000);
                                $ctrl.buscarDocs();
                                $timeout(function() {
                                    $("#file-upload-autentique").val('');
                                }, 100);
                            })
                            .catch((error) => {
                                console.log("error");
                                console.error(error);
                            })
                    }
                }

                $ctrl.removerContrato = function(contrato) {
                    swal({
                            title: 'Deseja remover este documento?',
                            text: 'Esta ação é irreversível',
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            closeOnConfirm: false,
                            showLoaderOnConfirm: true
                        },
                        function() {
                            $window.onkeydown = null;
                            $window.onfocus = null;
                            var dados = {
                                'contrato': contrato,
                                'user_hash': Cookies.get('crm_funil_hash')
                            }
                            $http.post(API.url + 'app/components/uploadContratosAutentique/service.php?f=removerContrato', dados).then(function(response) {
                                console.log("removerContrato");
                                console.log(response.data);
                                //return null;
                                swal({
                                    title: 'Removido com sucesso!',
                                    type: 'success',
                                    text: 'Documento removido com sucesso!',
                                    showConfirmButton: false,
                                    timer: 1000
                                });
                                $ctrl.buscarDocs();
                            }, function(error) {
                                console.log("error");
                                console.error(error);
                            });
                        });
                }

                $ctrl.reenviarContrato = function(contrato) {
                    swal({
                            title: 'Deseja reenviar o documento para assinatura?',
                            text: 'Reenvia um email de assinatura para os signatários que não assinaram/rejeitaram.',
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            closeOnConfirm: false,
                            showLoaderOnConfirm: true
                        },
                        function() {
                            $window.onkeydown = null;
                            $window.onfocus = null;
                            var dados = {
                                'contrato': contrato,
                                'user_hash': Cookies.get('crm_funil_hash')
                            }
                            $http.post(API.url + 'app/components/uploadContratosAutentique/service.php?f=reenviarContrato', dados).then(function(response) {
                                console.log("reenviarContrato");
                                console.log(response.data);
                                //return null;
                                swal({
                                    title: 'Reenviado com sucesso!',
                                    type: 'success',
                                    text: 'Documento enviado com sucesso!',
                                    showConfirmButton: false,
                                    timer: 1000
                                });
                            }, function(error) {
                                console.log("error");
                                console.error(error);
                            });
                        });
                }

                $ctrl.copiarLinkAssinatura = function(contrato) {
                    var temp = $("<input>");
                    $("body").append(temp);
                    temp.val('https://painel.autentique.com.br/assinar/' + contrato.partes[0].link.uuid);
                    temp.select();
                    document.execCommand("copy");
                    temp.remove();
                }

                $ctrl.modalNovoContrato = function(f) {
                    $ctrl.enviarEmail = [];
                    $ctrl.validar = [];

                    $timeout(function() {
                        $ctrl.enviarEmail.para = [];
                        $ctrl.emailAdicionados = [];
                        $ctrl.emails = angular.copy($ctrl.emailsTodos);
                        carregar();
                        $(".box_contrato_selecionado").hide();
                        $("#nome-contrato-autentique").html('');
                        $(".text_formato_arquivo").hide();
                        $(".text_nenhum_arquivo").hide();
                        $(".button_upload span").html('Selecionar documento');
                        $ctrl.diasVencimento = '';
                        $ctrl.acaoAssinatura = 'email';

                        $('[data-toggle="tooltip"]').tooltip('hide');
                    });

                    $timeout(function() {
                        $("html").addClass("remover-scroll");
                    });

                    var modalContrato = $("#modalNovoContrato");
                    modalContrato.modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                    if (modalContrato.parent().get(0).tagName != 'BODY') {
                        $('.modal-backdrop').insertAfter(modalContrato);
                    }
                }
                //$ctrl.modalNovoContrato();

                $('#modalNovoContrato').on('hide.bs.modal', function(event) {
                    $timeout(function() {
                        $("html").removeClass("remover-scroll");
                    });
                });

                $ctrl.isDocumentSigned = function(document) {
                    return (document.partes || []).length > 0 &&
                        $filter('filter')(document.partes, { assinado: { created: '' } }).length === document.partes.length;
                };

                $ctrl.isSignRejected = function(sign) {
                    return (sign.rejeitado || {}).created ||
                        (!(sign.mail || {}).delivered && !(sign.visualizado || {}).created && (sign.mail || {}).refused);
                };

                $ctrl.isDocumentRejected = function(document) {
                    var isRejected = false;
                    for (var i = 0; i < (document.partes || []).length; i++) {
                        if ($ctrl.isSignRejected(document.partes[i])) {
                            isRejected = true;
                            break;
                        }
                    }
                    return isRejected;
                };

                $ctrl.signed = function(document) {
                    return $filter('filter')(document.partes, { assinado: { created: '' } });
                };

                $ctrl.countAssinar = function(document) {
                    if (document.partes) {
                        return $filter('filter')(document.partes, { assinado: { created: '' } }).length;
                    } else {
                        return null;
                    }
                };

                $ctrl.modalStatusPartes = function() {

                    var modalStatusPartes = $("#modalStatusPartes");
                    modalStatusPartes.modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                    if (modalStatusPartes.parent().get(0).tagName != 'BODY') {
                        $('.modal-backdrop').insertAfter(modalStatusPartes);
                    }
                }

                $ctrl.formatData = function(data) {
                    return moment(data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
                }

                $ctrl.getBuscarEmails = function(cliente_empresa) {
                    var dados = {
                        'id': $ctrl.dados.cliente_empresa.id,
                        'tipo': $ctrl.dados.cliente_empresa.tipo
                    };
                    modelosEmailService.buscarEmails(dados).then(function(response) {
                        /*console.log("buscarEmails");
                        console.log(response.data);*/
                        if (response.data != 0) {
                            $timeout(function() {
                                $ctrl.emails = response.data;
                                $ctrl.emailsTodos = angular.copy($ctrl.emails);
                                carregar();
                            });
                        } else {
                            $timeout(function() {
                                $ctrl.emails = '';
                                carregar();
                            });
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                }
                $ctrl.getBuscarEmails();

                var self = this;

                function carregar() {
                    self.states = loadAll();
                    self.selectedItem = '';
                    self.selectedItem2 = '';
                    self.selectedItem3 = '';
                    self.searchText = '';
                    self.searchText2 = '';
                    self.searchText3 = '';
                    self.querySearch = querySearch;
                    self.selectedItemChange = selectedItemChange;
                    self.transformChip = transformChip;
                    self.removeChip = removeChip;

                    self.emailsPara = [];
                    self.emailsCc = [];
                    self.emailsCco = [];
                }

                $ctrl.emailAdicionados = [];

                function transformChip(qual, chip) {
                    // If it is an object, it's already a known chip
                    if (angular.isObject(chip)) {
                        $timeout(function() {
                            if (qual == 1) { // PARA
                                $ctrl.enviarEmail.para = angular.copy(self.emailsPara);
                                $ctrl.validar.para = false;
                                $ctrl.emailAdicionados.push(chip.email);
                            } else if (qual == 2) { // Cc
                                $ctrl.enviarEmail.cc = angular.copy(self.emailsCc);
                                $ctrl.emailAdicionados.push(chip.email);
                            } else if (qual == 3) { // Cco
                                $ctrl.enviarEmail.cco = angular.copy(self.emailsCco);
                                $ctrl.emailAdicionados.push(chip.email);
                            }
                        });

                        // REMOVER E-MAIL ADICIONADO DA LISTA
                        $ctrl.emails = $ctrl.emails.filter(function(e) {
                            return e.email !== chip.email
                        })
                        return chip;
                    }

                    // Otherwise, create a new one
                    return false;
                }

                function removeChip(qual, chip) {
                    if (qual == 1) { // PARA
                        var index = '';
                        angular.forEach($ctrl.enviarEmail.para, function(value, key) {
                            if (value.email == chip.email) {
                                index = key;
                            }
                        });
                        $ctrl.enviarEmail.para.splice(index, 1);
                    } else if (qual == 2) { // Cc
                        var index = '';
                        angular.forEach($ctrl.enviarEmail.cc, function(value, key) {
                            if (value.email == chip.email) {
                                index = key;
                            }
                        });
                        $ctrl.enviarEmail.cc.splice(index, 1);
                    } else if (qual == 3) { // Cco
                        var index = '';
                        angular.forEach($ctrl.enviarEmail.cco, function(value, key) {
                            if (value.email == chip.email) {
                                index = key;
                            }
                        });
                        $ctrl.enviarEmail.cco.splice(index, 1);
                    }

                    $ctrl.emailAdicionados = $ctrl.emailAdicionados.filter(function(e) {
                        return e !== chip.email
                    })

                    $ctrl.emails = angular.copy($ctrl.emailsTodos);
                    angular.forEach($ctrl.emailAdicionados, function(value, key) {
                        $ctrl.emails = $ctrl.emails.filter(function(e) {
                            return e.email !== value
                        })
                    })

                }

                function selectedItemChange(item) {
                    if (item) {
                        /*$ctrl.enviarEmail.para = [];
                        $ctrl.enviarEmail.para.push(item);
                        $ctrl.validar.para = false;*/
                    } else {
                        //$ctrl.enviarEmail.para = [];
                    }
                }

                function querySearch(query) {
                    var results = query ? self.states.filter(createFilterFor(query)) : self.states,
                        deferred;
                    return results;
                }

                function loadAll() {
                    self.states = $ctrl.emails;
                    var emails = $ctrl.emails;
                    return angular.forEach(emails, function(values, key) {
                        return {
                            value: values.id_email,
                            display: values.email
                        };
                    });
                }

                function createFilterFor(query) {
                    var lowercaseQuery = angular.lowercase(query);
                    return function filterFn(state) {
                        return (state.email.toLowerCase().indexOf(lowercaseQuery) == 0);
                    };
                }

            }
        })
}());