/*
* @Author: Glauber Funez
* @Date:   2018-03-19 09:42:10
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:52
*/

(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelAtividadesController', gerarRelAtividadesController);


  gerarRelAtividadesController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'PessoasService', 'produtosService', 'compromissosService', 'FactoryRoles', 'usuariosService'];

  function gerarRelAtividadesController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas, PessoasService, produtosService, compromissosService, FactoryRoles, usuariosService) {

        var Base64 = {
          _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
          encode: function(e) {
            var t = '';
            var n, r, i, s, o, u, a;
            var f = 0;
            e = Base64._utf8_encode(e);
            while (f < e.length) {
              n = e.charCodeAt(f++);
              r = e.charCodeAt(f++);
              i = e.charCodeAt(f++);
              s = n >> 2;
              o = (n & 3) << 4 | r >> 4;
              u = (r & 15) << 2 | i >> 6;
              a = i & 63;
              if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
              t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
            }
            return t;
          },
          decode: function(e) {
            var t = '';
            var n, r, i;
            var s, o, u, a;
            var f = 0;
            e = e.replace(/[^A-Za-z0-9+/=]/g, '');
            while (f < e.length) {
              s = this._keyStr.indexOf(e.charAt(f++));
              o = this._keyStr.indexOf(e.charAt(f++));
              u = this._keyStr.indexOf(e.charAt(f++));
              a = this._keyStr.indexOf(e.charAt(f++));
              n = s << 2 | o >> 4;
              r = (o & 15) << 4 | u >> 2;
              i = (u & 3) << 6 | a;
              t = t + String.fromCharCode(n);
              if (u != 64) { t = t + String.fromCharCode(r); }
              if (a != 64) { t = t + String.fromCharCode(i); }
            }
            t = Base64._utf8_decode(t);
            return t;
          },
          _utf8_encode: function(e) {
            e = e.replace(/rn/g, 'n');
            var t = '';
            for (var n = 0; n < e.length; n++) {
              var r = e.charCodeAt(n);
              if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                t += String.fromCharCode(r >> 6 | 192);
                t += String.fromCharCode(r & 63 | 128);
              } else {
                t += String.fromCharCode(r >> 12 | 224);
                t += String.fromCharCode(r >> 6 & 63 | 128);
                t += String.fromCharCode(r & 63 | 128);
              }
            }
            return t;
          },
          _utf8_decode: function(e) {
            var t = '';
            var n = 0;
            var c1 = 0;
            var c2 = 0;
            var r = c1 = c2 = 0;
            while (n < e.length) {
              r = e.charCodeAt(n);
              if (r < 128) {
                t += String.fromCharCode(r);
                n++;
              } else if (r > 191 && r < 224) {
                c2 = e.charCodeAt(n + 1);
                t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                n += 2;
              } else {
                c2 = e.charCodeAt(n + 1);
                c3 = e.charCodeAt(n + 2);
                t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                n += 3;
              }
            }
            return t;
          }
        };

        var vm = this;

        vm.url = API.url;

        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.tituloPrincipal = 'Relatório de atividades do usuário';
        vm.subtitulo = 'Relatório de atividades do usuário';
        vm.carregando = true;

        vm.filtros = {
          'usuario': '',
          'situacoes': [],
          'inicio': '',
          'fim': '',
          'tipoComp': ''
        };

        vm.buscaUsuarios = function(slug) {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid
            };
            relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
              vm.usuarioMarcado = $filter('filter')(response.data.usuarios, { user_slug: slug });
            }, function(error) {
              console.error(error);
            });
          });
        };

        vm.carregarDados = function() {
          var dados = {
            'filtros': vm.filtros
          };

          if (FactoryRoles.checkRolesVisuFUNIL('isModCompromissos')) {
            relatoriosService.carregarDadosAtividadesGeral(dados).then(function(response) {
              console.log("carregarDadosAtividadesGeral");
              console.log(response.data);
              if (response.data != '' && response.data != 0) {
                vm.listagemAtividades = response.data;
              } else {
                vm.listagemAtividades = '';
              }
              vm.carregando = false;
            }, function(error) {
              console.error(error);
            });
          } else {
            vm.carregando = false;
          }
        };

        vm.buscaTipoCompFiltro = function(tipos) {
          vm.tipoCompFiltro = [];
          var dados = {
            'tipos': tipos
          }
          relatoriosService.buscaTipoCompFiltro(dados).then(function(response) {
            if (response.data != '0') {
              vm.tipoCompFiltro = response.data;
            } else {
              vm.tipoCompFiltro = [];
            }
          }, function(error) {
            $log.error(error);
          });
        }

        if ($routeParams) {
          if ($routeParams.situacoes) {
            var situacoes = $routeParams.situacoes;
            vm.filtros.situacoes = situacoes.split('-');
            vm.exibirAtendimentos = false;
            vm.exibirCompromissos = false;
            angular.forEach(vm.filtros.situacoes, function(value, key) {
              if (value == "Atendimentos") {
                vm.exibirAtendimentos = true;
              }
              if (value == "Abertos" || value == "Atrasados" || value == "Finalizados") {
                vm.exibirCompromissos = true;
              }
            })
          }
          if ($routeParams.usuario) {
            vm.filtros.usuario = $routeParams.usuario;
          }
          if ($routeParams.inicio) { // PERIODO SELECIONADO
            vm.filtros.inicio = $routeParams.inicio;
            vm.filtros.fim = $routeParams.fim;
            vm.inicioExibir = vm.filtros.inicio.replace('_', '/').replace('_', '/');
            vm.fimExibir = vm.filtros.fim.replace('_', '/').replace('_', '/');
          }
          if ($routeParams.tipoComp) {
            vm.filtros.tipoComp = $routeParams.tipoComp;
            vm.buscaTipoCompFiltro($routeParams.tipoComp);
          }
          vm.carregarDados();
          vm.buscaUsuarios($routeParams.usuario);
        }

        vm.detalharComp = function(qual) {
          $(".txt_" + qual).toggleClass("hide");
          $(".link_" + qual).toggleClass("clicado");
          $(".lista_" + qual).slideToggle();
        }

        vm.detalharAtd = function(qual) {
          $(".txt_" + qual).toggleClass("hide");
          $(".link_" + qual).toggleClass("clicado");
          $(".lista_" + qual).slideToggle();
        }

        vm.verMaisList = function(qual, slug) {
          if (qual == 'finalizados') {
            var comp = $filter('filter')(vm.listagemAtividades.COMPROMISSOS_LISTA.FINALIZADOS, { SLUGCOMP: slug });
            comp[0].LIMITE = comp[0].DESCRICAO.length;
          } else if (qual == 'abertos') {
            var comp = $filter('filter')(vm.listagemAtividades.COMPROMISSOS_LISTA.ABERTOS, { SLUGCOMP: slug });
            comp[0].LIMITE = comp[0].DESCRICAO.length;
          } else if (qual == 'atrasados') {
            var comp = $filter('filter')(vm.listagemAtividades.COMPROMISSOS_LISTA.ATRASADOS, { SLUGCOMP: slug });
            comp[0].LIMITE = comp[0].DESCRICAO.length;
          }
        }

        vm.verMaisListAtd = function(id) {
          var atd = $filter('filter')(vm.listagemAtividades.ATENDIMENTOS_LISTA, { ATENDIMENTOID: id });
          atd[0].LIMITE = atd[0].ATENDIMENTO.length;
        }

        vm.expandir_tudo = function(qual, expandir) {
          $(".expandir_" + qual).toggleClass("hide");
          if (qual == 'finalizados') {
            var comp = vm.listagemAtividades.COMPROMISSOS_LISTA.FINALIZADOS;
            if (expandir) {
              angular.forEach(comp, function(value, key) {
                value.LIMITE = value.DESCRICAO.length;
              })
            } else {
              angular.forEach(comp, function(value, key) {
                value.LIMITE = 125;
              })
            }
          } else if (qual == 'abertos') {
            var comp = vm.listagemAtividades.COMPROMISSOS_LISTA.ABERTOS;
            if (expandir) {
              angular.forEach(comp, function(value, key) {
                value.LIMITE = value.DESCRICAO.length;
              })
            } else {
              angular.forEach(comp, function(value, key) {
                value.LIMITE = 125;
              })
            }
          } else if (qual == 'atrasados') {
            var comp = vm.listagemAtividades.COMPROMISSOS_LISTA.ATRASADOS;
            if (expandir) {
              angular.forEach(comp, function(value, key) {
                value.LIMITE = value.DESCRICAO.length;
              })
            } else {
              angular.forEach(comp, function(value, key) {
                value.LIMITE = 125;
              })
            }
          }
        }

        vm.expandir_tudo_atd = function(qual, expandir) {
          $(".expandir_" + qual).toggleClass("hide");
          var atd = vm.listagemAtividades.ATENDIMENTOS_LISTA;
          if (expandir) {
            angular.forEach(atd, function(value, key) {
              value.LIMITE = value.ATENDIMENTO.length;
            })
          } else {
            angular.forEach(atd, function(value, key) {
              value.LIMITE = 125;
            })
          }
        }

        vm.acabou = function() {
          $timeout(function() {
            $('[data-toggle="tooltip"]').tooltip();
          }, 200);
        }

        vm.expandirAcc = function() {
          $('.box_filtrar_atividades').slideToggle();
        };

        /*TIPOS DE COMPROMISSO --- INÍCIO*/
        vm.buscarTiposCompromisso = function(slug_comp) {
          vm.tiposCompromissoRepeat = [];
          compromissosService.buscarTiposCompromisso().then(function(response) {
            if (response.data != '0') {
              angular.forEach(response.data, function(value, key) {
                var icon = '<img src=\'' + API.url + '/app/template/img/icones/' + value.cot_imagem_nome + '\'/>';
                vm.tiposCompromissoRepeat.push({ 'icon': icon, 'name': value.cot_nome, 'ticked': true, 'id': value.cot_id });
              });
              vm.quantTipoComp = vm.tiposCompromissoRepeat.length;
              vm.selecionadosInicioTipoComp = [];
              angular.forEach(vm.tiposCompromissoRepeat, function(value, key) {
                vm.selecionadosInicioTipoComp.push(value.id);
                vm.tipocompSelecionado = vm.selecionadosInicioTipoComp.join();
              });
              vm.todosNenhumTipoComp(vm.tipocompSelecionado, 'tipoComp');
              //vm.todosNenhumTipoComp('', 'tipoComp');
            } else {
              vm.tiposCompromissoRepeat = [];
            }
          }, function(error) {
            $log.error(error);
          });
        }
        /*TIPOS DE COMPROMISSO --- FIM*/


        /*FILTROS --- INÍCIO*/
        vm.periodo = '';
        vm.periodo_inicio = '';
        vm.periodo_fim = '';

        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.situacoesAtv = [
          { name: 'Abertos', selecionado: true },
          { name: 'Atrasados', selecionado: true },
          { name: 'Finalizados', selecionado: true },
          { name: 'Atendimentos', selecionado: true }
        ];

        vm.mudarAtv = function(qual, item) {
          if (qual) {
            vm.situacoesSelecionadasAtv = function() {
              return 'Abertos-Atrasados-Finalizados-';
            };
            angular.forEach(vm.situacoesAtv, function(item) {
              item.selecionado = true;
            });
          } else {
            vm.situacoesSelecionadasAtv = function() {
              return vm.situacoesAtv.filter(function(item) {
                return item.selecionado;
              }).map(function(item) {
                return item.name;
              }).join('-');
            };
            angular.forEach(vm.situacoesAtv, function(item) {
              item.selecionado = false;
            });
          }
        };

        vm.situacoesSelecionadasAtv = function() {
          return vm.situacoesAtv.filter(function(item) {
            return item.selecionado;
          }).map(function(item) {
            return item.name;
          }).join('-');
        };

        vm.rangePeriodo = function() {
          function cb(start, end) {
            if (start._isValid) { // datas validas
              vm.periodo_inicio = start.format('DD_MM_YYYY');
              vm.periodo_fim = end.format('DD_MM_YYYY');;
            } else {

            }
          }
          $('.campo_periodo').daterangepicker({
            //autoUpdateInput: false,
            'opens': 'center',
            'locale': {
              'format': 'DD/MM/YYYY',
              'cancelLabel': 'Limpar'
            },
            ranges: {
              'Hoje': [moment(), moment()],
              'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
              'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
              'Este mês': [moment().startOf('month'), moment().endOf('month')],
              'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
          });
          //}, cb);
          $('.campo_periodo').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
            vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
            vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
          });

          $('.campo_periodo').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            vm.periodo = '';
            vm.periodo_inicio = '';
            vm.periodo_fim = '';
          });
        };

        vm.url_img = API.url;

        vm.buscaUsuarios = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid
            };
            relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
              var array = [];

              var local = window.localStorage.getItem('5c479de2');
              var valLocal = JSON.parse(API.funilRC.decrypt(local));
              var localNeg = window.localStorage.getItem('isModNegociacao');
              var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

              vm.roleVisualiza = parseInt(valLocalNeg.visualiza);

              /* GESTOR --- INICIO*/
              if (vm.roleVisualiza == 1) {
                var gestores = $filter('filter')(response.data.usuarios, { fk_perfil_id: '1' });
                if (gestores != '') {
                  array.push({ name: "Gestor", msGroup: true });
                  angular.forEach(gestores, function(value, key) {
                    if (value.user_avatar == null) {
                      var icon =
                        '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                        '<div class="cell table">' +
                        '<div class="row">' +
                        '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                        '</span>' +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    } else {
                      var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                    }
                    if (value.user_ativo == 0) {
                      var name = value.user_nome + ' (Inativo)';
                    } else {
                      name = value.user_nome;
                    }
                    if (value.user_hash == Cookies.get('crm_funil_hash')) {
                      var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                    }
                    array.push({ 'icon': icon, 'name': name, 'ticked': false, 'slug': value.user_slug });
                  });
                  array.push({ msGroup: false });
                }
              }
              /* GESTOR --- FIM*/

              vm.montarSelectUsuarios(array, response.data.usuarios, response.data.grupos);
            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaUsuarios();

        vm.montarSelectUsuarios = function(array, usuarios, grupos) {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var grupo_id = valLocal[0].fk_gru_id;
          var localNeg = window.localStorage.getItem('isModNegociacao');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
          if (vm.roleVisualiza != 4) {
            /* GRUPOS --- INICIO*/
            if (vm.roleVisualiza == 2 || vm.roleVisualiza == 1) {
              if (grupos != '') {
                angular.forEach(grupos, function(value, key) {
                  if (vm.roleVisualiza == 2) {
                    if (grupo_id == value.gru_id) {
                      array.push({ name: value.gru_nome, msGroup: true });
                      if (value.usuarios.length != '') {
                        angular.forEach(value.usuarios, function(value, key) {
                          if (value.user_avatar == null) {
                            var icon =
                              '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                              '<div class="cell table">' +
                              '<div class="row">' +
                              '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                              '</span>' +
                              '</div>' +
                              '</div>' +
                              '</div>';
                          } else {
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                          }
                          if (value.user_ativo == 0) {
                            var name = value.user_nome + ' (Inativo)';
                          } else {
                            name = value.user_nome;
                          }
                          if (value.user_hash == Cookies.get('crm_funil_hash')) {
                            var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                          }
                          array.push({ 'icon': icon, 'name': name, 'ticked': false, 'slug': value.user_slug });
                        });
                      } else {
                        array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                      }
                      array.push({ msGroup: false });
                    }
                  } else {
                    array.push({ name: value.gru_nome, msGroup: true });
                    if (value.usuarios.length != '') {
                      angular.forEach(value.usuarios, function(value, key) {
                        if (value.user_avatar == null) {
                          var icon =
                            '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                            '<div class="cell table">' +
                            '<div class="row">' +
                            '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                            '</span>' +
                            '</div>' +
                            '</div>' +
                            '</div>';
                        } else {
                          var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 0) {
                          var name = value.user_nome + ' (Inativo)';
                        } else {
                          name = value.user_nome;
                        }
                        if (value.user_hash == Cookies.get('crm_funil_hash')) {
                          var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                        }
                        array.push({ 'icon': icon, 'name': name, 'ticked': false, 'slug': value.user_slug });
                      });
                    } else {
                      array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                    }
                    array.push({ msGroup: false });
                  }

                });
              }
            }
            /* GRUPOS --- FIM*/

            if (vm.roleVisualiza == 1) {
              var representantes = $filter('filter')(usuarios, { fk_perfil_id: '5' });
              if (representantes != '') {
                array.push({ name: 'Representante', msGroup: true });
                angular.forEach(representantes, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': false, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });

              }
              var gerentesGerais = $filter('filter')(usuarios, { fk_perfil_id: '3' });
              if (gerentesGerais != '') {
                array.push({ name: 'Gerente Geral', msGroup: true });
                angular.forEach(gerentesGerais, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': false, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });
              }
            }

            if (vm.roleVisualiza == 3) {
              angular.forEach(usuarios, function(value, key) {
                if (value.user_hash == Cookies.get('crm_funil_hash')) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': false, 'slug': value.user_slug });
                }
              });
            }

            vm.selectUsuarios = array;

            vm.somente_users = $filter('filter')(vm.selectUsuarios, { slug: '' });
            /*vm.quantUsuarios = vm.somente_users.length;
            vm.selecionadosInicio = [];
            angular.forEach(vm.somente_users, function(value, key) {
                vm.selecionadosInicio.push(value.slug);
                vm.usuarioSelecionado = vm.selecionadosInicio.join();
            });*/
          }
        }

        vm.ptbrAtiv = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar usuário'
        };
        vm.ptbrAtivComp = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar tipo de compromisso'
        };
        vm.ptbr = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar usuários'
        };

        vm.fSelectAll = function(qual) {
          if (qual == 'usuarios') {
            vm.novoTodos = [];
            angular.forEach(vm.somente_users, function(value, key) {
              vm.novoTodos.push(value.slug);
              vm.usuarioSelecionado = vm.novoTodos.join();
            });
            vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
          } else if (qual == 'tipoComp') {
            vm.novoTodosTipoComp = [];
            angular.forEach(vm.tiposCompromissoRepeat, function(value, key) {
              vm.novoTodosTipoComp.push(value.slug);
              vm.tipoCompSelecionado = vm.novoTodosTipoComp.join();
            });
            vm.todosNenhumTipoComp(vm.tipoCompSelecionado, 'tipoComp');
          }
        };

        vm.fClick = function(data, qual) {
          if (qual == 'usuarios') {
            vm.usuarioSelecionado = [];
            vm.novo = [];
            angular.forEach(vm.selectedUsuarios, function(value, key) {
              vm.novo.push(value.slug);
              vm.usuarioSelecionado = vm.novo.join();
            });
            vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
          } else if (qual == 'tipoComp') {
            vm.tipocompSelecionado = [];
            vm.novoTipoComp = [];
            angular.forEach(vm.selectedTipoComp, function(value, key) {
              vm.novoTipoComp.push(value.id);
              vm.tipocompSelecionado = vm.novoTipoComp.join();
            });
            vm.todosNenhumTipoComp(vm.tipocompSelecionado, 'tipoComp');
          }
        };

        vm.fSelectNone = function(qual) {
          if (qual == 'usuarios') {
            vm.novoTodos = [];
            angular.forEach(vm.selectUsuarios, function(value, key) {
              vm.novoTodos.splice(key);
              vm.usuarioSelecionado = vm.novoTodos.join();
            });
            vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
          } else if (qual == 'tipoComp') {
            vm.novoTodosTipoComp = [];
            angular.forEach(vm.produtosRepeat, function(value, key) {
              vm.novoTodosTipoComp.splice(key);
              vm.tipocompSelecionado = vm.novoTodosTipoComp.join();
            });
            vm.todosNenhumTipoComp(vm.tipocompSelecionado, 'tipoComp');
          }
        };

        vm.TodosNenhum = function(array, qual) {
          if (array) {
            if (array != '') {
              var array = array.split(',');
            } else {
              var array = '';
            }
          }
          if (qual == 'usuarios') {
            if (vm.relatorio == 'pesquisaAvancada') {
              if (array.length == vm.quantUsuarios) {
                vm.usuarioSelecionado = 'todos';
              } else if (array.length == 0) {
                vm.usuarioSelecionado = 'não selecionado';
              } else if (array == 'nenhum') {
                vm.usuarioSelecionado = 'nenhum';
              }
            }
          }
        };

        vm.todosNenhumTipoComp = function(array, qual) {
          if (array) {
            if (array != '') {
              var array = array.split(',');
            } else {
              var array = '';
            }
          }
          if (array.length == vm.quantTipoComp) {
            vm.tipocompSelecionado = 'todos';
          } else if (array.length == '') {
            vm.tipocompSelecionado = 'nenhum';
          }
          vm.tipocompSelecionado = Base64.encode(vm.tipocompSelecionado);
        };
        /*FILTROS --- FIM*/


        vm.imprimir = function() {
          window.print();
        };

        vm.verificarNavegador = function() {
          // Opera 8.0+
          vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
          // Firefox 1.0+
          vm.isFirefox = typeof InstallTrigger !== 'undefined';
          // At least Safari 3+: "[object HTMLElementConstructor]"
          vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
          // Internet Explorer 6-11
          vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
          // Edge 20+
          vm.isEdge = !vm.isIE && !!window.StyleMedia;
          // Chrome 1+
          vm.isChrome = !!window.chrome && !!window.chrome.webstore;
          // Blink engine detection
          vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();
      }
})();
