(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelNegocCliController', gerarRelNegocCliController)
    
  
  gerarRelNegocCliController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'FactoryRoles', 'usuariosService', 'compromissosService', 'PessoasService', 'turmasVCService'];

  function gerarRelNegocCliController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas, FactoryRoles, usuariosService, compromissosService, PessoasService, turmasVCService) {
        var Base64 = {
          _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
          encode: function(e) {
            var t = '';
            var n, r, i, s, o, u, a;
            var f = 0;
            e = Base64._utf8_encode(e);
            while (f < e.length) {
              n = e.charCodeAt(f++);
              r = e.charCodeAt(f++);
              i = e.charCodeAt(f++);
              s = n >> 2;
              o = (n & 3) << 4 | r >> 4;
              u = (r & 15) << 2 | i >> 6;
              a = i & 63;
              if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
              t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
            }
            return t;
          },
          decode: function(e) {
            var t = '';
            var n, r, i;
            var s, o, u, a;
            var f = 0;
            e = e.replace(/[^A-Za-z0-9+/=]/g, '');
            while (f < e.length) {
              s = this._keyStr.indexOf(e.charAt(f++));
              o = this._keyStr.indexOf(e.charAt(f++));
              u = this._keyStr.indexOf(e.charAt(f++));
              a = this._keyStr.indexOf(e.charAt(f++));
              n = s << 2 | o >> 4;
              r = (o & 15) << 4 | u >> 2;
              i = (u & 3) << 6 | a;
              t = t + String.fromCharCode(n);
              if (u != 64) { t = t + String.fromCharCode(r); }
              if (a != 64) { t = t + String.fromCharCode(i); }
            }
            t = Base64._utf8_decode(t);
            return t;
          },
          _utf8_encode: function(e) {
            e = e.replace(/rn/g, 'n');
            var t = '';
            for (var n = 0; n < e.length; n++) {
              var r = e.charCodeAt(n);
              if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                t += String.fromCharCode(r >> 6 | 192);
                t += String.fromCharCode(r & 63 | 128);
              } else {
                t += String.fromCharCode(r >> 12 | 224);
                t += String.fromCharCode(r >> 6 & 63 | 128);
                t += String.fromCharCode(r & 63 | 128);
              }
            }
            return t;
          },
          _utf8_decode: function(e) {
            var t = '';
            var n = 0;
            var c1 = 0;
            var c2 = 0;
            var r = c1 = c2 = 0;
            while (n < e.length) {
              r = e.charCodeAt(n);
              if (r < 128) {
                t += String.fromCharCode(r);
                n++;
              } else if (r > 191 && r < 224) {
                c2 = e.charCodeAt(n + 1);
                t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                n += 2;
              } else {
                c2 = e.charCodeAt(n + 1);
                c3 = e.charCodeAt(n + 2);
                t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                n += 3;
              }
            }
            return t;
          }
        };

        var vm = this;

        vm.url = API.url;

        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        var now = new Date;
        vm.anoatual = now.getFullYear();

        vm.periodo = '';
        vm.periodo_inicio = '';
        vm.periodo_fim = '';

        vm.options = { width: 100, height: 200, 'bar': 'bbb' };
        vm.data = [0, 2, 3];

        vm.adm = false;
        vm.verificarAdm = function() {
          categoriasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
            if (response.data == 'Administrador') {
              vm.adm = true;
            } else {
              vm.adm = false;
            }
          }, function(error) {
            console.log('Erro: ', error);
          });
        };

        vm.carregando_mais_inf = false;

        vm.carregando = true;

        vm.acabou = function() {
          vm.carregando = false;
          vm.carregando_mais_inf = false;
        };

        vm.limite = 30;

        vm.carregar_tudo = true;
        vm.maisinf = function() {
          vm.carregando_mais_inf = true;
          //vm.carregar_tudo = true;
          $timeout(function() {
            vm.limite += 30;
            vm.carregando_mais_inf = false;
          }, 500);
        };

        vm.expandirAcc = function() { //EXPANDIR ACCORDION
          $('.body_acc').slideToggle();
          $('.seta').toggleClass('fa-angle-down fa-angle-up');
        };

        vm.rangePeriodo = function() {
          function cb(start, end) {
            if (start._isValid) { // datas validas
              vm.periodo_inicio = start.format('DD_MM_YYYY');
              vm.periodo_fim = end.format('DD_MM_YYYY');;
            } else {

            }
          }
          $('.campo_periodo_neg').daterangepicker({
            //autoUpdateInput: false,
            'opens': 'center',
            'locale': {
              'format': 'DD/MM/YYYY',
              'cancelLabel': 'Limpar'
            },
            ranges: {
              'Hoje': [moment(), moment()],
              'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
              'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
              'Este mês': [moment().startOf('month'), moment().endOf('month')],
              'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
          });
          //}, cb);
          $('.campo_periodo_neg').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
            vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
            vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
          });

          $('.campo_periodo_neg').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            vm.periodo = '';
            vm.periodo_inicio = '';
            vm.periodo_fim = '';
          });
        };

        vm.selecionarTodas = true;

        vm.situacoes = [
          { name: 'Criadas', selecionado: true },
          { name: 'Perdidas', selecionado: true },
          { name: 'Ganhas', selecionado: true },
          { name: 'Abertas', selecionadoA: false },
          { name: 'Exibir Descontos', selecionadoA: false },
        ];

        var arrSituacoes = $routeParams.situacoes.split('-');

        vm.mostrarMedDescontos = arrSituacoes.filter( function(elem) {
          return elem == 'Exibir Descontos';
        } )

        if (vm.mostrarMedDescontos.length) {
          vm.situacoes[4].selecionadoA = true;
        }


        vm.desabilitar = true;

        vm.mudar = function(qual, item) {
          if (qual) {
            vm.desabilitar = true;
            vm.situacoesSelecionadas = function() {
              return 'Criadas,Ganhas,Perdidas';
            };
            angular.forEach(vm.situacoes, function(item) {
              item.selecionado = true;
            });
          } else {
            vm.situacoesSelecionadas = function() {
              return vm.situacoes.filter(function(item) {
                return item.selecionado;
              }).map(function(item) {
                return item.name;
              }).join('-');
            };
            angular.forEach(vm.situacoes, function(item) {
              item.selecionado = false;
            });
            vm.desabilitar = false;
          }
        };

        vm.situacoesSelecionadas = function() {
          return vm.situacoes.filter(function(item) {
            return item.selecionado;
          }).map(function(item) {
            return item.name;
          }).join('-');
        };

        vm.situacoesSelecionadasA = function() {
          return vm.situacoes.filter(function(item) {
            return item.selecionadoA;
            item.selecionadoA;
          }).map(function(item) {
            return item.name;
          }).join('-');
        };

        angular.forEach(arrSituacoes, function(value) {
          if (value.match(/Abertas/)) {
            vm.abertas = value;
          } else if (value == 'Criadas') {
            vm.criadas = value;
          } else if (value == 'Perdidas') {
            vm.perdidas = value;
          } else if (value == 'Ganhas') {
            vm.ganhas = value;
          } else if (value == 'Ganhas, Perdidas, Criadas') {
            vm.todas = 'Ganhas, Perdidas, Criadas';
          }
        });

        vm.buscaCategorias = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findCategorias(vm.saasid).then(function(response) {
                    vm.categorias = response.data;
                }, function(error) {
                    console.error(error);
                });
                vm.selecionarCateg('todos');
            });
        };
        vm.buscaCategorias();

        vm.selecionarCateg = function(dados) {
            vm.categoria = dados;
            vm.categoriaSelecionado = dados;
        };

        if ($routeParams) {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            if ($routeParams.relatorio == 'negociacoes_clientes') {
              vm.tituloPrincipal = 'Análise de negócios';
              vm.tituloFiltro = 'Negociações de clientes';
              vm.subtitulo = 'Relatório de análise de negociações';
              vm.criadasExibir = vm.criadas;
              vm.perdidasExibir = vm.perdidas;
              vm.ganhasExibir = vm.ganhas;
              vm.abertasExibir = vm.abertas;
              vm.todasExibir = vm.todas;
              if ($routeParams.inicio) { // PERIODO SELECIONADO
                vm.inicio = $routeParams.inicio;
                vm.fim = $routeParams.fim;
                vm.inicioExibir = vm.inicio.replace('_', '/').replace('_', '/');
                vm.fimExibir = vm.fim.replace('_', '/').replace('_', '/');
                var dados = {
                  'saasid': vm.saasid,
                  'criadas': vm.criadasExibir,
                  'ganhas': vm.ganhasExibir,
                  'perdidas': vm.perdidasExibir,
                  'todas': vm.todasExibir,
                  'abertas': vm.abertasExibir,
                  'inicio': vm.inicio,
                  'fim': vm.fim
                };
              } else { // PERIODO NÃƒO SELECIONADO
                var dados = {
                  'saasid': vm.saasid,
                  'criadas': vm.criadasExibir,
                  'ganhas': vm.ganhasExibir,
                  'perdidas': vm.perdidasExibir,
                  'todas': vm.todasExibir,
                  'abertas': vm.abertasExibir

                };
              }
              if (FactoryRoles.checkRolesVisuFUNIL('isModNegociacao')) {
                dados.qual_visu = $routeParams.visualizar;
                var _situacaoDescontoMostrar = $routeParams.situacoes
                                                            .split('-')
                                                            .filter(function(elem) {
                                                              return elem == 'Exibir Descontos';
                                                            })
                if (_situacaoDescontoMostrar.length) {
                  dados.mostrarMedDesconto = true;
                } else {
                  dados.mostrarMedDesconto = false;
                }

                dados.categoria = $routeParams.categoria;
                vm.categoriaSelecionada = $routeParams.categoria;

                if ($routeParams.unidade_odontotop) {
                  dados.unidade_odontotop = $routeParams.unidade_odontotop;
                  vm.buscaUnidadesOdontoTop();
                }

                if ($routeParams.turma_vc) {
                  dados.turma_vc = $routeParams.turma_vc;
                  vm.buscaTurmasVC();
                }

                relatoriosService.buscaSituacaoNegociacoes(dados).then(function(response) {
                  console.log("buscaSituacaoNegociacoes");
                  console.log(response.data);
                  //return null;
                  vm.quantCriadas = (response.data.CRIADAS);
                  vm.vlCriadas = (response.data.VL_CRIADAS);
                  vm.quantPerdidas = (response.data.PERDIDAS);
                  vm.vlPerdidas = (response.data.VL_PERDIDAS);
                  vm.quantGanhas = (response.data.GANHAS);
                  vm.vlGanhas = (response.data.VL_GANHAS);
                  vm.med_desc_ganha_porcent = response.data.med_desc_ganha_porcent;
                  vm.med_desc_ganha_real = response.data.med_desc_ganha_real;
                  vm.med_desc_perdidas_porcent = response.data.med_desc_perdidas_porcent;
                  vm.med_desc_perdidas_real = response.data.med_desc_perdidas_real;
                  vm.med_desc_criadas_porcent = response.data.med_desc_criadas_porcent;
                  vm.med_desc_criadas_real = response.data.med_desc_criadas_real;


                  if (vm.vlCriadas == null) {
                    vm.vlCriadas = '0,00';
                  }
                  if (vm.vlPerdidas == null) {
                    vm.vlPerdidas = '0,00';
                  }
                  if (vm.vlGanhas == null) {
                    vm.vlGanhas = '0,00';
                  }
                }, function(error) {
                  console.log('Erro: ', error);
                });
                relatoriosService.buscaNegociacoesPessoasEmpresas(dados).then(function(response) {
                  /*console.log("buscaNegociacoesPessoasEmpresas");
                  console.log(response.data);*/
                  //return null;
                  if (response.data != '') {
                    vm.listaNegPessoasEmpresas = response.data;
                  } else {
                    vm.listaNegPessoasEmpresas = '';
                    vm.carregando = false;
                  }
                }, function(error) {
                  console.log('Erro: ', error);
                });
                vm.mostrarFiltroVisualizar($routeParams.visualizar);
              } else {
                vm.carregando = false;
                vm.naoMostrar = true;
              }
            }
          });
        }

        vm.acc_negociacoes = function(id) {
          $timeout(function() {
            $('.tr_dados_neg_' + id).fadeToggle("slow");
            $('.seta_acc_negocios_' + id).toggleClass('seta_ativa');
          });
        };

        vm.mostrarFiltroVisualizar = function(visu) {
          var qual_visu = Base64.decode(visu);
          if (!qual_visu.match(/todos/)) {
            if (qual_visu.match(/user/)) {
              var start = qual_visu.indexOf("_") + 1;
              var end = qual_visu.length;
              var user = qual_visu.substr(start, end);
              var dados = {
                'user_id': user
              }
              usuariosService.buscaUsuarioID(dados).then(function(response) {
                vm.filtroVisualizar = "Usuário - " + response.data[0].user_nome;
              }, function(error) {
                console.log('Erro: ', error);
              });
            } else {
              var start = qual_visu.indexOf("_") + 1;
              var end = qual_visu.length;
              var grupo = qual_visu.substr(start, end);
              var dados = {
                'gru_id': grupo
              }
              usuariosService.buscaGrupoID(dados).then(function(response) {
                vm.filtroVisualizar = "Grupo - " + response.data[0].gru_nome;
              }, function(error) {
                console.log('Erro: ', error);
              });
            }
          } else {
            vm.filtroVisualizar = 'Todos';
          }
        }

        vm.nomeColuna = 'nome';
        vm.reverse = false;

        vm.sortBy = function(nomeColuna) {
          vm.reverse = (vm.nomeColuna === nomeColuna) ? !vm.reverse : true;
          vm.nomeColuna = nomeColuna;
        };

        vm.imprimir = function() {
          window.print();
        };
        vm.verificarNavegador = function() {
          // Opera 8.0+
          vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
          // Firefox 1.0+
          vm.isFirefox = typeof InstallTrigger !== 'undefined';
          // At least Safari 3+: "[object HTMLElementConstructor]"
          vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
          // Internet Explorer 6-11
          vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
          // Edge 20+
          vm.isEdge = !vm.isIE && !!window.StyleMedia;
          // Chrome 1+
          vm.isChrome = !!window.chrome && !!window.chrome.webstore;
          // Blink engine detection
          vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();

        // COMBOBOX VISUALIZACAO --- INICIO
        vm.visualizando = 'todos';

        vm.buscaGrupos = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid,
            };
            usuariosService.findGrupo(dados)
              .then(function(response) {
                if (response.data != 0) {
                  vm.grupolista = response.data;
                  if (vm.usuarioLogado.fk_perfil_id != 1 && vm.usuarioLogado.fk_perfil_id != 3 && vm.roleVisualiza != 1) {
                    vm.grupolista = $filter('filter')(vm.grupolista, { gru_id: vm.usuarioLogado.fk_gru_id });
                  }
                  $timeout(function() {
                    $('.select_visu').selectpicker();
                    $timeout(function() {
                      vm.mostrarCombo = true;
                    });
                  }, 100);
                } else {
                  vm.grupolista = '';
                };
              }, function(error) {
                console.error(error);
              });
          });
        };

        vm.buscaRepresentantes = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid,
            };
            usuariosService.findRepresentantes(dados)
              .then(function(response) {
                if (response.data != 0) {
                  vm.representantelista = response.data;
                  $timeout(function() {
                    $('.select_visu').selectpicker();
                    $timeout(function() {
                      vm.mostrarCombo = true;
                    });
                  }, 100);
                } else {
                  vm.representantelista = '';
                };
              }, function(error) {
                console.error(error);
              });
          });
        };

        vm.buscarUsuarioLogado = function() {
          var dados = {
            'hash': Cookies.get('crm_funil_hash')
          }
          compromissosService.buscarUsuarioEspecifico(dados).then(function(response) {
            vm.usuarioLogado = response.data[0];
            vm.verifDadosUser();
          }, function(error) {
            console.error(error);
          });
        }
        vm.buscarUsuarioLogado();

        vm.mostrarCombo = false;
        vm.mostrarVisuGeral = true;
        vm.mostrarUsuario = true;
        vm.naoMostrar = false;
        vm.verifDadosUser = function() {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var localNeg = window.localStorage.getItem('isModNegociacao');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          if (valLocalNeg.visualiza) {
            vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
            switch (parseInt(valLocalNeg.visualiza)) {
              case 4:
                $timeout(function() {
                  vm.mostrarCombo = true;
                  vm.mostrarUsuario = false;
                  vm.carregando = false;
                  vm.naoMostrar = true;
                  vm.visualizando = '';
                  vm.mostrarVisuGeral = false;
                });
                break;
              case 3:
                $timeout(function() {
                  vm.mostrarCombo = true;
                  vm.mostrarVisuGeral = false;
                  vm.mostrarUsuario = true;
                  vm.visualizando = "user_" + vm.usuarioLogado.user_id;
                  $timeout(function() {
                    vm.enviarVisualizando = Base64.encode(vm.visualizando);
                  });
                });
                break;
              case 2:
                $timeout(function() {
                  vm.buscaGrupos();
                  if (vm.usuarioLogado.fk_perfil_id == 4 || vm.usuarioLogado.fk_perfil_id == 5) {
                    vm.mostrarVisuGeral = false;
                    vm.mostrarUsuario = true;
                  }
                  vm.visualizando = "grupo_" + vm.usuarioLogado.fk_gru_id;
                  $timeout(function() {
                    vm.enviarVisualizando = Base64.encode(vm.visualizando);
                  });
                });
                break;
              default:
                $timeout(function() {
                  vm.buscaGrupos();
                  vm.buscaRepresentantes();
                  vm.visualizando = "todos";
                  vm.mostrarVisuGeral = true;
                  vm.mostrarUsuario = true;
                  $timeout(function() {
                    vm.enviarVisualizando = Base64.encode(vm.visualizando);
                  });
                });
            }
          }
        }

        vm.mudarVisualizacao = function(visu) {
          $timeout(function() {
            vm.enviarVisualizando = Base64.encode(vm.visualizando);
          });
        }

        // COMBOBOX VISUALIZACAO --- FIM

        vm.unidades_odontotop = [];
        vm.buscaUnidadesOdontoTop = function(name) {
            PessoasService.findUnidadesOdontoTop().then(function(response) {
                // console.log("buscaUnidadesOdontoTop");
                // console.log(response.data);
                if ($routeParams.unidade_odontotop != 'todas') {
                    angular.forEach(response.data, function(value, key) {
                        if (value.id == $routeParams.unidade_odontotop){
                            vm.unidade_odontotop = value.name;
                        }
                    });
                } else {
                    vm.unidade_odontotop = "Todas";
                }
            }, function(error) {
                $log.error(error);
            });
        };

        // TURMAS VENDAS COMPLEXAS - A9 --- INÍCIO
        vm.buscaTurmasVC = function () {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function (items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                turmasVCService.findAll(dados).then(function (response) {
                  if ($routeParams.turma_vc != 'todas') {
                    angular.forEach(response.data, function(value, key) {
                        if (value.tvc_id == $routeParams.turma_vc){
                            vm.turma_vc = value.tvc_nome;
                        }
                    });
                  } else {
                      vm.turma_vc = "Todas";
                  }
                }, function (error) {
                    $log.error(error);
                });
            });
        };
        // TURMAS VENDAS COMPLEXAS - A9 --- FIM
      }

  })();
