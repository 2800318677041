(function() {
    'use strict';

    angular
        .module('app')
        .config(function($mdThemingProvider) {
            $mdThemingProvider.definePalette('blueFunilPro', {
                '50': '00293e',
                '100': '00293e',
                '200': '00293e',
                '300': '00293e',
                '400': '00293e',
                '500': '00293e',
                '600': '00293e',
                '700': '00293e',
                '800': '00293e',
                '900': '00293e',
                'A100': '00293e',
                'A200': '00293e',
                'A400': '00293e',
                'A700': '00293e',
                'contrastDefaultColor': 'light',
                'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100'],
                'contrastLightColors': undefined
            });

            $mdThemingProvider.theme('default')
                .primaryPalette('blueFunilPro')
        })
        .component('listHistoryLink', {
            templateUrl: 'pessoas/component/listHistoryLink.template.html',
            bindings: {
                list: '<'
            },
            controller: function(RC, $compile, $scope, $timeout) {
                const $ctrl = this;
                $ctrl.userData = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
                $ctrl.loading = 'loading';

                /*$ctrl.list = [{
                        "title": "COTAÇÃO COMEDOURO E BEBEDOURO HABITAT PRA PAGAMENTO ANTECIPADO",
                        "hash": "19654adb9d8e79ccb4c5344cb9c0fbf7",
                        "history": [
                            [{
                                "firstAccess": "2019-12-11T15:01:53.257Z",
                                "lastAccess": "2019-12-11T15:01:53.257Z",
                                "timeRemained": 0,
                                "total_time": 0
                            }],
                            [{
                                "firstAccess": "2019-12-11T15:01:45.238Z",
                                "lastAccess": "2019-12-11T15:01:45.239Z",
                                "timeRemained": 0.001,
                                "total_time": 0.001
                            }],
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-11T14:55:46.940Z",
                                    "lastAccess": "2019-12-11T14:55:54.596Z",
                                    "timeRemained": 7.656,
                                    "action": "pagina",
                                    "total_time": 253.316
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-11T14:55:54.596Z",
                                    "lastAccess": "2019-12-11T14:56:02.289Z",
                                    "timeRemained": 7.693,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-11T14:56:02.289Z",
                                    "lastAccess": "2019-12-11T15:00:00.256Z",
                                    "timeRemained": 237.967,
                                    "action": "pagina"
                                }
                            ],
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-10T15:25:40.858Z",
                                    "lastAccess": "2019-12-10T15:26:05.051Z",
                                    "timeRemained": 24.193,
                                    "action": "pagina",
                                    "total_time": 533.299
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-10T15:26:05.051Z",
                                    "lastAccess": "2019-12-10T15:26:07.189Z",
                                    "timeRemained": 2.138,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-10T15:26:07.189Z",
                                    "lastAccess": "2019-12-10T15:34:34.157Z",
                                    "timeRemained": 506.968,
                                    "action": "pagina"
                                }
                            ],
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-10T15:22:52.282Z",
                                    "lastAccess": "2019-12-10T15:23:00.770Z",
                                    "timeRemained": 8.488,
                                    "action": "pagina",
                                    "total_time": 91.12
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-10T15:23:00.770Z",
                                    "lastAccess": "2019-12-10T15:23:02.554Z",
                                    "timeRemained": 1.784,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-10T15:23:02.554Z",
                                    "lastAccess": "2019-12-10T15:23:43.655Z",
                                    "timeRemained": 41.101,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=4",
                                    "firstAccess": "2019-12-10T15:23:43.655Z",
                                    "lastAccess": "2019-12-10T15:24:23.402Z",
                                    "timeRemained": 39.747,
                                    "action": "pagina"
                                }
                            ],
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-10T15:18:15.242Z",
                                    "lastAccess": "2019-12-10T15:18:59.165Z",
                                    "timeRemained": 43.923,
                                    "action": "pagina",
                                    "total_time": 264.184
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-10T15:18:59.164Z",
                                    "lastAccess": "2019-12-10T15:19:07.352Z",
                                    "timeRemained": 8.188,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-10T15:19:07.352Z",
                                    "lastAccess": "2019-12-10T15:20:21.291Z",
                                    "timeRemained": 73.939,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=4",
                                    "firstAccess": "2019-12-10T15:20:21.290Z",
                                    "lastAccess": "2019-12-10T15:22:39.426Z",
                                    "timeRemained": 138.136,
                                    "action": "pagina"
                                }
                            ],
                            [{
                                "id": "pag=1",
                                "firstAccess": "2019-12-10T15:17:39.946Z",
                                "lastAccess": "2019-12-10T15:20:29.495Z",
                                "timeRemained": 169.549,
                                "action": "pagina",
                                "total_time": 169.549
                            }],
                            [{
                                "firstAccess": "2019-12-10T14:52:03.782Z",
                                "lastAccess": "2019-12-10T14:52:03.782Z",
                                "timeRemained": 0,
                                "total_time": 0
                            }],
                            [{
                                "firstAccess": "2019-12-10T14:50:45.899Z",
                                "lastAccess": "2019-12-10T14:50:45.899Z",
                                "timeRemained": 0,
                                "total_time": 0
                            }],
                            [{
                                "id": "pag=1",
                                "firstAccess": "2019-12-10T14:45:32.977Z",
                                "lastAccess": "2019-12-10T14:50:35.420Z",
                                "timeRemained": 302.443,
                                "action": "pagina",
                                "total_time": 302.443
                            }]
                        ]
                    },
                    {
                        "title": "COTAÇÃO ALIMENTADOR CRECHE 80 CPO R PVC",
                        "hash": "de76f8ff57a7fb3ec6477ff18f7f365d",
                        "history": [
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-07T10:15:51.394Z",
                                    "lastAccess": "2019-12-07T10:15:55.832Z",
                                    "timeRemained": 4.438,
                                    "action": "pagina",
                                    "total_time": 28.898
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-07T10:15:55.832Z",
                                    "lastAccess": "2019-12-07T10:15:57.737Z",
                                    "timeRemained": 1.905,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-07T10:15:57.737Z",
                                    "lastAccess": "2019-12-07T10:15:59.072Z",
                                    "timeRemained": 1.335,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=4",
                                    "firstAccess": "2019-12-07T10:15:59.072Z",
                                    "lastAccess": "2019-12-07T10:15:59.293Z",
                                    "timeRemained": 0.221,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-07T10:15:59.293Z",
                                    "lastAccess": "2019-12-07T10:15:59.505Z",
                                    "timeRemained": 0.212,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-07T10:15:59.504Z",
                                    "lastAccess": "2019-12-07T10:15:59.958Z",
                                    "timeRemained": 0.454,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-07T10:15:59.958Z",
                                    "lastAccess": "2019-12-07T10:16:20.292Z",
                                    "timeRemained": 20.334,
                                    "action": "pagina"
                                }
                            ],
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-07T09:58:18.003Z",
                                    "lastAccess": "2019-12-07T09:58:21.396Z",
                                    "timeRemained": 3.393,
                                    "action": "pagina",
                                    "total_time": 385.995
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-07T09:58:21.396Z",
                                    "lastAccess": "2019-12-07T09:58:23.016Z",
                                    "timeRemained": 1.62,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-07T09:58:23.015Z",
                                    "lastAccess": "2019-12-07T10:04:43.998Z",
                                    "timeRemained": 380.983,
                                    "action": "pagina"
                                }
                            ],
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-07T02:49:51.811Z",
                                    "lastAccess": "2019-12-07T02:56:04.417Z",
                                    "timeRemained": 372.606,
                                    "action": "pagina",
                                    "total_time": 423.74
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-07T02:56:04.416Z",
                                    "lastAccess": "2019-12-07T02:56:06.453Z",
                                    "timeRemained": 2.037,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-07T02:56:06.453Z",
                                    "lastAccess": "2019-12-07T02:56:31.401Z",
                                    "timeRemained": 24.948,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=4",
                                    "firstAccess": "2019-12-07T02:56:31.401Z",
                                    "lastAccess": "2019-12-07T02:56:55.551Z",
                                    "timeRemained": 24.15,
                                    "action": "pagina"
                                }
                            ],
                            [{
                                "firstAccess": "2019-12-07T02:49:14.860Z",
                                "lastAccess": "2019-12-07T02:49:14.860Z",
                                "timeRemained": 0,
                                "total_time": 0
                            }],
                            [{
                                    "id": "pag=1",
                                    "firstAccess": "2019-12-07T02:44:09.715Z",
                                    "lastAccess": "2019-12-07T02:44:26.913Z",
                                    "timeRemained": 17.198,
                                    "action": "pagina",
                                    "total_time": 301.312
                                },
                                {
                                    "id": "pag=2",
                                    "firstAccess": "2019-12-07T02:44:26.912Z",
                                    "lastAccess": "2019-12-07T02:44:30.365Z",
                                    "timeRemained": 3.453,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-07T02:44:30.365Z",
                                    "lastAccess": "2019-12-07T02:46:11.389Z",
                                    "timeRemained": 101.024,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=5",
                                    "firstAccess": "2019-12-07T02:46:11.389Z",
                                    "lastAccess": "2019-12-07T02:47:51.268Z",
                                    "timeRemained": 99.879,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=3",
                                    "firstAccess": "2019-12-07T02:47:51.268Z",
                                    "lastAccess": "2019-12-07T02:47:58.838Z",
                                    "timeRemained": 7.57,
                                    "action": "pagina"
                                },
                                {
                                    "id": "pag=4",
                                    "firstAccess": "2019-12-07T02:47:58.838Z",
                                    "lastAccess": "2019-12-07T02:49:11.027Z",
                                    "timeRemained": 72.189,
                                    "action": "pagina"
                                }
                            ]
                        ]
                    }
                ]*/

                $ctrl.$onChanges = () => {
                    if ($ctrl.list !== undefined) {
                        $ctrl.loading = 'loaded'

                        if ($ctrl.list.length) {
                            console.log($ctrl.list)
                            for (let list of $ctrl.list) {
                                list.config = {
                                    pagination: list.history.length > 3 ? 3 : list.history.length
                                }
                            }
                            // $timeout(() => $(".box_rolagem_link").niceScroll())
                        }
                    }
                }

                $ctrl.moreHistory = () => {
                    // $timeout(() => $(".box_rolagem_link").niceScroll())
                }

                $ctrl.historyModal = {
                    open: (element, history) => {
                        $ctrl.test = history;
                        let buttonPosition = element.currentTarget.getBoundingClientRect();

                        let backdrop = document.createElement('div');
                        backdrop.className = 'backdrop_calendar_comp';
                        backdrop.style.display = 'block';
                        backdrop.style.position = 'fixed';
                        backdrop.setAttribute('ng-click', '$ctrl.historyModal.close()');
                        $compile(backdrop)($scope)


                        let copyModal = document.querySelector('#copy-modal');
                        let copyModalPosition = copyModal.getBoundingClientRect();
                        copyModal.style.display = 'block';
                        copyModal.style.top = (buttonPosition.top + window.scrollY) + 'px';
                        copyModal.style.left = (buttonPosition.left - document.querySelector('#copy-modal').clientWidth) + 'px';
                        copyModal.style.position = 'absolute';


                        document.body.appendChild(backdrop);
                        document.body.appendChild(copyModal);
                    },
                    close: () => {
                        let modal = document.querySelector('.backdrop_calendar_comp');
                        let copyModal = document.querySelector('#copy-modal');
                        modal.remove();
                        copyModal.style.display = 'none';
                    }
                }
            }
        });

    angular
        .module('app')
        .filter('secsToHour', () => {
            return (secs) => {
                // var sec_num = parseInt(secs, 10); // don't forget the second param
                // var hours = Math.floor(sec_num / 3600);
                // var minutes = Math.floor((sec_num % 3600) / 60);
                // var seconds = Math.floor(sec_num % 60);

                // if (hours < 10) { hours = "0" + hours; }
                // if (minutes < 10) { minutes = "0" + minutes; }
                // if (seconds < 10) { seconds = "0" + seconds; }
                // return new Date(secs * 1000).toISOString().substr(11, 8);

                d = Number(secs);
                var h = Math.floor(d / 3600);
                var m = Math.floor(d % 3600 / 60);
                var s = Math.floor(d % 3600 % 60);

                if (h < 10) { h = "0" + h; }
                if (m < 10) { m = "0" + m; }
                if (s < 10) { s = "0" + s; }
                return h + ":" + m + ":" + s; 
            }
        })
}());