/*
 * @Author: Glauber Funez
 * @Date:   2018-03-19 09:30:30
 * @Last Modified by:   Glauber Funez
 * @Last Modified time: 2018-08-21 09:39:52
 */

(function() {
    'use strict';

    angular
        .module('relatorios')
        .controller('gerarRelAnaliseFunilController', gerarRelAnaliseFunilController)


    gerarRelAnaliseFunilController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'usuariosService', 'compromissosService', 'FactoryRoles', '$window', 'loadFunisVenda'];

    function gerarRelAnaliseFunilController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas, usuariosService, compromissosService, FactoryRoles, $window, loadFunisVenda) {
        var Base64 = {
            _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
            encode: function(e) {
                var t = '';
                var n, r, i, s, o, u, a;
                var f = 0;
                e = Base64._utf8_encode(e);
                while (f < e.length) {
                    n = e.charCodeAt(f++);
                    r = e.charCodeAt(f++);
                    i = e.charCodeAt(f++);
                    s = n >> 2;
                    o = (n & 3) << 4 | r >> 4;
                    u = (r & 15) << 2 | i >> 6;
                    a = i & 63;
                    if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
                    t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
                }
                return t;
            },
            decode: function(e) {
                var t = '';
                var n, r, i;
                var s, o, u, a;
                var f = 0;
                e = e.replace(/[^A-Za-z0-9+/=]/g, '');
                while (f < e.length) {
                    s = this._keyStr.indexOf(e.charAt(f++));
                    o = this._keyStr.indexOf(e.charAt(f++));
                    u = this._keyStr.indexOf(e.charAt(f++));
                    a = this._keyStr.indexOf(e.charAt(f++));
                    n = s << 2 | o >> 4;
                    r = (o & 15) << 4 | u >> 2;
                    i = (u & 3) << 6 | a;
                    t = t + String.fromCharCode(n);
                    if (u != 64) { t = t + String.fromCharCode(r); }
                    if (a != 64) { t = t + String.fromCharCode(i); }
                }
                t = Base64._utf8_decode(t);
                return t;
            },
            _utf8_encode: function(e) {
                e = e.replace(/rn/g, 'n');
                var t = '';
                for (var n = 0; n < e.length; n++) {
                    var r = e.charCodeAt(n);
                    if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                        t += String.fromCharCode(r >> 6 | 192);
                        t += String.fromCharCode(r & 63 | 128);
                    } else {
                        t += String.fromCharCode(r >> 12 | 224);
                        t += String.fromCharCode(r >> 6 & 63 | 128);
                        t += String.fromCharCode(r & 63 | 128);
                    }
                }
                return t;
            },
            _utf8_decode: function(e) {
                var t = '';
                var n = 0;
                var c1 = 0;
                var c2 = 0;
                var r = c1 = c2 = 0;
                while (n < e.length) {
                    r = e.charCodeAt(n);
                    if (r < 128) {
                        t += String.fromCharCode(r);
                        n++;
                    } else if (r > 191 && r < 224) {
                        c2 = e.charCodeAt(n + 1);
                        t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                        n += 2;
                    } else {
                        c2 = e.charCodeAt(n + 1);
                        c3 = e.charCodeAt(n + 2);
                        t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                        n += 3;
                    }
                }
                return t;
            }
        };

        var vm = this;

        vm.url = API.url;

        vm.resultVisu = FactoryRoles.checkRolesVisuFUNIL('isModNegociacao');

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        var now = new Date;
        vm.anoatual = now.getFullYear();

        vm.userHash = Cookies.get('crm_funil_hash');

        vm.gerarGraficoAvancadas = function(etapa_id, porcentAvancadas) {
            $timeout(function() {
                $('.grafico_avancadas_' + etapa_id).circliful({
                    animation: 1,
                    animationStep: 3,
                    foregroundBorderWidth: 10,
                    backgroundBorderWidth: 10,
                    percent: porcentAvancadas,
                    textSize: 28,
                    percentageTextSize: 35,
                    textStyle: 'font-size: 12px;',
                    textColor: '#fff',
                    fontColor: '#fff',
                    foregroundColor: '#a754c6'
                });
                $('.print_grafico_avancadas_' + etapa_id).circliful({
                    animation: 1,
                    animationStep: 3,
                    foregroundBorderWidth: 10,
                    backgroundBorderWidth: 10,
                    percent: porcentAvancadas,
                    textSize: 28,
                    percentageTextSize: 35,
                    textStyle: 'font-size: 12px;',
                    textColor: '#fff',
                    fontColor: '#000',
                    foregroundColor: '#a754c6'
                });
            });
        };
        vm.gerarGraficoAndamento = function(etapa_id, porcentAbertos) {
            $timeout(function() {
                $('.grafico_em_andamento_' + etapa_id).circliful({
                    animation: 1,
                    animationStep: 3,
                    foregroundBorderWidth: 10,
                    backgroundBorderWidth: 10,
                    percent: porcentAbertos,
                    textSize: 28,
                    percentageTextSize: 35,
                    textStyle: 'font-size: 12px;',
                    textColor: '#fff',
                    fontColor: '#fff',
                    foregroundColor: '#ffc000'
                });
                $('.print_grafico_em_andamento_' + etapa_id).circliful({
                    animation: 1,
                    animationStep: 3,
                    foregroundBorderWidth: 10,
                    backgroundBorderWidth: 10,
                    percent: porcentAbertos,
                    textSize: 28,
                    percentageTextSize: 35,
                    textStyle: 'font-size: 12px;',
                    textColor: '#fff',
                    fontColor: '#000',
                    foregroundColor: '#ffc000'
                });
            });
        };

        vm.carregarEtapas = function(qual_visu, role) {
            vm.carregando = true;
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'qual_visu': qual_visu,
                    'role': role,
                    'periodo': vm.periodo,
                    'funil_venda': vm.funil_venda_grafico
                };
                relatoriosService.carregarEtapas(dados).then(function(response) {
                    console.log("carregarEtapas");
                    console.log(response.data);
                    vm.qtdtotalNeg = response.data.totais.total_neg;
                    vm.VLtotalNeg = response.data.totais.VL_total_neg;
                    vm.qtdtotalGanhas = response.data.totais.total_ganhas;
                    vm.VLtotalGanhas = response.data.totais.VL_total_ganhas;
                    vm.qtdtotalPerdidas = response.data.totais.total_perdidas;
                    vm.VLtotalPerdidas = response.data.totais.VL_total_perdidas;
                    vm.qtdtotalAbertas = response.data.totais.total_abertas;
                    vm.VLtotalAbertas = response.data.totais.VL_total_abertas;

                    if (response.data.periodo) {
                        vm.inicio = response.data.periodo.inicio;
                        vm.fim = response.data.periodo.fim;
                    }

                    angular.forEach(response.data.etapas, function(value, key) {
                        var qtdTotal = value.total;
                        var qtdGanhos = value.ganhos;
                        var qtdPerdas = value.perdas;
                        var qtdAbertos = value.abertos;
                        var qtdAvancadas = value.avancadas;
                        var qtdAtrasados = value.atrasados;
                        if (qtdGanhos != 0 && vm.qtdtotalNeg != 0) {
                            var porcentGanhos = Math.round((qtdGanhos * 100) / vm.qtdtotalNeg);
                        } else {
                            var porcentGanhos = 0;
                        }
                        if (!porcentGanhos) {
                            var porcentGanhos = 0;
                        }
                        if (qtdPerdas != 0 && vm.qtdtotalNeg != 0) {
                            var porcentPerdas = Math.round((qtdPerdas * 100) / vm.qtdtotalNeg);
                        } else {
                            var porcentPerdas = 0;
                        }
                        if (!porcentPerdas) {
                            var porcentPerdas = 0;
                        }
                        if (qtdAbertos != 0 && vm.qtdtotalNeg != 0) {
                            var porcentAbertos = Math.round((qtdAbertos * 100) / vm.qtdtotalAbertas);
                        } else {
                            var porcentAbertos = 0;
                        }
                        if (!porcentAbertos) {
                            var porcentAbertos = 0;
                        }
                        if (qtdAvancadas != 0 && vm.qtdtotalNeg != 0) {
                            var porcentAvancadas = Math.round((qtdAvancadas * 100) / (value.total + value.avancadas));
                        } else {
                            var porcentAvancadas = 0;
                        }
                        if (!porcentAvancadas) {
                            var porcentAvancadas = 0;
                        }
                        if (qtdAtrasados != 0 && vm.qtdtotalNeg != 0) {
                            var porcentAtrasados = Math.round((qtdAtrasados * 100) / value.abertos);
                        } else {
                            var porcentAtrasados = 0;
                        }
                        if (!porcentAtrasados) {
                            var porcentAtrasados = 0;
                        }
                        response.data.etapas[key].porcentGanhos = porcentGanhos;
                        response.data.etapas[key].porcentPerdas = porcentPerdas;
                        response.data.etapas[key].porcentAbertos = porcentAbertos;
                        response.data.etapas[key].porcentAvancadas = porcentAvancadas;
                        response.data.etapas[key].porcentAtrasados = porcentAtrasados;
                        response.data.etapas[key].passaram_aqui = value.total + value.avancadas;
                        response.data.etapas[key].VLpassaram_aqui = value.VLtotal + value.VLavancadas;

                        $('.grafico_avancadas_' + value.etapa_id).empty();
                        $('.grafico_em_andamento_' + value.etapa_id).empty();
                        vm.gerarGraficoAvancadas(value.etapa_id, 0);
                        vm.gerarGraficoAndamento(value.etapa_id, 0);
                    });
                    if (response.data.etapas != 0) {
                        vm.etapasLista = response.data.etapas;
                    } else {
                        vm.etapasLista = '';
                    }
                    vm.carregarEficientes();
                }, function(error) {
                    console.log('Erro: ', error);
                });
            }, function(error) {
                console.log('Erro: ', error);
            });
        };

        vm.formatarTextos = function() {
            vm.carregando = false;
            $timeout(function() {
                var largura = 760;
                angular.forEach(vm.etapasLista, function(value, key) {
                    largura-=60;
                    $('.new_funnel_' + value.etapa_id).css('width', largura + 'px');
                });
            }, 100);
            /*$timeout(function() {
                var a = 1;
                angular.forEach(vm.etapasLista, function(value, key) {
                    var altura_box_img = $('.img_etapa_' + value.etapa_id).height();
                    var largura_box_img = $('.img_etapa_' + value.etapa_id).width();
                    var largura_img = $('.img_' + value.etapa_id).width();
                    var altura_img = $('.img_' + value.etapa_id).height();

                    var left = (largura_box_img / 2) - (largura_img / 2) + 0;
                    $('.img_etapa_' + value.etapa_id).height();
                    $('.texto_etapa_' + value.etapa_id).css('padding-top', altura_box_img / 2 - 5 + 'px');
                    $('.box_texto_' + value.etapa_id).css('width', largura_img + 'px');
                    $('.box_texto_' + value.etapa_id).css('height', altura_img + 'px');
                    $('.box_texto_' + value.etapa_id).css('left', left + 'px');
                    if (vm.etapasLista.length == a) {
                        $('.t').fadeIn('fast');
                    }
                    a++;
                });
            }, 800);*/
        };

        vm.expandirEtapa = function(etapa_id) {
            $('.detalhe_' + etapa_id).slideToggle('slow', function() {
                if ($(this).is(':hidden')) { // quando recolher o accordion zera os valores para fazer novamente a animaÃ§Ã£o
                    vm.zerar(etapa_id);
                } else {
                    angular.forEach(vm.etapasLista, function(value, key) {
                        if (vm.etapasLista[key].etapa_id == etapa_id) {
                            $('.linha_avancadas_' + etapa_id).animate({ width: vm.etapasLista[key].porcentAvancadas + '%' }, 600);
                            $('.circle_avancadas_' + etapa_id).animate({ left: (vm.etapasLista[key].porcentAvancadas - 1) + '%' }, 600);
                            $('.linha_ganhos_' + etapa_id).animate({ width: vm.etapasLista[key].porcentGanhos + '%' }, 600);
                            $('.circle_ganhos_' + etapa_id).animate({ left: (vm.etapasLista[key].porcentGanhos - 1) + '%' }, 600);
                            $('.linha_perdas_' + etapa_id).animate({ width: vm.etapasLista[key].porcentPerdas + '%' }, 600);
                            $('.circle_perdas_' + etapa_id).animate({ left: (vm.etapasLista[key].porcentPerdas - 1) + '%' }, 600);
                            $('.linha_abertas_' + etapa_id).animate({ width: vm.etapasLista[key].porcentAbertos + '%' }, 600);
                            $('.circle_abertas_' + etapa_id).animate({ left: (vm.etapasLista[key].porcentAbertos - 1) + '%' }, 600);
                            $('.linha_atrasados_' + etapa_id).animate({ width: vm.etapasLista[key].porcentAtrasados + '%' }, 600);
                            $('.circle_atrasados_' + etapa_id).animate({ left: (vm.etapasLista[key].porcentAtrasados - 1) + '%' }, 600);

                            $('.grafico_avancadas_' + etapa_id).empty();
                            $('.grafico_em_andamento_' + etapa_id).empty();
                            vm.gerarGraficoAvancadas(etapa_id, vm.etapasLista[key].porcentAvancadas);
                            vm.gerarGraficoAndamento(etapa_id, vm.etapasLista[key].porcentAbertos);
                        }
                    });
                    $('html, body').animate({
                        scrollTop: $('.detalhe_' + etapa_id).offset().top - 25 - $('.img_' + etapa_id).height()
                    }, 1000);
                    $('[data-toggle="tooltip"]').tooltip();
                }
            });

        };

        vm.zerar = function(etapa_id) {
            $('.linha_avancadas_' + etapa_id).animate({ width: 0 + '%' });
            $('.circle_avancadas_' + etapa_id).animate({ left: 0 + '%' });
            $('.linha_ganhos_' + etapa_id).animate({ width: 0 + '%' });
            $('.circle_ganhos_' + etapa_id).animate({ left: 0 + '%' });
            $('.linha_perdas_' + etapa_id).animate({ width: 0 + '%' });
            $('.circle_perdas_' + etapa_id).animate({ left: 0 + '%' });
            $('.linha_abertas_' + etapa_id).animate({ width: 0 + '%' });
            $('.circle_abertas_' + etapa_id).animate({ left: 0 + '%' });
            $('.linha_atrasados_' + etapa_id).animate({ width: 0 + '%' });
            $('.circle_atrasados_' + etapa_id).animate({ left: 0 + '%' });

            $('.grafico_avancadas_' + etapa_id).empty();
            $('.grafico_em_andamento_' + etapa_id).empty();
            vm.gerarGraficoAvancadas(etapa_id, 0);
            vm.gerarGraficoAndamento(etapa_id, 0);
        };


        vm.imprimir = function() {
            window.print();
        };

        vm.verificarNavegador = function() {
            // Opera 8.0+
            vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
            // Firefox 1.0+
            vm.isFirefox = typeof InstallTrigger !== 'undefined';
            // At least Safari 3+: "[object HTMLElementConstructor]"
            vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // Internet Explorer 6-11
            vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
            // Edge 20+
            vm.isEdge = !vm.isIE && !!window.StyleMedia;
            // Chrome 1+
            vm.isChrome = !!window.chrome && !!window.chrome.webstore;
            // Blink engine detection
            vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();

        vm.carregarEficientes = function() {
            vm.maisPropostas = {
                'ganhos': 0
            };
            vm.maisRentavel = {
                'VLganhos': 0
            };
            vm.menosPropostas = {
                'perdas': 0,
                'VLperdas': 0
            };

            // 1Âª CLAUSULA - BUSCAR ETAPA COM MAIS PROPOSTAS
            angular.forEach(vm.etapasLista, function(value, key) {
                if (value.ganhos > vm.maisPropostas.ganhos) {
                    vm.maisPropostas = value;
                }
                if (value.VLganhos > vm.maisRentavel.VLganhos) {
                    vm.maisRentavel = value;
                }
                if (value.perdas > vm.menosPropostas.perdas) {
                    vm.menosPropostas = value;
                }
            });

            // 2Âª CLAUSULA - SE HOUVEREM ETAPAS COM A MESMA QUANTIA DE PROPOSTAS, BUSCAR PELA ETAPA COM MAIOR VALOR
            angular.forEach(vm.etapasLista, function(value, key) {
                if (vm.maisPropostas.ganhos == value.ganhos) {
                    if (value.VLganhos > vm.maisPropostas.VLganhos) {
                        vm.maisPropostas = value;
                    }
                }
                if (vm.menosPropostas.perdas == value.perdas) {
                    if (value.VLperdas > vm.menosPropostas.VLperdas) {
                        vm.menosPropostas = value;
                    }
                }
            });

            // 3Âª CLAUSULA - SE HOUVEREM ETAPAS COM A MESMA QUANTIA DE PROPOSTAS E COM O MESMO VALOR, BUSCAR PELA ETAPA COM POSIÃ‡ÃƒO MAIS ALTA OU MAIS BAIXA
            angular.forEach(vm.etapasLista, function(value, key) {
                if (vm.maisPropostas.ganhos == value.ganhos) {
                    if (value.VLganhos == vm.maisPropostas.VLganhos) {
                        if (value.etapa_posicao < vm.maisPropostas.etapa_posicao) {
                            vm.maisPropostas = value;
                        }
                    }
                }
                if (vm.maisRentavel.VLganhos == value.VLganhos) {
                    if (value.etapa_posicao < vm.maisRentavel.etapa_posicao) {
                        vm.maisRentavel = value;
                    }
                }
                if (vm.menosPropostas.perdas == value.perdas) {
                    if (value.VLperdas == vm.menosPropostas.VLperdas) {
                        if (value.etapa_posicao > vm.menosPropostas.etapa_posicao) {
                            vm.menosPropostas = value;
                        }
                    }
                }
            });

            var valor = $filter('currency')(vm.maisRentavel.VLganhos, 'R$ ', 2);
            vm.tamanho_valor = valor.length;
        };

        vm.abrirModalAjuda = function() {
            $('#modalAjudaAnalise').modal({
                //backdrop: 'static',
                //keyboard: false,
                show: true
            });
        };

        // COMBOBOX VISUALIZACAO --- INICIO
        vm.visualizando = 'todos';

        vm.grupos = [
            { 'gru_id': 1, 'gru_nome': 'Grupo A' },
            { 'gru_id': 2, 'gru_nome': 'Grupo B' },
            { 'gru_id': 3, 'gru_nome': 'Grupo C' },
        ];

        vm.buscaGrupos = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                usuariosService.findGrupo(dados)
                    .then(function(response) {
                        if (response.data != 0) {
                            vm.grupolista = response.data;
                            if (vm.usuarioLogado.fk_perfil_id != 1 && vm.usuarioLogado.fk_perfil_id != 3 && vm.roleVisualiza != 1) {
                                vm.grupolista = $filter('filter')(vm.grupolista, { gru_id: vm.usuarioLogado.fk_gru_id });
                            }
                            $timeout(function() {
                                $('.select_visu').selectpicker();
                                $timeout(function() {
                                    vm.mostrarCombo = true;
                                });
                            }, 100);
                        } else {
                            vm.grupolista = '';
                        };
                    }, function(error) {
                        console.error(error);
                    });
            });
        };

        vm.buscaRepresentantes = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                usuariosService.findRepresentantes(dados)
                    .then(function(response) {
                        if (response.data != 0) {
                            vm.representantelista = response.data;
                            $timeout(function() {
                                $('.select_visu').selectpicker();
                                $timeout(function() {
                                    vm.mostrarCombo = true;
                                });
                            }, 100);
                        } else {
                            vm.representantelista = '';
                        };
                    }, function(error) {
                        console.error(error);
                    });
            });
        };

        vm.buscarUsuarioLogado = function() {
            var dados = {
                'hash': Cookies.get('crm_funil_hash')
            }
            compromissosService.buscarUsuarioEspecifico(dados).then(function(response) {
                vm.usuarioLogado = response.data[0];
                vm.verifDadosUser();
            }, function(error) {
                console.error(error);
            });
        }
        //vm.buscarUsuarioLogado();

        vm.mostrarCombo = false;
        vm.mostrarVisuGeral = true;
        vm.mostrarUsuario = true;
        vm.naoMostrar = false;
        vm.verifDadosUser = function() {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localNeg = window.localStorage.getItem('isModNegociacao');
            var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

            if (valLocalNeg.visualiza) {
                vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
                switch (parseInt(valLocalNeg.visualiza)) {
                    case 4:
                        $timeout(function() {
                            vm.mostrarCombo = false;
                            vm.mostrarUsuario = false;
                            vm.carregando = false;
                            vm.naoMostrar = true;
                        });
                        break;
                    case 3:
                        $timeout(function() {
                            vm.mostrarCombo = false;
                            vm.mostrarVisuGeral = false;
                            vm.visualizando = "user_" + vm.usuarioLogado.user_id;
                            vm.carregarEtapas(vm.visualizando, vm.roleVisualiza);
                            $timeout(function() {
                                vm.enviarVisualizando = Base64.encode(vm.visualizando);
                            });
                        });
                        break;
                    case 2:
                        $timeout(function() {
                            vm.buscaGrupos();
                            if (vm.usuarioLogado.fk_perfil_id == 4 || vm.usuarioLogado.fk_perfil_id == 5) {
                                vm.mostrarVisuGeral = false;
                                vm.mostrarUsuario = true;
                            }
                            vm.visualizando = "grupo_" + vm.usuarioLogado.fk_gru_id;
                            vm.carregarEtapas(vm.visualizando, vm.roleVisualiza);
                            $timeout(function() {
                                vm.enviarVisualizando = Base64.encode(vm.visualizando);
                            });
                        });
                        break;
                    default:
                        $timeout(function() {
                            vm.buscaGrupos();
                            vm.buscaRepresentantes();
                            vm.visualizando = "todos";
                            vm.mostrarVisuGeral = true;
                            vm.mostrarUsuario = true;
                            vm.carregarEtapas(vm.visualizando, vm.roleVisualiza);
                            $timeout(function() {
                                vm.enviarVisualizando = Base64.encode(vm.visualizando);
                            });
                        });
                }
            }
        }

        vm.mudarVisualizacao = function(visu) {
            vm.carregarEtapas(vm.visualizando, vm.roleVisualiza);
            $timeout(function() {
                vm.enviarVisualizando = Base64.encode(vm.visualizando);
            });
        }

        vm.carregando = true;

        // COMBOBOX VISUALIZACAO --- FIM

        // SELECT PERIODO --- INICIO
        vm.dateRangePeriodo = function() {
            function cb(start, end, qual) {
                if (qual == 'Customizar') {
                    $('.box_periodo_select span').html(start.format('D') + ' de ' + start.format('MMM') + '. de ' + start.format('YYYY') + ' - ' + end.format('D') + ' de ' + end.format('MMM') + '. de ' + end.format('YYYY'));
                } else {
                    $('.box_periodo_select span').html(qual);
                }
                //vm.buscaClientesRentaveis(start, end);
            }
            cb(moment().startOf('year'), moment().endOf('year'), 'Este ano');
            $('.box_periodo_select').daterangepicker({
                'opens': 'center',
                'locale': {
                    'format': 'DD/MM/YYYY',
                    'cancelLabel': 'Limpar'
                },
                ranges: {
                    /*'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],*/
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Este ano': [moment().startOf('year'), moment().endOf('year')],
                    'Ano passado': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
                }
            }, cb);
        }

        vm.buscarAnosNegocios = function() {
            var userDateStorage = JSON.parse(
                API.funilRC.decrypt(
                    $window.localStorage.getItem('5c479de2')
                )
            );

            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'rolevisu': vm.roleStorageVisuNego,
                    'perfilid': userDateStorage[0].fk_perfil_id,
                    'userId': userDateStorage[0].user_id,
                    'gruId': userDateStorage[0].fk_gru_id
                }

                negociosService.carregarAnosNegocios(dados).then(function(response) {
                    if (response.data != 0) {
                        vm.anosNegocios = response.data;
                    } else {
                        vm.anosNegocios = '';
                    }
                }, function(error) {
                    console.error(error);
                });
            });
        }

        vm.periodo = "todos";
        // SELECT PERIODO --- FIM


        /*MÚLTIPLOS FUNIS DE VENDA --- INÍCIO*/
        vm.listarFunisVendas = function() {
            vm.funisVenda = [];
            loadFunisVenda().then(function(response) {
                $timeout(function() {
                    vm.funisVenda = response;
                    vm.funil_venda_grafico = response[0].funv_id;                    
                    vm.buscarUsuarioLogado();
                });
            });
        };
        vm.listarFunisVendas();
        /*MÚLTIPLOS FUNIS DE VENDA --- FIM*/

    }

})();