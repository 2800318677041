(function() {
    'use strict';

    angular
        .module('app')
        .component('listAtdLigacao', {
            templateUrl: 'app/components/listAtdLigacao/listAtdLigacao.template.html',
            bindings: {
                dados: '=',
                local: '='
            },
            controller: function($element, $filter, FactoryTotalVoice){

                const $ctrl = this;

                $ctrl.atd = $ctrl.dados;
                
                $ctrl.playGravacao = function(url_gravacao, id_chamada, qual){
                    FactoryTotalVoice.playGravacao(url_gravacao, id_chamada, qual);
                }
                
            }
        })
}());
