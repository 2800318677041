/*
* @Author: Glauber Funez
* @Date:   2018-03-19 09:56:19
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:52
*/

(function() {
    'use strict';

    angular
        .module('negocios')
        .controller('kanbanController', kanbanController)

    kanbanController.$inject = ['$routeParams', 'mensagem', '$location', 'negociosService', '$http', 'API', '$filter', '$timeout', '$q', 'orderByFilter', '$mdBottomSheet', 'loadSaas', 'validarSaas', 'rangeDatas', '$window', 'FactoryRoles', '$log', 'FactoryWebhook', '$scope', 'loadFunisVenda'];

    function kanbanController($routeParams, mensagem, $location, negociosService, $http, API, $filter, $timeout, $q, orderBy, $mdBottomSheet, loadSaas, validarSaas, rangeDatas, $window, FactoryRoles, $log, FactoryWebhook, $scope, loadFunisVenda) {
        var vm = this;

        vm.url = API.url;
        vm.searchUrl = function(qual, value) {
            $location.search(qual, value);
        }

        vm.resultCriaRoleNeg = FactoryRoles.checkRolesCria('isModNegociacao');
        vm.resultCriaFunil = FactoryRoles.checkRolesCria('isModFunil');

        vm.listarEtapasKanban = function(resp) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash'));
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.continuarlistarEtapasKanban(resp);
            });
        };

        vm.continuarlistarEtapasKanban = function(resp) {
            $timeout(function() {
                vm.carregando = true;
            });
            if (resp) { // responsável foi selecionado
                var dados = {
                    'id_resp': resp,
                    'saasid': vm.saasid,
                    'funil_venda': vm.funil_venda_selecionado,
                };
                vm.searchUrl('prazo', null);
                if (vm.inicio) { // prazo foi selecionado
                    var dados = {
                        'id_resp': resp,
                        'inicio': vm.inicio.format('YYYY-MM-DD'),
                        'fim': vm.fim.format('YYYY-MM-DD'),
                        'saasid': vm.saasid,
                        'funil_venda': vm.funil_venda_selecionado,
                    };
                    if (vm.zerouPrazo) {
                        vm.searchUrl('prazo', null);
                    } else {
                        vm.searchUrl('prazo', dados.inicio + "_" + dados.fim);
                    }
                }
            } else { // nenhum responsável selecionado
                var dados = '';
                if (vm.inicio) { // prazo foi selecionado
                    var dados = {
                        'id_resp': resp,
                        'inicio': vm.inicio.format('YYYY-MM-DD'),
                        'fim': vm.fim.format('YYYY-MM-DD'),
                        'saasid': vm.saasid,
                        'funil_venda': vm.funil_venda_selecionado,
                    };
                    if (vm.zerouPrazo) {
                        vm.searchUrl('prazo', null);
                    } else {
                        vm.searchUrl('prazo', dados.inicio + "_" + dados.fim);
                    }
                } else {
                    var dados = {
                        'saasid': vm.saasid,
                        'funil_venda': vm.funil_venda_selecionado,
                    };
                    vm.searchUrl('prazo', null);
                }
            }
            dados.user_hash = Cookies.get('crm_funil_hash');
            dados.mod_id = 3;
            negociosService.carregarEtapasKanban(dados).then(function(response) {
                vm.etapasLista = [];
                if (response.data != 0) {
                    $timeout(function() {
                        for (var a = 0; a < response.data.length; a++) {
                            vm.etapasLista[a] = {
                                'etapa_id': response.data[a].etapa_id,
                                'etapa_nome': response.data[a].etapa_nome,
                                'etapa_posicao': response.data[a].etapa_posicao,
                                'qtd_propostas': response.data[a].qtd_propostas,
                                'valor_total': response.data[a].valor_total,
                                'negociacoes': []
                            };
                        }
                        if (resp) { // se um responsável foi selecionado executa a função de listagem de negociações enviando o id do responsável
                            vm.listarNegociosKanban(resp);
                        } else {
                            vm.listarNegociosKanban();
                        }
                    });
                } else {
                    $timeout(function() {
                        vm.etapasLista = '';
                    });
                }
            }, function(error) {
                $log.error(error);
            });
        };
        /*vm.listarEtapasKanban();*/

        vm.listarNegociosKanban = function(resp) {
            if (resp) { // responsável selecionado - envia o array com id e ordenação
                var dados = {
                    'id_resp': resp,
                    'ordenar': vm.ordenarNeg,
                    'saasid': vm.saasid,
                    'funil_venda': vm.funil_venda_selecionado,
                };
                if (vm.inicio) {
                    var dados = {
                        'id_resp': resp,
                        'ordenar': vm.ordenarNeg,
                        'inicio': vm.inicio.format('YYYY-MM-DD'),
                        'fim': vm.fim.format('YYYY-MM-DD'),
                        'saasid': vm.saasid,
                        'funil_venda': vm.funil_venda_selecionado,
                    };
                }
            } else {
                var dados = {
                    'ordenar': vm.ordenarNeg,
                    'saasid': vm.saasid,
                    'funil_venda': vm.funil_venda_selecionado,
                };
                if (vm.inicio) { // prazo selecionado
                    var dados = {
                        'ordenar': vm.ordenarNeg,
                        'inicio': vm.inicio.format('YYYY-MM-DD'),
                        'fim': vm.fim.format('YYYY-MM-DD'),
                        'saasid': vm.saasid,
                        'funil_venda': vm.funil_venda_selecionado,
                    };
                }
            }
            dados.user_hash = Cookies.get('crm_funil_hash');
            dados.mod_id = 3;
            negociosService.carregarNegociosKanban(dados).then(function(response) {

                var quant_etapas = vm.etapasLista.length;
                for (var b = 0; b < response.data.length; b++) {
                    for (var c = 0; c < vm.etapasLista.length; c++) {
                        if (response.data[b].etapa_posicao == vm.etapasLista[c].etapa_posicao) {
                            var qual_armazenar = c;
                        }
                    }
                    vm.etapasLista[qual_armazenar].negociacoes.push(response.data[b]);
                }
                if (response.data != 0) {
                    $timeout(function() {
                        vm.negociosLista = response.data;
                    });
                } else {
                    $timeout(function() {
                        vm.negociosLista = '';
                    });
                }
            }, function(error) {
                $log.error(error);
            });
        };
        vm.carregando = true;
        vm.acabouEtapas = function() { // DEFINIR LARGURA DAS ETAPAS
            $timeout(function() {
                vm.carregando = false;
            }, 100);

            vm.largura = parseFloat(100 / vm.etapasLista.length);
            if (vm.etapasLista.length > 5){ // MAIS DE 5 ETAPAS, CRIAR BARRA DE ROLAGEM
                $timeout(function() {
                    /*console.log("$('.box_kanban').width()");
                    console.log($('.box_kanban').width());*/
                    $('.box_kanban').css('display', 'inline-flex');
                    $('.box_kanban').css('width', '93vw');
                    $('.box_kanban').css('max-width', '93vw');
                    var largura = parseFloat($('.box_kanban').width() / 5);
                    $('.box_kanban').addClass('rolagem_horizontal');
                    $('.etapas_kanban').css('width', largura + 'px');
                    $('.interno_etapas_kanban').css('width', (largura - 4) + 'px');
                });
            } else { // ATÉ 5 ETAPAS
                $timeout(function() {
                    $('.etapas_kanban').css('width', vm.largura + '%');
                });

            }
        };

        vm.abrirModalConfigEtapas = function() {
            vm.initNewFunil();
            vm.etapasNegociosConfig();
            $('#modalConfigEtapas').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            });
        };

        vm.moverNegociacaoKanban = function(id, index, key) {
            /*for (var a = 0; a < vm.etapasLista[key].negociacoes.length; a++) {
                if (vm.etapasLista[key].negociacoes[a].prop_id == id) {
                    var index = a;
                }
            }
            $(".box_negociacao_" + id).addClass("box_negociacao_marcado_move");
            $(".box_negociacao").addClass("box_negociacao_marcado_outros");*/
            $timeout(function() {
                if (vm.filtro_resp || vm.atrasado) {
                    vm.etapasLista[key].negociacoes = $filter('filter')(vm.etapasLista[key].negociacoes, {
                        'atrasado': vm.atrasado,
                        'user_slug': vm.filtro_resp
                    });
                }
                vm.etapasLista[key].negociacoes.splice(index, 1);
            });
            /*$(".val_etapa").removeClass("fadeInUp");
            $(".qtd_etapa").removeClass("fadeInUp");

            for (var a = 0; a < vm.etapasLista.length; a++) {
                var total_etapa = 0;
                for (var b = 0; b < vm.etapasLista[a].negociacoes.length; b++) {
                    var valor_etapa = angular.copy(parseFloat(vm.etapasLista[a].negociacoes[b].prop_valor));
                    total_etapa = total_etapa + valor_etapa;
                }

                if (parseFloat(vm.etapasLista[a].valor_total).toFixed(2) != parseFloat(total_etapa).toFixed(2)) {
                    vm.etapasLista[a].valor_total = parseFloat(total_etapa).toFixed(2);
                    $(".val_etapa_" + vm.etapasLista[a].etapa_id).html($filter('currency')(total_etapa, "R$", 2));
                    $(".val_etapa_" + vm.etapasLista[a].etapa_id).addClass("fadeInUp");
                }
                if (parseInt(vm.etapasLista[a].qtd_propostas) != parseInt(vm.etapasLista[a].negociacoes.length)) {
                    vm.etapasLista[a].qtd_propostas = parseInt(vm.etapasLista[a].negociacoes.length);
                    if (vm.etapasLista[a].negociacoes.length == 0) {
                        $(".qtd_etapa_" + vm.etapasLista[a].etapa_id).html("Nenhuma negociação");
                    } else if (vm.etapasLista[a].negociacoes.length == 1) {
                        $(".qtd_etapa_" + vm.etapasLista[a].etapa_id).html($filter('number')(vm.etapasLista[a].negociacoes.length, 0) + " negociação");
                    } else {
                        $(".qtd_etapa_" + vm.etapasLista[a].etapa_id).html($filter('number')(vm.etapasLista[a].negociacoes.length, 0) + " negociações");
                    }
                    $(".qtd_etapa_" + vm.etapasLista[a].etapa_id).addClass("fadeInUp");
                }
            }
            $timeout(function() {
                $(".box_negociacao_" + id).removeClass("box_negociacao_marcado_move");
                $(".box_negociacao").removeClass("box_negociacao_marcado_outros");
            }, 1000);*/
        };

        vm.terminouMover = function(para_onde, index_array, event) {
            var dados = {
                'posicao': vm.etapasLista[index_array].etapa_id,
                'dados': vm.etapasLista[index_array].negociacoes[para_onde],
                'user_hash': Cookies.get('crm_funil_hash'),
                'funil_venda': vm.funil_venda_selecionado,
            };

            negociosService.moverNegKanban(dados).then(function(response) {

            }, function(error) {
                $log.error(error);
            });
        };

        // MODAL CONFIGURAR ETAPAS --- INÍCIO



        vm.etapasNegociosConfig = function() {
            vm.etapasListaConfig = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                negociosService.carregarEtapasNegociosConfig(dados).then(function(response) {
                    console.log("carregarEtapasNegociosConfig");
                    console.log(response.data);
                    vm.etapasListaConfig = response.data;
                    return null;
                    if (response.data != 0) {
                        $timeout(function() {
                            var a = 0;
                            var ativos = $filter('filter')(response.data, {
                                etapa_ativo: '!0'
                            }); // retira os inativos

                            for (var i = 0; i < ativos.length; i++) {
                                if (ativos[a]) {
                                    vm.etapasListaConfig[i] = {
                                            'etapa_id': ativos[a].etapa_id,
                                            'etapa_nome': ativos[a].etapa_nome,
                                            'etapa_posicao': ativos[a].etapa_posicao
                                        };
                                    a++;
                                }
                            }

                            var dados = $filter('filter')(response.data, {
                                etapa_ativo: '!1'
                            }); // retira os ativos
                            var a = 0;
                            for (var i = vm.etapasListaConfig.length; i < response.data.length; i++) {
                                vm.etapasListaConfig[i] = {
                                    'etapa_id': dados[a].etapa_id,
                                    'etapa_nome': '',
                                    'etapa_posicao': ''
                                };
                                a++;
                            }
                        });
                    } else {
                        $timeout(function() {
                            vm.etapasListaConfig = '';
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        vm.etapasNegociosConfig();

        vm.etapaMovida = function(index, index2) {
            vm.etapasListaConfig[index2]['etapas'].splice(index, 1);
        };

        vm.editarEtapa = function(id) {
            $('#etapa_' + id).toggleClass('hide');
            $('#campo_edit_' + id).toggleClass('hide');
            $('#campo_edit_' + id).focus();
            $('#campo_edit_' + id).select();
        };
        vm.cancelEditarEtapa = function(id) {
            $('#etapa_' + id).toggleClass('hide');
            $('#campo_edit_' + id).toggleClass('hide');
        };

        vm.novaEtapa = function(index) {
            $('.icon_add_etapa_' + index).toggleClass('hide');
            $('.cadastro_etapa_' + index).toggleClass('hide');
            $('#campo_cadastro_' + index).toggleClass('hide');
            $('#campo_cadastro_' + index).focus();
        };
        vm.cancelnovaEtapa = function(index) {
            $('.icon_add_etapa_' + index).toggleClass('hide');
            $('.cadastro_etapa_' + index).toggleClass('hide');
            $('#campo_cadastro_' + index).toggleClass('hide');
        };
        vm.cancelarCadastro = function(index) {
            $('.icon_add_etapa_' + index).toggleClass('hide');
            $('.cadastro_etapa_' + index).toggleClass('hide');
            $('#campo_cadastro_' + index).val('');
            $('#campo_cadastro_' + index).toggleClass('hide');
        };

        vm.alterarEtapa = function(id, nome) {
            $('.check_etapa_' + id).hide(); // mostra check sucesso
            vm.cancelEditarEtapa(id);
        };

        vm.salvarEtapa = function(index, id, nome, index2) {
            vm.etapasListaConfig[index2]['etapas'][index]['etapa_id'] = id ? id : '';
            vm.etapasListaConfig[index2]['etapas'][index]['etapa_nome'] = nome;
            vm.etapasListaConfig[index2]['etapas'][index]['etapa_posicao'] = index + 1;
            vm.cancelnovaEtapa(index);
        };
        vm.ordenar = false;
        vm.removerEtapa = function(id, index, index2) {
            swal({
                    title: 'Deseja excluir esta etapa?',
                    text: 'Esta ação é irreversível',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    var dados = {
                        'id': id
                    };
                    negociosService.verificaApagarEtapasNegociosConfig(dados).then(function(response) {
                        if (response.data != 0 && response.data != '') { // EXISTEM NEGOCIAÇÕES
                            swal({
                                title: 'Não foi possível excluir!',
                                text: 'Existem negociações vinculadas com esta etapa.',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            });
                        } else { // NÃO EXISTEM NEGOCIAÇÕES
                            vm.etapasListaConfig[index2]['etapas'][index]['etapa_id'] = id;
                            vm.etapasListaConfig[index2]['etapas'][index]['etapa_nome'] = '';
                            vm.etapasListaConfig[index2]['etapas'][index]['etapa_posicao'] = index + 1;
                            swal({
                                title: 'Removido!',
                                text: 'Etapa removida com sucesso!',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            //vm.etapasListaConfig = orderBy(vm.etapasListaConfig, "etapa_nome", true);
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                });
        };

        vm.salvando = false;
        vm.salvarBDEtapa = function() {
            vm.salvando = true;
            negociosService.salvarEtapasNegociosConfig(vm.etapasListaConfig).then(function(response) {
                console.log("salvarBDEtapa");
                console.log(response.data);
                /*$timeout(function() {
                    vm.salvando = false;
                });
                return null;*/
                $timeout(function() {
                    vm.salvando = false;
                });
                vm.listarEtapasKanban();
                $('#modalConfigEtapas').modal('hide');
            }, function(error) {
                $log.error(error);
            });
        };

        vm.addEtapa = function(index){
            vm.etapasListaConfig[index].etapas.push({'etapa_id': '','etapa_nome': '','etapa_posicao': ''});
            $timeout(function() {
                vm.novaEtapa(vm.etapasListaConfig[index].etapas.length - 1);
            }, 100);
        }


        // MODAL CONFIGURAR ETAPAS --- FIM


        vm.acabouNegKanban = function() {

            $timeout(function() {
                $('.ul_negociacoes').niceScroll({
                    zindex: '30'
                });
            }, 100);
        };

        vm.mostrarDetalhesNegociacao = function(id_prop) {
            $('.box_negociacao_' + id_prop).addClass('box_negociacao_marcado');
            $mdBottomSheet.show({
                locals: {
                    id: id_prop
                },
                templateUrl: 'negocios/template/box_detalhes_negociacao.html' + version,
                controller: 'boxDetalhesCtrl',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                disableParentScroll: false,
                disableBackdrop: false,
                onRemove: function(scope, element, options) {
                    if (scope.vm.alterado) { // se ouve alteração no box detalhes atualiza as negociações do kanban
                        ReloadNegocios();
                    }
                    $('.box_negociacao').removeClass('box_negociacao_marcado');
                    var scrollmask = document.getElementsByClassName('md-scroll-mask');
                    var backdrop = document.getElementsByTagName('md-backdrop');
                    angular.element(scrollmask).remove();
                    angular.element(backdrop).remove();
                    angular.element(element).addClass('animated');
                    angular.element(element).addClass('fadeOutDownBig');
                    //angular.element(element).remove();
                    $mdUtil.enableScrolling();
                }
            }).then(function() {
                vm.listarEtapasKanban();
                $('.box_negociacao').removeClass('box_negociacao_marcado');
            });
        };

        function ReloadNegocios() {
            vm.listarEtapasKanban();
        }


        vm.buscaUsuarios = function(responsavel) {
            vm.responsavel = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'acao': 'VISUALIZA',
                    'mod_id': 3
                };
                negociosService.findUsuarios(dados).then(function(response) {
                    $timeout(function() {
                        vm.usuarios = response.data;

                        // VERIFICAR PERFIL DO USUÁRIO LOGADO PARA FILTAR CASO SEJA VENDEDOR(2) OU REPRESENTANTE(5)
                        for (var x = 0; x < response.data.length; x++) {
                            if (response.data[x].user_hash == Cookies.get('crm_funil_hash')) {
                                vm.responsavel = response.data[x];
                                if (response.data[x].fk_perfil_id == 2 || response.data[x].fk_perfil_id == 5) {
                                    vm.filtro_resp = response.data[x].user_slug;
                                }
                            }
                        }
                        vm.verifMostrarBtnEditar();
                    });
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        /*ORDENAÇÃO DAS NEGOCIAÇÕES --- INÍCIO*/
        var init = 0;
        var init3 = 0;
        var init8 = 0;
        vm.ordenarNegociacoes = function(qual) {
            vm.ordenarNeg = qual;

            var search = $location.search();
            if (search.prazo && init == 0) {
                var arrPrazo = search.prazo.split('_');
                vm.inicio = moment(arrPrazo[0]);
                vm.fim = moment(arrPrazo[1]);
                $timeout(function() {
                    $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicio);
                    $('.reportrangeN').data('daterangepicker').setEndDate(vm.fim);
                    $('.reportrangeN span').html(vm.inicio.format('D') + ' de ' + vm.inicio.format('MM') + '/' + vm.inicio.format('YY') + ' - ' + vm.fim.format('D') + ' de ' + vm.fim.format('MM') + '/' + vm.fim.format('YY'));
                }, 400);
            }
            init = 1;

            if (search.ordenar && init3 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                vm.ordenacao = search.ordenar;
                vm.ordenarNeg = search.ordenar;
                qual = search.ordenar;
            } else if (!qual || qual == 'recentes') {
                qual = null;
            }
            init3 = 1;

            if ($routeParams.slugDoUserNotifi && init8 == 0) {
                vm.ordenacao = 'prazo_menor';
                vm.ordenarNeg = 'prazo_menor';
                qual = 'prazo_menor';
                init8 = 1;
            }

            //vm.listarEtapasKanban();
            vm.listarFunisVendas();

            vm.searchUrl('ordenar', qual);
        };
        /*ORDENAÇÃO DAS NEGOCIAÇÕES --- FIM*/

        /*BARRA DE FILTROS --- INÍCIO*/

        /*FILTRO RESPONSÁVEL*/
        var init2 = 0;
        vm.filtrar_responsavel = function(qual) {

            var search = $location.search();
            if (search.responsavel && init2 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                vm.filtro_resp = search.responsavel;
                qual = search.responsavel;
            } else if (!qual) {
                qual = null;
                delete vm.filtro_resp;
            }
            init2 = 1;

            /*if (qual) {
                vm.listarEtapasKanban(qual);
            } else {
                vm.listarEtapasKanban();
            }*/

            vm.searchUrl('responsavel', qual);
        };
        vm.filtrar_responsavel();

        /*FILTRO PRAZO*/
        vm.dateRangeN = function() {
            function cb(start, end) {
                if (start._isValid) { // datas validas
                    if (start.format('DD/MM/YYYY') == vm.inicial.format('DD/MM/YYYY') && end.format('DD/MM/YYYY') == vm.final.format('DD/MM/YYYY')) {
                        $('.reportrangeN span').html('Prazo');
                        vm.zerouPrazo = true;
                    } else {
                        $('.reportrangeN span').html(start.format('D') + '/' + start.format('MM') + '/' + start.format('YY') + ' - ' + end.format('D') + '/' + end.format('MM') + '/' + start.format('YY'));
                        vm.zerouPrazo = false;
                    }
                    vm.inicio = start;
                    vm.fim = end;
                    vm.listarEtapasKanban(vm.filtro_resp);
                } else {
                    $('.reportrangeN span').html('Prazo');
                    delete vm.inicio;
                    delete vm.fim;
                    vm.listarEtapasKanban(vm.filtro_resp);
                }
            }
            $timeout(function() {
                $('.reportrangeN').daterangepicker({
                    'opens': 'center',
                    ranges: {
                        'Prazo': [vm.inicial, vm.final],
                        'Hoje': [moment(), moment()],
                        'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                        'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
                        'Este mês': [moment().startOf('month'), moment().endOf('month')],
                        'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                }, cb);
                $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicial);
                $('.reportrangeN').data('daterangepicker').setEndDate(vm.final);
            });
        };
        /*BARRA DE FILTROS --- FIM*/

        // CARREGAR PRIMEIRA E ULTIMO PRAZO DA LISTA DE COMPROMISSOS --- INÍCIO
        vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
        vm.items.then(function(items) {
            vm.saasid = items;
            vm.items = rangeDatas('negocios', vm.saasid);
            vm.items.then(function(datas) {
                vm.inicial = moment(datas.inicial, 'DD/MM/YYYY');
                vm.final = moment(datas.final, 'DD/MM/YYYY');
                vm.dateRangeN();
            });
        });
        // CARREGAR PRIMEIRA E ULTIMO PRAZO DA LISTA DE COMPROMISSOS --- FIM


        if ($routeParams.slugUserDash) { // ACESSO VIA DASHBOARD
            console.log('entrou dash');
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'acao': 'VISUALIZA',
                    'mod_id': 3
                };
                negociosService.findUsuarios(dados).then(function(response) {
                    $timeout(function() {
                        vm.usuarios = response.data;
                        $timeout(function() {
                            for (var x = 0; x < vm.usuarios.length; x++) {
                                if (vm.usuarios[x].user_slug == $routeParams.slugUserDash) {
                                    vm.filtro_resp = vm.usuarios[x].user_slug;
                                }
                            }
                            //vm.filtrar_responsavel(vm.filtro_resp);
                        });
                    });
                }, function(error) {
                    $log.error(error);
                });
            });
        } else if ($routeParams.slugDoUserNotifi) {
            console.log('atrasados + de 3o dias: ', $routeParams.slugDoUserNotifi);
            vm.filtro_resp = $routeParams.slugDoUserNotifi;

        } else if ($routeParams.slugUserDashAtrasados) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'acao': 'VISUALIZA',
                    'mod_id': 3
                };
                negociosService.findUsuarios(dados).then(function(response) {
                    $timeout(function() {
                        vm.usuarios = response.data;
                        $timeout(function() {
                            for (var x = 0; x < vm.usuarios.length; x++) {
                                if (vm.usuarios[x].user_slug == $routeParams.slugUserDashAtrasados) {
                                    vm.filtro_resp = vm.usuarios[x].user_slug;
                                }
                            }
                            //vm.filtrar_responsavel(vm.filtro_resp);
                            vm.atrasado = 1;
                        });
                    });
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.validaAtrasado = function(data) {
            var dataatual = new Date();
            var d = dataatual.getDate();
            var m = dataatual.getMonth();
            m += 1;
            var y = dataatual.getFullYear();
            if (d < 10) {
                d = '0' + d;
            }
            if (m < 10) {
                m = '0' + m;
            }
            var data_formatada = (d + '/' + m + '/' + y);
            var atual = moment(data_formatada, 'DD/MM/YYYY');
            var escolhida = moment(data, 'DD/MM/YYYY');

            var diferenca = escolhida.diff(atual, 'days');
            return diferenca;
        };

        vm.mostrarBtnEditar = false;
        vm.verifMostrarBtnEditar = function() {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localPessoas = window.localStorage.getItem('isModFunil');
            var valLocalPessoas = JSON.parse(API.funilRC.decrypt(localPessoas));

            switch (parseInt(valLocalPessoas.altera)) {
                case 4:
                    $timeout(function() {
                        vm.mostrarBtnEditar = false;
                        vm.roleSemAcesso = true;
                    });
                    break;
                case 3:
                    if (valLocal[0].user_id == vm.responsavel.user_id) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                            vm.userIdRole = valLocal[0].user_id;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                case 2:
                    if (valLocal[0].fk_gru_id == vm.responsavel.fk_gru_id) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                            vm.grupoIdRole = valLocal[0].fk_gru_id;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                default:
                    $timeout(function() {
                        vm.mostrarBtnEditar = true;
                    });
            }
        }

        function verifSearch() {
            var search = $location.search();
            if (!search.prazo && !search.responsavel && !search.ordenar) {
                vm.filtrar_responsavel();
                vm.inicio = '';
                vm.fim = '';
                vm.ordenacao = 'recentes';
                vm.ordenarNeg = 'recentes';
                $('.reportrangeN span').html('Prazo');
                vm.listarEtapasKanban();
            }
        }
        $scope.$on("$locationChangeSuccess", verifSearch);


        /*MÚLTIPLOS FUNIS DE VENDA --- INÍCIO*/
        vm.tabSelecionada = 0;
        vm.listarFunisVendas = function() {
            vm.funisVenda = [];
            loadFunisVenda().then(function(response) {
                console.log("loadFunisVenda");
                console.log(response);
                $timeout(function() {
                    vm.funisVenda = response;
                    vm.funil_venda_selecionado = response[0].funv_id;
                    vm.listarEtapasKanban();
                });
            });
        };

        vm.alterarFunilVenda = function(id) {
            vm.carregando = true;
            vm.funil_venda_selecionado = id;
            vm.listarEtapasKanban();
            $timeout(function() {
                $('html,body').scrollTop(0);
            });
        };

        vm.initNewFunil = function(){
            $timeout(function() {
                vm.newFunil = {
                    "nome" : '',
                    "etapas" : []
                }
                vm.newFunil.etapas.push({'etapa_id': '','etapa_nome': '','etapa_posicao': ''});
                vm.newFunil.etapas.push({'etapa_id': '','etapa_nome': '','etapa_posicao': ''});
            });
        }

        vm.salvarNewFunil = function() {
            var dados = {
                'funil': vm.newFunil,
                'saasid': vm.saasid,
            };
            negociosService.salvarNewFunil(dados).then(function(response) {
                console.log("salvarNewFunil");
                console.log(response.data);
                vm.initNewFunil();
                vm.etapasNegociosConfig();
                $timeout(function() {
                    vm.tabSelecionada = vm.etapasListaConfig.length - 1;
                }, 500);
            }, function(error) {
                $log.error(error);
            });
        };


        vm.desativarFunilVenda = function(id, nome) {
            swal({
                    title: 'Deseja realmente desativar o funil de vendas ' + nome +'?',
                    text: 'Esta ação é irreversível',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    var dados = {
                        'id': id
                    };
                    negociosService.verificaApagarFunilNegociosConfig(dados).then(function(response) {
                        if (response.data != 0 && response.data != '') { // EXISTEM NEGOCIAÇÕES
                            swal({
                                title: 'Não foi possível excluir!',
                                text: 'Existem negociações vinculadas com este funil.',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            });
                        } else { // NÃO EXISTEM NEGOCIAÇÕES
                            negociosService.desativarFunilVenda(dados).then(function(response) {
                                console.log("desativarFunilVenda");
                                console.log(response.data);
                                swal({
                                    title: 'Removido!',
                                    text: 'Etapa removida com sucesso!',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 1000
                                });
                                vm.etapasNegociosConfig();
                                vm.listarFunisVendas();
                                vm.tabSelecionada = 0;
                            }, function(error) {
                                $log.error(error);
                            });
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                });
        };
        /*MÚLTIPLOS FUNIS DE VENDA --- FIM*/

    }

})();