/*
* @Author: Glauber Funez
* @Date:   2018-03-19 09:36:47
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:52
*/

(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelPesquisaAvancadaController', gerarRelPesquisaAvancadaController)


  gerarRelPesquisaAvancadaController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'PessoasService', 'FactoryRoles'];

  function gerarRelPesquisaAvancadaController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas, PessoasService, FactoryRoles) {
        
        var Base64 = {
          _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
          encode: function(e) {
            var t = '';
            var n, r, i, s, o, u, a;
            var f = 0;
            e = Base64._utf8_encode(e);
            while (f < e.length) {
              n = e.charCodeAt(f++);
              r = e.charCodeAt(f++);
              i = e.charCodeAt(f++);
              s = n >> 2;
              o = (n & 3) << 4 | r >> 4;
              u = (r & 15) << 2 | i >> 6;
              a = i & 63;
              if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
              t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
            }
            return t;
          },
          decode: function(e) {
            var t = '';
            var n, r, i;
            var s, o, u, a;
            var f = 0;
            e = e.replace(/[^A-Za-z0-9+/=]/g, '');
            while (f < e.length) {
              s = this._keyStr.indexOf(e.charAt(f++));
              o = this._keyStr.indexOf(e.charAt(f++));
              u = this._keyStr.indexOf(e.charAt(f++));
              a = this._keyStr.indexOf(e.charAt(f++));
              n = s << 2 | o >> 4;
              r = (o & 15) << 4 | u >> 2;
              i = (u & 3) << 6 | a;
              t = t + String.fromCharCode(n);
              if (u != 64) { t = t + String.fromCharCode(r); }
              if (a != 64) { t = t + String.fromCharCode(i); }
            }
            t = Base64._utf8_decode(t);
            return t;
          },
          _utf8_encode: function(e) {
            e = e.replace(/rn/g, 'n');
            var t = '';
            for (var n = 0; n < e.length; n++) {
              var r = e.charCodeAt(n);
              if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                t += String.fromCharCode(r >> 6 | 192);
                t += String.fromCharCode(r & 63 | 128);
              } else {
                t += String.fromCharCode(r >> 12 | 224);
                t += String.fromCharCode(r >> 6 & 63 | 128);
                t += String.fromCharCode(r & 63 | 128);
              }
            }
            return t;
          },
          _utf8_decode: function(e) {
            var t = '';
            var n = 0;
            var c1 = 0;
            var c2 = 0;
            var r = c1 = c2 = 0;
            while (n < e.length) {
              r = e.charCodeAt(n);
              if (r < 128) {
                t += String.fromCharCode(r);
                n++;
              } else if (r > 191 && r < 224) {
                c2 = e.charCodeAt(n + 1);
                t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                n += 2;
              } else {
                c2 = e.charCodeAt(n + 1);
                c3 = e.charCodeAt(n + 2);
                t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                n += 3;
              }
            }
            return t;
          }
        };

        function b64EncodeUnicode(str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    return String.fromCharCode('0x' + p1);
            }));
        }

        function b64DecodeUnicode(str) {
            return decodeURIComponent(atob(str).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }


        var vm = this;

        vm.url = API.url;
        vm.negSelect = 'todos';

        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.tituloPrincipal = 'Pesquisa avançada';
        vm.subtitulo = 'Pesquisa avançada';
        vm.carregando = true;

        vm.carregarDados = function() {
          var dados = {
            'filtros': vm.filtros
          };
          dados.user_hash = Cookies.get('crm_funil_hash');
          dados.mod_id = '1';
          relatoriosService.carregarDadosPesquisaAvancada(dados).then(function(response) {
            /*console.log("carregarDadosPesquisaAvancada");
            console.log(response.data);*/
            if (response.data != '' && response.data != 0) {
              vm.listagem = $filter('orderBy')(response.data, 'nome', false, '');
              vm.quantRegistros = vm.listagem.length;
              vm.limite = 20;
              if (vm.quantRegistros > vm.limite) {
                vm.quantExibindo = vm.limite;
              } else {
                vm.quantExibindo = vm.quantRegistros;
              }
            } else {
              vm.listagem = '';
            }
          }, function(error) {
            console.error(error);
          });
        };

        if ($routeParams.urlCodificada) {
          //vm.urlDecodificada = atob($routeParams.urlCodificada);
          // glauber funez
          vm.urlDecodificada = b64DecodeUnicode($routeParams.urlCodificada);
          var array = vm.urlDecodificada.split('/');
          vm.filtros = {};
          //console.log(array);
          angular.forEach(array, function(value, key) {
            if (key == 0) { // SAASID
              vm.filtros.saasid = value;
            }
            if (key == 1) { // TIPO DE LEAD
              vm.filtros.tipoLead = value;
            }
            if (key == 2) { // RESPONSÁVEL
              if (value != 'todos' && value != 'nenhum') {
                var dados = {
                  'saasid': vm.filtros.saasid,
                  'slug_usuarios': value
                };
                relatoriosService.buscaUsuariosFiltro(dados).then(function(response) {
                  vm.usuarios2 = (response.data);
                  vm.quantUsuarios = vm.usuarios2.length;
                }, function(error) {
                  console.log('Erro ', error);
                });
                vm.filtros.responsaveis = value.split(',');
              } else {
                vm.filtros.responsaveis = value;
              }
            }
            if (key == 3) { // CARGO
              if (value != 'todos' && value != 'nenhum' && value != 'não selecionado') {
                var dados = {
                  'saasid': vm.filtros.saasid,
                  'ids': value
                };
                relatoriosService.buscaCargosFiltro(dados).then(function(response) {
                  vm.cargos2 = (response.data);
                }, function(error) {
                  console.log('Erro ', error);
                });
                vm.filtros.cargos = value.split(',');
              } else {
                vm.filtros.cargos = value;
              }
            }
            if (key == 4) { // CATEGORIA
              vm.filtros.categorias = value;
              if (value != 'todos' && value != 'nenhum') {
                var dados = {
                  'saasid': vm.filtros.saasid,
                  'categoria': value
                };
                relatoriosService.buscaCategoriasFiltro(dados).then(function(response) {
                  vm.categorias2 = (response.data);
                }, function(error) {
                  console.log('Erro ', error);
                });
              }
            }
            if (key == 5) { // CIDADE
              if (value != 'todos' && value != 'nenhum' && value != 'não selecionado') {
                var dados = {
                  'saasid': vm.filtros.saasid,
                  'cidades': value
                };
                relatoriosService.buscaCidadesFiltro(dados).then(function(response) {
                  vm.cidades2 = (response.data);
                }, function(error) {
                  console.log('Erro ', error);
                });
                vm.filtros.cidades = value.split(',');
              } else {
                vm.filtros.cidades = value;
              }
            }
            if (key == 6) { // ESTADO
              if (value != 'todos' && value != 'nenhum' && value != 'não selecionado') {
                var dados = {
                  'saasid': vm.filtros.saasid,
                  'estados': value
                };
                relatoriosService.buscaEstadosFiltro(dados).then(function(response) {
                  vm.estados2 = (response.data);
                }, function(error) {
                  console.log('Erro ', error);
                });
                vm.filtros.estados = value.split(',');
              } else {
                vm.filtros.estados = value;
              }
            }
            if (key == 7) { // TAGS
              if (!value.match(/todos/) && !value.match(/nenhum/) && !value.match(/não selecionado/)) {
                var dados = {
                  'saasid': vm.filtros.saasid,
                  'tags': value
                };
                relatoriosService.buscaTagsFiltro(dados).then(function(response) {
                  vm.tags2 = (response.data);
                }, function(error) {
                  console.log('Erro ', error);
                });
                vm.filtros.tags = value.split(',');
              } else {
                if (value.match(/todos/)) {
                  vm.filtros.tags = 'todos';
                }
                if (value.match(/nenhum/)) {
                  vm.filtros.tags = 'nenhum';
                }
                if (value.match(/não selecionado/)) {
                  vm.filtros.tags = 'não selecionado';
                }
              }
            }
            if (key == 8) { // PAÍS
              if (value != 'todos' && value != 'nenhum' && value != 'não selecionado') {
                var dados = {
                  'saasid': vm.filtros.saasid,
                  'pais': value
                };
                relatoriosService.buscaPaisFiltro(dados).then(function(response) {
                  vm.pais2 = (response.data);
                }, function(error) {
                  console.log('Erro ', error);
                });
                vm.filtros.pais = value.split(',');
              } else {
                vm.filtros.pais = value;
              }
            }
            if (key == 9) { // neg aberta. fechada etc...
              vm.filtros.negSel = value;             
            }
          });
          vm.carregarDados();
        }

        vm.acabou = function() {
          $timeout(function() {
            vm.carregando = false;
          }, 300);
        };

        vm.carregar_tudo = true;
        vm.maisinf = function() {
          $('.linha_listagem').addClass('disable_a');
          vm.carregando_mais_inf = true;
          //vm.carregar_tudo = true;
          $timeout(function() {
            vm.limite = vm.limite + 20;
            vm.carregando_mais_inf = false;
            if (vm.quantRegistros < vm.limite) {
              vm.quantExibindo = vm.quantRegistros;
            } else {
              vm.quantExibindo = vm.limite;
            }
            $('.linha_listagem').removeClass('disable_a');
          }, 500);
        };

        vm.limiteResp = 2;

        vm.expandirAcc = function() {
          $('.box_filtrar_pesquisa').slideToggle();
        };









        /*FUNCÕES FILTRAR PESQUISA --- INÍCIO*/
        vm.url_img = API.url;
        vm.tipo = 'todos';

        vm.selectUsuarios = [];
        vm.selectedUsuarios = [];
        /*vm.buscaUsuarios = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
                    angular.forEach(response.data, function(value, key) {
                        if (value.user_avatar == null) {
                            var icon =
                                '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                '<div class="cell table">' +
                                '<div class="row">' +
                                '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                '</span>' +
                                '</div>' +
                                '</div>' +
                                '</div>';
                        } else {
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 0) {
                            var name = value.user_nome + ' (Inativo)';
                        } else {
                            name = value.user_nome;
                        }
                        vm.selectUsuarios.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                    });
                    vm.selectUsuarios.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum responsável', 'ticked': true, 'slug': 'nenhum' });
                    vm.saasidSelecionado = vm.saasid;

                    vm.quantUsuarios = vm.selectUsuarios.length;
                    vm.selecionadosInicio = [];
                    angular.forEach(vm.selectUsuarios, function(value, key) {
                        vm.selecionadosInicio.push(value.slug);
                        vm.usuarioSelecionado = vm.selecionadosInicio.join();
                    });
                    vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
                    //vm.saasidSelecionado = btoa(vm.saasid);
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaUsuarios();*/

        vm.buscaUsuarios = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid
            };
            vm.saasidSelecionado = vm.saasid;
            relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
              var array = [];

              var local = window.localStorage.getItem('5c479de2');
              var valLocal = JSON.parse(API.funilRC.decrypt(local));
              var localNeg = window.localStorage.getItem('isModPessoas');
              var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

              vm.roleVisualiza = parseInt(valLocalNeg.visualiza);

              /* GESTOR --- INICIO*/
              if (vm.roleVisualiza == 1) {
                var gestores = $filter('filter')(response.data.usuarios, { fk_perfil_id: '1' });
                if (gestores != '') {
                  array.push({ name: "Gestor", msGroup: true });
                  angular.forEach(gestores, function(value, key) {
                    if (value.user_avatar == null) {
                      var icon =
                        '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                        '<div class="cell table">' +
                        '<div class="row">' +
                        '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                        '</span>' +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    } else {
                      var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                    }
                    if (value.user_ativo == 0) {
                      var name = value.user_nome + ' (Inativo)';
                    } else {
                      name = value.user_nome;
                    }
                    if (value.user_hash == Cookies.get('crm_funil_hash')) {
                      var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                    }
                    array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                  });
                  array.push({ msGroup: false });
                }
              }
              /* GESTOR --- FIM*/

              vm.montarSelectUsuarios(array, response.data.usuarios, response.data.grupos);
            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaUsuarios();

        vm.montarSelectUsuarios = function(array, usuarios, grupos) {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var grupo_id = valLocal[0].fk_gru_id;
          var localNeg = window.localStorage.getItem('isModPessoas');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
          if (vm.roleVisualiza != 4) {
            /* GRUPOS --- INICIO*/
            if (vm.roleVisualiza == 2 || vm.roleVisualiza == 1) {
              if (grupos != '') {
                angular.forEach(grupos, function(value, key) {
                  if (vm.roleVisualiza == 2) {
                    if (grupo_id == value.gru_id) {
                      array.push({ name: value.gru_nome, msGroup: true });
                      if (value.usuarios.length != '') {
                        angular.forEach(value.usuarios, function(value, key) {
                          if (value.user_avatar == null) {
                            var icon =
                              '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                              '<div class="cell table">' +
                              '<div class="row">' +
                              '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                              '</span>' +
                              '</div>' +
                              '</div>' +
                              '</div>';
                          } else {
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                          }
                          if (value.user_ativo == 0) {
                            var name = value.user_nome + ' (Inativo)';
                          } else {
                            name = value.user_nome;
                          }
                          if (value.user_hash == Cookies.get('crm_funil_hash')) {
                            var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                          }
                          array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                        });
                      } else {
                        array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                      }
                      array.push({ msGroup: false });
                    }
                  } else {
                    array.push({ name: value.gru_nome, msGroup: true });
                    if (value.usuarios.length != '') {
                      angular.forEach(value.usuarios, function(value, key) {
                        if (value.user_avatar == null) {
                          var icon =
                            '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                            '<div class="cell table">' +
                            '<div class="row">' +
                            '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                            '</span>' +
                            '</div>' +
                            '</div>' +
                            '</div>';
                        } else {
                          var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 0) {
                          var name = value.user_nome + ' (Inativo)';
                        } else {
                          name = value.user_nome;
                        }
                        if (value.user_hash == Cookies.get('crm_funil_hash')) {
                          var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                        }
                        array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                      });
                    } else {
                      array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                    }
                    array.push({ msGroup: false });
                  }

                });
              }
            }
            /* GRUPOS --- FIM*/

            if (vm.roleVisualiza == 1) {
              var representantes = $filter('filter')(usuarios, { fk_perfil_id: '5' });
              if (representantes != '') {
                array.push({ name: 'Representante', msGroup: true });
                angular.forEach(representantes, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });

              }
              var gerentesGerais = $filter('filter')(usuarios, { fk_perfil_id: '3' });
              if (gerentesGerais != '') {
                array.push({ name: 'Gerente Geral', msGroup: true });
                angular.forEach(gerentesGerais, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });
              }
            }

            if (vm.roleVisualiza == 3) {
              angular.forEach(usuarios, function(value, key) {
                if (value.user_hash == Cookies.get('crm_funil_hash')) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                }
              });
            }

            vm.selectUsuarios = array;
            if (vm.roleVisualiza == 1) {
              vm.selectUsuarios.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum responsável', 'ticked': true, 'slug': 'nenhum' });
            }
            //vm.selectUsuarios.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum responsável', 'ticked': true, 'slug': 'nenhum' });

            vm.somente_users = $filter('filter')(vm.selectUsuarios, { slug: '' });
            //return null;
            vm.quantUsuarios = vm.somente_users.length;
            vm.selecionadosInicio = [];
            angular.forEach(vm.somente_users, function(value, key) {
              vm.selecionadosInicio.push(value.slug);
              vm.usuarioSelecionado = vm.selecionadosInicio.join();
            });
          }
        }
        //TRADUÇÃO DO INPUT SELECT DE USUÁRIOS
        vm.ptbr = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar usuários'
        };
        vm.ptbrC = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar cargos'
        };
        vm.ptbrCid = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar cidades'
        };
        vm.ptbrE = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar estados'
        };
        vm.ptbrPA = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar país'
        };
        vm.ptbrT = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar tags'
        };

        vm.fSelectAll = function(qual) {
          if (qual == 'usuarios') {
            vm.novoTodos = [];
            angular.forEach(vm.somente_users, function(value, key) {
              vm.novoTodos.push(value.slug);
              vm.usuarioSelecionado = vm.novoTodos.join();
            });
            vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
          } else if (qual == 'cargos') {
            vm.novoTodosC = [];
            angular.forEach(vm.cargosRepeat, function(value, key) {
              vm.novoTodosC.push(value.id);
              vm.cargoSelecionado = vm.novoTodosC.join();
            });
            vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
          } else if (qual == 'cidades') {
            vm.novoTodosCid = [];
            angular.forEach(vm.cidadesRepeat, function(value, key) {
              vm.novoTodosCid.push(value.name);
              vm.cidadeSelecionado = vm.novoTodosCid.join();
            });
            vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
          } else if (qual == 'pais') {
            // glauber
            vm.novoTodosPA = [];
            angular.forEach(vm.paisRepeat, function(value, key) {
              vm.novoTodosPA.push(value.name);
              vm.paisSelecionado = vm.novoTodosPA.join();
            });
            vm.TodosNenhum(vm.paisSelecionado, 'pais');
          } else if (qual == 'estados') {
            vm.novoTodosE = [];
            angular.forEach(vm.estadosRepeat, function(value, key) {
              vm.novoTodosE.push(value.name);
              vm.estadoSelecionado = vm.novoTodosE.join();
            });
            vm.TodosNenhum(vm.estadoSelecionado, 'estados');
          } else if (qual == 'tags') {
            vm.novoTodosT = [];
            angular.forEach(vm.tagsRepeat, function(value, key) {
              vm.novoTodosT.push(value.id);
              vm.tagSelecionado = vm.novoTodosT.join();
            });
            vm.TodosNenhum(vm.tagSelecionado, 'tags');
          }
        };

        vm.selectedCargos = [];
        vm.fClick = function(data, qual) {
          if (qual == 'usuarios') {
            vm.usuarioSelecionado = [];
            vm.novo = [];
            angular.forEach(vm.selectedUsuarios, function(value, key) {
              vm.novo.push(value.slug);
              vm.usuarioSelecionado = vm.novo.join();
            });
            vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
          } else if (qual == 'cargos') {
            vm.cargoSelecionado = [];
            vm.novoC = [];
            angular.forEach(vm.selectedCargos, function(value, key) {
              vm.novoC.push(value.id);
              vm.cargoSelecionado = vm.novoC.join();
            });
            vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
          } else if (qual == 'cidades') {
            vm.cidadeSelecionado = [];
            vm.novoCid = [];
            angular.forEach(vm.selectedCidades, function(value, key) {
              vm.novoCid.push(value.name);
              vm.cidadeSelecionado = vm.novoCid.join();
            });
            vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
          } else if (qual == 'estados') {
            vm.estadoSelecionado = [];
            vm.novoE = [];
            angular.forEach(vm.selectedEstados, function(value, key) {
              vm.novoE.push(value.name);
              vm.estadoSelecionado = vm.novoE.join();
            });
            vm.TodosNenhum(vm.estadoSelecionado, 'estados');
          } else if (qual == 'pais') {
            vm.paisSelecionado = [];
            vm.novoE = [];
            angular.forEach(vm.paisSelect, function(value, key) {
              vm.novoE.push(value.name);
              vm.paisSelecionado = vm.novoE.join();
            });
            vm.TodosNenhum(vm.paisSelecionado, 'pais');
          } else if (qual == 'tags') {
            vm.tagSelecionado = [];
            vm.novoT = [];
            angular.forEach(vm.selectedTags, function(value, key) {
              vm.novoT.push(value.id);
              vm.tagSelecionado = vm.novoT.join();
            });
            vm.TodosNenhum(vm.tagSelecionado, 'tags');
          }
        };

        vm.fSelectNone = function(qual) {
          if (qual == 'usuarios') {
            vm.novoTodos = [];
            angular.forEach(vm.selectUsuarios, function(value, key) {
              vm.novoTodos.splice(key);
              vm.usuarioSelecionado = vm.novoTodos.join();
            });
            vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
          } else if (qual == 'cargos') {
            vm.novoTodosC = [];
            angular.forEach(vm.cargosRepeat, function(value, key) {
              vm.novoTodosC.splice(key);
              vm.cargoSelecionado = vm.novoTodosC.join();
            });
            vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
          } else if (qual == 'cidades') {
            vm.novoTodosCid = [];
            angular.forEach(vm.cidadesRepeat, function(value, key) {
              vm.novoTodosCid.splice(key);
              vm.cidadeSelecionado = vm.novoTodosCid.join();
            });
            vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
          } else if (qual == 'estados') {
            vm.novoTodosE = [];
            angular.forEach(vm.estadosRepeat, function(value, key) {
              vm.novoTodosE.splice(key);
              vm.estadoSelecionado = vm.novoTodosE.join();
            });
            vm.TodosNenhum(vm.estadoSelecionado, 'estados');
          } else if (qual == 'pais') {
            vm.novoTodosPA = [];
            angular.forEach(vm.paisRepeat, function(value, key) {
              vm.novoTodosPA.splice(key);
              vm.paisSelecionado = vm.novoTodosPA.join();
            });
            vm.TodosNenhum(vm.estadoSelecionado, 'pais');
          } else if (qual == 'tags') {
            vm.novoTodosT = [];
            angular.forEach(vm.tagsRepeat, function(value, key) {
              vm.novoTodosT.splice(key);
              vm.tagSelecionado = vm.novoTodosT.join();
            });
            vm.TodosNenhum(vm.tagSelecionado, 'tags');
          }
        };

        vm.selecionarNeg = function(dados) {
          vm.negSel = dados;
          vm.negSelSelecionado = dados;
          vm.TodosNenhum();
        };

        vm.TodosNenhum = function(array, qual) {
          if (array) {
            if (array != '') {
              var array = array.split(',');
            } else {
              var array = '';
            }
          }
          if (qual == 'usuarios') {
            if (array.length == vm.quantUsuarios) {
              vm.usuarioSelecionado = 'todos';
            } else if (array.length == 0) {
              vm.usuarioSelecionado = 'não selecionado';
            } else if (array == 'nenhum') {
              vm.usuarioSelecionado = 'nenhum';
            }
          } else if (qual == 'cargos') {
            if (array.length == vm.quantCargos) {
              vm.cargoSelecionado = 'todos';
            } else if (array.length == 0) {
              vm.cargoSelecionado = 'não selecionado';
            } else if (array == 'nenhum') {
              vm.cargoSelecionado = 'nenhum';
            }
          } else if (qual == 'cidades') {
            if (array.length == vm.quantCidades) {
              vm.cidadeSelecionado = 'todos';
            } else if (array.length == 0) {
              vm.cidadeSelecionado = 'não selecionado';
            } else if (array == 'Nenhuma cidade') {
              vm.cidadeSelecionado = 'nenhum';
            }
          } else if (qual == 'estados') {
            if (array.length == vm.quantEstados) {
              vm.estadoSelecionado = 'todos';
            } else if (array.length == 0) {
              vm.estadoSelecionado = 'não selecionado';
            } else if (array == 'Nenhum estado') {
              vm.estadoSelecionado = 'nenhum';
            }
          } else if (qual == 'pais') {
            // glauber
            if (array.length == vm.quantPais) {
              vm.paisSelecionado = 'todos';
            } else if (array.length == 0) {
              vm.paisSelecionado = 'não selecionado';
            } else if (array == 'Nenhum estado') {
              vm.paisSelecionado = 'nenhum';
            }
          } else if (qual == 'tags') {
            if (array.length == vm.quantTags) {
              vm.tagSelecionado = 'todos';
            } else if (array.length == 0) {
              vm.tagSelecionado = 'não selecionado';
            } else if (array == 'nenhum') {
              vm.tagSelecionado = 'nenhum';
            }
          } else if (qual == 'tipo') {
            if (vm.tipoSelecionado == 'empresas') {
              if (vm.cargosRepeat) {
                angular.forEach(vm.cargosRepeat, function(value, key) {
                  vm.cargosRepeat[key].ticked = false;
                });
                vm.fSelectNone('cargos');
              } else {
                vm.listarCargos()
              }
              vm.desabilitarCargo = true;
            } else {
              if (vm.cargosRepeat != '') {
                vm.desabilitarCargo = false;
              }
            }
          }

          if (vm.negSelSelecionado == undefined || vm.negSelSelecionado == 'todos' || vm.negSelSelecionado == null) {
            vm.negSelSelecionado = 'todos';
          }


          vm.urlEnviar = vm.saasidSelecionado + '/' + vm.tipoSelecionado + '/' + vm.usuarioSelecionado + '/' + vm.cargoSelecionado + '/' + vm.categoriaSelecionado + '/' + vm.cidadeSelecionado + '/' + vm.estadoSelecionado + '/' + vm.tagSelecionado + '/' + vm.paisSelecionado + '/' + vm.negSelSelecionado;
          //vm.urlEnviar = btoa(vm.urlEnviar);
          vm.urlEnviar = Base64.encode(vm.urlEnviar);
        };

        /*PESQUISA AVANÇADA --- INÍCIO*/
        vm.listarCargos = function() {
          vm.cargosRepeat = [];
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) {
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid
            };
            PessoasService.listarCargos(dados).then(function(response) {
              if (FactoryRoles.checkRolesVisuFUNIL('isModConfig')) {
                if (response.data != '0') {
                  angular.forEach(response.data, function(value, key) {
                    vm.cargosRepeat.push({ 'name': value.car_nome, 'ticked': false, 'id': value.car_id });
                  });
                  vm.cargosRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum cargo', 'ticked': false, 'id': 'nenhum' });
                } else {
                  vm.cargosRepeat = [];
                }
                vm.quantCargos = vm.cargosRepeat.length;
                vm.selecionadosInicioC = [];
                vm.cargoSelecionado = '';
                /*angular.forEach(vm.cargosRepeat, function(value, key) {
                    vm.selecionadosInicioC.push(value.id);
                    vm.cargoSelecionado = vm.selecionadosInicioC.join();
                })*/
                vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
              } else {
                vm.cargosRepeat = [];
                vm.quantCargos = vm.cargosRepeat.length;
                vm.selecionadosInicioC = [];
                vm.cargoSelecionado = '';
                vm.TodosNenhum('nenhum', 'cargos');
              }

            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.listarCargos();


        vm.buscaCategorias = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            if (FactoryRoles.checkRolesVisuFUNIL('isModConfig')) {
              PessoasService.findCategorias(vm.saasid).then(function(response) {
                vm.categorias = response.data;
              }, function(error) {
                console.error(error);
              });
              vm.selecionarCateg('todos');
            } else {
              vm.selecionarCateg('nenhum');
            }
          });
        };
        vm.buscaCategorias();

        vm.selecionarCateg = function(dados) {
          vm.categoria = dados;
          vm.categoriaSelecionado = dados;
          vm.TodosNenhum();
        };

        vm.selecionarNeg = function(dados) {
          /*if (dados == 'todos') {
              vm.categoria = "todos";
          } else {
              vm.categoria = dados;
          }*/
          //vm.categoriaSelecionado = btoa(vm.categoria);
          vm.negSel = dados;
          vm.negSelSelecionado = dados;
          vm.TodosNenhum();
        };

        vm.desabilitarCargo = false;
        vm.selecionarTipo = function(dados) {
          //vm.tipoSelecionado = btoa(dados);
          vm.tipoSelecionado = dados;
          vm.TodosNenhum();
        };
        vm.selecionarTipo('todos');

        vm.buscaCidades = function() {
          vm.cidadesRepeat = [];
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            PessoasService.findCidadesTodos(vm.saasid).then(function(response) {
              if (response.data != '0') {
                angular.forEach(response.data, function(value, key) {
                  vm.cidadesRepeat.push({ 'name': value.endcli_cidade, 'ticked': true, 'id': '' });
                });
                vm.cidadesRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhuma cidade', 'ticked': true, 'id': 'nenhum' });
              } else {
                vm.cidadesRepeat = [];
              }
              vm.quantCidades = vm.cidadesRepeat.length;
              vm.selecionadosInicioCid = [];
              angular.forEach(vm.cidadesRepeat, function(value, key) {
                vm.selecionadosInicioCid.push(value.id);
                vm.cidadeSelecionado = vm.selecionadosInicioCid.join();
              });
              vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaCidades();

        vm.buscaEstados = function() {
          vm.estadosRepeat = [];
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            PessoasService.findEstadosTodos(vm.saasid).then(function(response) {
              if (response.data != '0') {
                angular.forEach(response.data, function(value, key) {
                  vm.estadosRepeat.push({ 'name': value.endcli_estado, 'ticked': true, 'id': '' });
                });
                vm.estadosRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum estado', 'ticked': true, 'id': '' });
              } else {
                vm.estadosRepeat = [];
              }
              vm.quantEstados = vm.estadosRepeat.length;
              vm.selecionadosInicioE = [];
              angular.forEach(vm.estadosRepeat, function(value, key) {
                vm.selecionadosInicioE.push(value.id);
                vm.estadoSelecionado = vm.selecionadosInicioE.join();
              });
              vm.TodosNenhum(vm.estadoSelecionado, 'estados');
            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaEstados();

        vm.buscaPais = function() {
          vm.paisRepeat = [];
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            PessoasService.findPaisTodos(vm.saasid).then(function(response) {
              if (response.data != '0') {
                angular.forEach(response.data, function(value, key) {
                  vm.paisRepeat.push({ 'name': value.endcli_pais, 'ticked': true, 'id': '' });
                });
                vm.paisRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum pais', 'ticked': true, 'id': '' });
              } else {
                vm.paisRepeat = [];
              }
              vm.quantPais = vm.paisRepeat.length;
              vm.selecionadosInicioPais = [];
              angular.forEach(vm.paisRepeat, function(value, key) {
                vm.selecionadosInicioPais.push(value.id);
                vm.paisSelecionado = vm.selecionadosInicioPais.join();
              });
              vm.TodosNenhum(vm.paisSelecionado, 'pais');
            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaPais();

        vm.buscaTags = function() {
          vm.tagsRepeat = [];
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            PessoasService.findTagsRel(vm.saasid).then(function(response) {
              if (FactoryRoles.checkRolesVisuFUNIL('isModConfig')) {
                if (response.data != '0') {
                  angular.forEach(response.data, function(value, key) {
                    vm.tagsRepeat.push({ 'name': value.tag_nome, 'ticked': true, 'id': value.tag_id });
                  });
                  vm.tagsRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhuma tag', 'ticked': true, 'id': 'nenhum' });
                } else {
                  vm.tagsRepeat = [];
                }
                vm.quantTags = vm.tagsRepeat.length;
                vm.selecionadosInicioT = [];
                angular.forEach(vm.tagsRepeat, function(value, key) {
                  vm.selecionadosInicioT.push(value.id);
                  vm.tagSelecionado = vm.selecionadosInicioT.join();
                });
                vm.TodosNenhum(vm.tagSelecionado, 'tags');
              } else {
                vm.tagsRepeat = [];
                vm.quantTags = vm.tagsRepeat.length;
                vm.selecionadosInicioT = [];
                angular.forEach(vm.tagsRepeat, function(value, key) {
                  vm.selecionadosInicioT.push(value.id);
                  vm.tagSelecionado = vm.selecionadosInicioT.join();
                });
                vm.TodosNenhum('nenhum', 'tags');
              }

            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaTags();
        /*FUNCÕES FILTRAR PESQUISA --- FIM*/

        vm.verificarNavegador = function() {
          // Opera 8.0+
          vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
          // Firefox 1.0+
          vm.isFirefox = typeof InstallTrigger !== 'undefined';
          // At least Safari 3+: "[object HTMLElementConstructor]"
          vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
          // Internet Explorer 6-11
          vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
          // Edge 20+
          vm.isEdge = !vm.isIE && !!window.StyleMedia;
          // Chrome 1+
          vm.isChrome = !!window.chrome && !!window.chrome.webstore;
          // Blink engine detection
          vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();

        vm.imprimir = function() {
          window.print();
        };
  }

  })();
