/*
* @Author: Glauber Funez
* @Date:   2018-03-19 09:28:20
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:53
*/

(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelQualificacaoController', gerarRelQualificacaoController)
  

  gerarRelQualificacaoController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'FactoryRoles'];

  function gerarRelQualificacaoController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas, FactoryRoles) {

        var vm = this;

        vm.url = API.url;
        vm.negSelect = 'todos';

        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        var now = new Date;
        vm.anoatual = now.getFullYear();

        vm.options = { width: 100, height: 200, 'bar': 'bbb' };
        vm.data = [0, 2, 3];
        vm.hovered = function(d) {
          vm.barValue = d;
          //$scope.$apply();
        };
        vm.barValue = 'None';

        vm.userHash = Cookies.get('crm_funil_hash');

        vm.adm = false;
        vm.verificarAdm = function() {
          categoriasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
            if (response.data == 'Administrador') {
              vm.adm = true;
            } else {
              vm.adm = false;
            }
          }, function(error) {
            console.log('Erro: ', error);
          });
        };
        vm.verificarAdm();

        vm.mostrarDados = false;

        if ($routeParams.relatorio) {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            if ($routeParams.relatorio == 'qualificacao_leads') {
              vm.tituloPrincipal = 'QUALIFICAÇÃO DE LEADS';
              vm.tipo = $routeParams.tipo;
              vm.qualificacao = $routeParams.qualificacao;
              vm.relatorio = $routeParams.relatorio;
              if ($routeParams.inicio) { // PERIODO SELECIONADO
                vm.inicio = $routeParams.inicio;
                vm.fim = $routeParams.fim;
                vm.inicioExibir = vm.inicio.replace('_', '/').replace('_', '/');
                vm.fimExibir = vm.fim.replace('_', '/').replace('_', '/');
                var dados = {
                  'relatorio': vm.relatorio,
                  'saasid': vm.saasid,
                  'tipo': vm.tipo,
                  'qualificacao': vm.qualificacao,
                  'inicio': vm.inicio,
                  'fim': vm.fim
                };
              } else { // PERIODO NÃO SELECIONADO
                var dados = {
                  'relatorio': vm.relatorio,
                  'saasid': vm.saasid,
                  'tipo': vm.tipo,
                  'qualificacao': vm.qualificacao
                };
              }
              dados.user_hash = Cookies.get('crm_funil_hash');
              dados.mod_idPE = 1;
              dados.mod_idNeg = 2;
              dados.mod_idComp = 4;
              relatoriosService.carregarDados(dados).then(function(response) {
                if (response.data != '') {
                  vm.mostrarDados = true;
                  vm.quantPessoas = parseInt(response.data.quant_cli);
                  vm.quantEmpresas = parseInt(response.data.quant_emp);
                  if (vm.tipo == 'todos') {
                    vm.totalLeads = parseInt(response.data.total);
                    vm.quantLeadsNegocios = parseInt(response.data.todos_neg);
                    vm.quantLeadsComp = parseInt(response.data.todos_comp);
                  } else if (vm.tipo == 'pessoas') {
                    vm.totalLeads = parseInt(response.data.quant_cli);
                    vm.quantLeadsNegocios = parseInt(response.data.cli_neg);
                    vm.quantLeadsComp = parseInt(response.data.cli_comp);
                  } else {
                    vm.totalLeads = parseInt(response.data.quant_emp);
                    vm.quantLeadsNegocios = parseInt(response.data.emp_neg);
                    vm.quantLeadsComp = parseInt(response.data.emp_comp);
                  }
                  if (vm.quantLeadsComp != 0) {
                    vm.porcentComp = Math.round((vm.quantLeadsComp * 100) / vm.totalLeads);
                  } else {
                    vm.porcentComp = 0;
                  }
                  if (vm.quantLeadsNegocios != 0) {
                    vm.porcentNeg = Math.round((vm.quantLeadsNegocios * 100) / vm.totalLeads);
                  } else {
                    vm.porcentNeg = 0;
                  }
                  vm.detalhesNegocios();
                  vm.detalhesCompromissos();
                  vm.carregarLeadsVazios();
                  $('.grafico_compromisso').circliful({
                    animation: 1,
                    animationStep: 3,
                    foregroundBorderWidth: 10,
                    backgroundBorderWidth: 3,
                    percent: vm.porcentComp,
                    textSize: 40,
                    percentageTextSize: 35,
                    textStyle: 'font-size: 12px;',
                    textColor: '#666',
                    foregroundColor: '#a754c8',

                    /*noPercentageSign: true,
                    iconColor: '#aaa',
                    icon: 'f295',
                    iconSize: '15',
                    iconPosition: 'right'*/

                  });
                  $('.grafico_negocio').circliful({
                    animation: 1,
                    animationStep: 3,
                    foregroundBorderWidth: 10,
                    backgroundBorderWidth: 3,
                    percent: vm.porcentNeg,
                    textSize: 28,
                    percentageTextSize: 35,
                    textStyle: 'font-size: 12px;',
                    textColor: '#666',
                    foregroundColor: '#5da3b1'
                  });
                } else {
                  vm.mostrarDados = false;
                }
              }, function(error) {
                console.error(error);
              });
            }
          });
        }

        vm.mostrarDadosNeg = false;
        vm.detalhesNegocios = function() {
          if ($routeParams.inicio) { // PERIODO SELECIONADO
            var dados = {
              'saasid': vm.saasid,
              'inicio': vm.inicio,
              'fim': vm.fim
            };
          } else { // PERIODO NÃO SELECIONADO
            var dados = {
              'saasid': vm.saasid
            };
          }
          dados.user_hash = Cookies.get('crm_funil_hash');
          dados.mod_id = 2;
          relatoriosService.detalhesNegocios(dados).then(function(response) {
            if (response.data != '') {
              vm.mostrarDadosNeg = true;
              if (vm.tipo == 'todos') {
                vm.qtdGanhos = parseInt(response.data.todos_ganho);
                vm.qtdPerdidos = parseInt(response.data.todos_perdido);
                vm.qtdAbertos = parseInt(response.data.todos_aberto);
                vm.vlGanhos = parseFloat(response.data.valor_ganho);
                vm.vlPerdidos = parseFloat(response.data.valor_perdido);
                vm.vlAbertos = parseFloat(response.data.valor_aberto);
              } else if (vm.tipo == 'pessoas') {
                vm.qtdGanhos = parseInt(response.data.cli_ganho);
                vm.qtdPerdidos = parseInt(response.data.cli_perdido);
                vm.qtdAbertos = parseInt(response.data.cli_aberto);
                vm.vlGanhos = parseFloat(response.data.cli_vl_ganho);
                vm.vlPerdidos = parseFloat(response.data.cli_vl_perdido);
                vm.vlAbertos = parseFloat(response.data.cli_vl_aberto);
              } else {
                vm.qtdGanhos = parseInt(response.data.emp_ganho);
                vm.qtdPerdidos = parseInt(response.data.emp_perdido);
                vm.qtdAbertos = parseInt(response.data.emp_aberto);
                vm.vlGanhos = parseFloat(response.data.emp_vl_ganho);
                vm.vlPerdidos = parseFloat(response.data.emp_vl_perdido);
                vm.vlAbertos = parseFloat(response.data.emp_vl_aberto);
              }
              vm.vlGanhosCli = parseFloat(response.data.cli_vl_ganho);
              vm.vlPerdidosCli = parseFloat(response.data.cli_vl_perdido);
              vm.vlAbertosCli = parseFloat(response.data.cli_vl_aberto);
              vm.vlGanhosEmp = parseFloat(response.data.emp_vl_ganho);
              vm.vlPerdidosEmp = parseFloat(response.data.emp_vl_perdido);
              vm.vlAbertosEmp = parseFloat(response.data.emp_vl_aberto);

              vm.totalQtdNeg = vm.qtdGanhos + vm.qtdPerdidos + vm.qtdAbertos;
              vm.totalVlNeg = parseFloat((vm.vlGanhos + vm.vlPerdidos + vm.vlAbertos).toFixed(2));

              vm.porcentGanhos = Math.round((vm.qtdGanhos * 100) / vm.totalQtdNeg);
              vm.porcentPerdidos = Math.round((vm.qtdPerdidos * 100) / vm.totalQtdNeg);
              vm.porcentAbertos = Math.round((vm.qtdAbertos * 100) / vm.totalQtdNeg);

              $('.linha_ganhos').animate({ width: vm.porcentGanhos + '%' }, 600);
              $('.circle_ganhos').animate({ left: (vm.porcentGanhos - 1) + '%' }, 600);
              $('.linha_perdidos').animate({ width: vm.porcentPerdidos + '%' }, 600);
              $('.circle_perdidos').animate({ left: (vm.porcentPerdidos - 1) + '%' }, 600);
              $('.linha_abertos').animate({ width: vm.porcentAbertos + '%' }, 600);
              $('.circle_abertos').animate({ left: (vm.porcentAbertos - 1) + '%' }, 600);
              vm.carregarDadosListagemNegocios();
            } else {
              vm.mostrarDadosNeg = false;
            }
          }, function(error) {
            console.error(error);
          });
        };

        vm.mostrarDadosComp = false;
        vm.detalhesCompromissos = function() {
          if ($routeParams.inicio) { // PERIODO SELECIONADO
            var dados = {
              'saasid': vm.saasid,
              'inicio': vm.inicio,
              'fim': vm.fim
            };
          } else { // PERIODO NÃO SELECIONADO
            var dados = {
              'saasid': vm.saasid
            };
          }
          dados.user_hash = Cookies.get('crm_funil_hash');
          dados.mod_id = 4;
          relatoriosService.detalhesCompromissos(dados).then(function(response) {
            if (response.data != '') {
              vm.mostrarDadosComp = true;
              if (vm.tipo == 'todos') {
                vm.qtdComp = parseInt(response.data.total_comp);
                vm.qtdCompFinalizado = parseInt(response.data.total_finalizado);
                vm.qtdCompAberto = parseInt(response.data.total_aberto);
                vm.qtdCompAtrasado = parseInt(response.data.total_atrasado);
                vm.qtdCompCliFinalizado = parseInt(response.data.comp_cli_finalizado);
                vm.qtdCompCliAberto = parseInt(response.data.comp_cli_aberto);
                vm.qtdCompCliAtrasado = parseInt(response.data.comp_cli_atrasado);
                vm.qtdCompEmpFinalizado = parseInt(response.data.comp_emp_finalizado);
                vm.qtdCompEmpAberto = parseInt(response.data.comp_emp_aberto);
                vm.qtdCompEmpAtrasado = parseInt(response.data.comp_emp_atrasado);
              } else if (vm.tipo == 'pessoas') {
                vm.qtdComp = parseInt(response.data.comp_cli_total);
                vm.qtdCompFinalizado = parseInt(response.data.comp_cli_finalizado);
                vm.qtdCompAberto = parseInt(response.data.comp_cli_aberto);
                vm.qtdCompAtrasado = parseInt(response.data.comp_cli_atrasado);
                vm.qtdCompCliFinalizado = parseInt(response.data.comp_cli_finalizado);
                vm.qtdCompCliAberto = parseInt(response.data.comp_cli_aberto);
                vm.qtdCompCliAtrasado = parseInt(response.data.comp_cli_atrasado);
              } else {
                vm.qtdComp = parseInt(response.data.comp_emp_total);
                vm.qtdCompFinalizado = parseInt(response.data.comp_emp_finalizado);
                vm.qtdCompAberto = parseInt(response.data.comp_emp_aberto);
                vm.qtdCompAtrasado = parseInt(response.data.comp_emp_atrasado);
                vm.qtdCompEmpFinalizado = parseInt(response.data.comp_emp_finalizado);
                vm.qtdCompEmpAberto = parseInt(response.data.comp_emp_aberto);
                vm.qtdCompEmpAtrasado = parseInt(response.data.comp_emp_atrasado);
              }
              vm.labels = response.data.labels;

              vm.renderizarGraficoCompromisso(vm.labels, vm.qtdCompFinalizado, vm.qtdCompAberto, vm.qtdCompAtrasado);
              vm.carregarDadosListagemCompromissos();
            } else {
              vm.mostrarDadosComp = false;
            }
          }, function(error) {
            console.error(error);
          });
        };

        vm.renderizarGraficoCompromisso = function(labels, finalizado, aberto, atrasado) {
          vm.corFinalizado = 'rgba(0,255,0,0.6)';
          var bordaFinalizado = 'rgba(0,255,0,1)';
          vm.corAberto = 'rgba(255,192,0,0.6)';
          var bordaAberto = 'rgba(255,192,0,1)';
          vm.corAtrasado = 'rgba(255,0,0,0.6)';
          var bordaAtrasado = 'rgba(255,0,0,1)';
          var dados = {
            labels: labels,
            datasets: [{
              label: '',
              backgroundColor: [vm.corFinalizado, vm.corAberto, vm.corAtrasado],
              borderColor: [bordaFinalizado, bordaAberto, bordaAtrasado],
              /*hoverBackgroundColor: "rgba(255,99,132,0.5)",
              hoverBorderColor: "rgba(255,99,132,1)",*/
              data: [finalizado, aberto, atrasado],
              borderWidth: 2
            }]
          };

          var config = {
            type: 'horizontalBar',
            data: dados,
            responsive: true,
            options: {
              legend: {
                display: false
              },
              scales: {
                xAxes: [{
                  ticks: { beginAtZero: true }
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                  }
                }]
              },
              tooltips: {
                mode: 'label',
                callbacks: {
                  label: function(tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].label + Number(tooltipItem.xLabel);
                  }
                }
              },
            }
          };
          if (vm.qualificacao == 'compromissos' || vm.qualificacao == 'todos') {
            var ctx = document.getElementById('canvas_grafico_compromissos').getContext('2d');
            new Chart(ctx, config);
            //vm.gerarGraficoImpressao(finalizado, aberto, atrasado);
          }
        };

        vm.expandirAccPrincipais = function(qual) {
          $('.detalhes_' + qual).slideToggle('slow');
          $('.icon_acc_header_' + qual).toggleClass('girar');
        };

        vm.expandirAcc = function(qual) {
          if (qual == 'pessoas') {
            $('.listagem_' + qual).slideToggle('slow');
            $('.icon_acc_' + qual).toggleClass('girar');
          } else if (qual == 'empresas') {
            $('.listagem_' + qual).slideToggle('slow');
            $('.icon_acc_' + qual).toggleClass('girar');
          }
        };

        vm.expandirAccComp = function(qual) {
          if (qual == 'pessoas') {
            $('.listagem_' + qual + '_comp').slideToggle('slow');
            $('.icon_acc_' + qual + '_comp').toggleClass('girar');
          } else if (qual == 'empresas') {
            $('.listagem_' + qual + '_comp').slideToggle('slow');
            $('.icon_acc_' + qual + '_comp').toggleClass('girar');
          }
        };

        vm.carregarDadosListagemNegocios = function() {
          if ($routeParams.inicio) { // PERIODO SELECIONADO
            var dados = {
              'saasid': vm.saasid,
              'inicio': vm.inicio,
              'fim': vm.fim
            };
          } else { // PERIODO NÃO SELECIONADO
            var dados = {
              'saasid': vm.saasid
            };
          }
          dados.user_hash = Cookies.get('crm_funil_hash');
          dados.mod_id = 2;
          relatoriosService.carregarDadosListagemNegocios(dados).then(function(response) {
            if (response.data.pessoas) {
              vm.listagemPessoas = response.data.pessoas;
            } else {
              vm.listagemPessoas = '';
            }
            if (response.data.empresas) {
              vm.listagemEmpresas = response.data.empresas;
            } else {
              vm.listagemEmpresas = '';
            }
          }, function(error) {
            console.error(error);
          });
        };

        vm.carregarDadosListagemCompromissos = function() {
          if ($routeParams.inicio) { // PERIODO SELECIONADO
            var dados = {
              'saasid': vm.saasid,
              'inicio': vm.inicio,
              'fim': vm.fim
            };
          } else { // PERIODO NÃO SELECIONADO
            var dados = {
              'saasid': vm.saasid
            };
          }
          dados.user_hash = Cookies.get('crm_funil_hash');
          dados.mod_id = 4;
          relatoriosService.carregarDadosListagemCompromissos(dados).then(function(response) {
            if (response.data.pessoas) {
              vm.listagemPessoasComp = response.data.pessoas;
            } else {
              vm.listagemPessoasComp = '';
            }
            if (response.data.empresas) {
              vm.listagemEmpresasComp = response.data.empresas;
            } else {
              vm.listagemEmpresasComp = '';
            }
          }, function(error) {
            console.error(error);
          });
        };



        vm.carregarLeadsVazios = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            if ($routeParams.inicio) { // PERIODO SELECIONADO
              var dados = {
                'saasid': vm.saasid,
                'inicio': vm.inicio,
                'fim': vm.fim
              };
            } else { // PERIODO NÃO SELECIONADO
              var dados = {
                'saasid': vm.saasid
              };
            }
            dados.user_hash = Cookies.get('crm_funil_hash');
            dados.mod_idPE = 1;
            dados.mod_idNeg = 2;
            dados.mod_idComp = 4;
            relatoriosService.carregarLeadsVazios(dados).then(function(response) {
              vm.quantSemPessoas = parseInt(response.data.total_sem_cli);
              vm.quantSemEmpresas = parseInt(response.data.total_sem_emp);
              if (vm.tipo == 'todos') {
                vm.quantSem = parseInt(response.data.total_sem);
              } else if (vm.tipo == 'pessoas') {
                vm.quantSem = parseInt(response.data.total_sem_cli);
              } else {
                vm.quantSem = parseInt(response.data.total_sem_emp);
              }

              if (vm.quantSem != 0) {
                vm.porcentSem = Math.round((vm.quantSem * 100) / vm.totalLeads);
              } else {
                vm.porcentSem = 0;
              }

              $('.linha_vazios').animate({ width: vm.porcentSem + '%' }, 600);
              $('.circle_vazios').animate({ left: (vm.porcentSem - 1) + '%' }, 600);
              vm.carregarDadosListagemVazios();
            }, function(error) {
              console.error(error);
            });
          });
        };


        vm.carregarDadosListagemVazios = function() {
          if ($routeParams.inicio) { // PERIODO SELECIONADO
            var dados = {
              'saasid': vm.saasid,
              'inicio': vm.inicio,
              'fim': vm.fim,
              'tipo': vm.tipo
            };
          } else { // PERIODO NÃO SELECIONADO
            var dados = {
              'saasid': vm.saasid,
              'tipo': vm.tipo
            };
          }
          dados.user_hash = Cookies.get('crm_funil_hash');
          dados.mod_idPE = 1;
          dados.mod_idNeg = 2;
          dados.mod_idComp = 4;
          relatoriosService.carregarDadosListagemVazios(dados).then(function(response) {
            if (response.data) {
              vm.listagemVazios = response.data;
            } else {
              vm.listagemVazios = '';
            }
          }, function(error) {
            console.error(error);
          });
        };
        vm.acabouVazios = function() {
          $timeout(function() {
            $('.rolagem').niceScroll();
          }, 10);
        };
        /*
                vm.gerarGraficoImpressao = function(finalizado, aberto, atrasado) {
                    vm.maximo = 0;
                    if (finalizado > vm.maximo) {
                        vm.maximo = finalizado;
                    } else if (aberto > vm.maximo) {
                        vm.maximo = aberto;
                    } else {
                        vm.maximo = atrasado;
                    }
                    vm.maximo += 2;
                    if (vm.maximo < 20) {
                        vm.maximo = 21;
                    }


                    vm.multi = 2;
                    if (vm.maximo > 50) {
                        vm.multi = 3;
                    }


                    var categories = ['', 'Finalizados', 'Abertos', 'Atrasados'];
                    var valores = [finalizado, aberto, atrasado];
                    var colors = [vm.corFinalizado, vm.corAberto, vm.corAtrasado, ''];

                    var grid = d3.range(vm.maximo).map(function(i) { // número máximo da contagem no eixo x
                        return {
                            'x1': 0,
                            'y1': 0,
                            'x2': 0,
                            'y2': 105 // 105 = altura das linhas da grade
                        };
                    });

                    var tickVals = grid.map(function(d, i) {
                        if (i > 0) {
                            return i * vm.multi; // multiplicador da escala no eixo x
                        } else if (i === 0) {
                            return "100";
                        }
                    });

                    var xscale = d3.scale.linear()
                        .domain([1, vm.maximo]) // número de linhas na grade
                        .range([0, 298]);

                    var yscale = d3.scale.linear()
                        .domain([0, categories.length]) // espaçamento entre as linhas horizontais do gráfico
                        .range([0, 110]);

                    var colorScale = d3.scale.quantize()
                        .domain([0, categories.length]) // quantia de cores o gráfico
                        .range(colors);

                    var canvas = d3.select('#wrapper')
                        .append('svg')
                        .attr({
                            'width': 400, // largura do gráfico
                            'height': 130 // altura do gráfico
                        });

                    var grids = canvas.append('g')
                        .attr('id', 'grid')
                        .attr('transform', 'translate(75,10)') // grade = margem esquerda, margem inferior
                        .selectAll('line')
                        .data(grid)
                        .enter()
                        .append('line')
                        .attr({
                            'x1': function(d, i) {
                                return i * 15; // espaçamento entre as linhas da grade
                            },
                            'y1': function(d) {
                                return d.y1;
                            },
                            'x2': function(d, i) {
                                return i * 15; // espaçamento entre as linhas da grade
                            },
                            'y2': function(d) {
                                return d.y2;
                            },
                        })
                        .style({
                            'stroke': '#eee', // cor da grade
                            'stroke-width': '1px' // espessura da grade
                        });

                    var xAxis = d3.svg.axis();
                    xAxis
                        .orient('bottom') // posição dos dados no eixo x
                        .scale(xscale)
                        .tickValues(tickVals);

                    var yAxis = d3.svg.axis();
                    yAxis
                        .orient('left') // linha do eixo y
                        .scale(yscale)
                        .tickSize(2)
                        .tickFormat(function(d, i) {
                            return categories[i];
                        })
                        .tickValues(d3.range(4)); // quantia de dados

                    var y_xis = canvas.append('g')
                        .attr("transform", "translate(75,0)") // eixo y
                        .attr('id', 'yaxis')
                        .call(yAxis);

                    var x_xis = canvas.append('g')
                        .attr("transform", "translate(75,110)") // eixo x (margem esquerda, altura)
                        .attr('id', 'xaxis')
                        .call(xAxis);

                    var chart = canvas.append('g')
                        .attr("transform", "translate(75,0)") // margem da barra horizontal na esquerda
                        .attr('id', 'bars')
                        .selectAll('rect')
                        .data(valores)
                        .enter()
                        .append('rect')
                        .attr('height', 19) // 19 = altura das barras
                        .attr({
                            'x': 0,
                            'y': function(d, i) {
                                return yscale(i) + 19;
                            }
                        })
                        .style('fill', function(d, i) {
                            return colorScale(i);
                        })
                        .attr('width', function(d) {
                            //return 0; // estava antes
                            return xscale(d);
                        });


                    var transit = d3.select("svg").selectAll("rect") // animação das barras
                        .data(valores)
                        .transition()
                        .duration(1000)
                        .attr("width", function(d) {
                            return xscale(d);
                        });

                    var transitext = d3.select('#bars') // labels do gráfico
                        .selectAll('text')
                        .data(valores)
                        .enter()
                        .append('text')
                        .attr({
                            'x': function(d) {
                                return xscale(d) + 5; // posição horizontal
                            },
                            'y': function(d, i) {
                                return yscale(i) + 33; // posição vertical
                            }
                        })
                        .text(function(d) {
                            if (d == 0) {
                                return ''; // se igual a zero retorna vazio
                            } else {
                                return d + ""; // se diferente de 0 retona o valor
                            }
                        }).style({
                            'fill': '#000', // cor do label
                            'font-size': '14px' // tamanho do label
                        });
                }*/

        vm.gerarGraficoImpressao = function(finalizado, aberto, atrasado) {
          /*var finalizado = 15;
          var aberto = 20;
          var atrasado = 35;*/
          $('#label_finalizado').addClass('tooltip_' + finalizado);
          $('#label_aberto').addClass('tooltip_' + aberto);
          $('#label_atrasado').addClass('tooltip_' + atrasado);

          var colors = [vm.corFinalizado, vm.corAberto, vm.corAtrasado, ''];
          var colorScale = d3.scale.quantize()
            .domain([0, 4]) // quantia de cores o gráfico
            .range(colors);

          var margins = {
              top: 5,
              left: 70,
              right: 5,
              bottom: 24
            },
            legendPanel = {
              width: 180
            },
            width = 500 - margins.left - margins.right - legendPanel.width,
            height = 100 - margins.top - margins.bottom,
            dataset = [{
                data: [{
                  month: 'Finalizados',
                  count: finalizado
                }, {
                  month: 'Abertos',
                  count: aberto
                }, {
                  month: 'Atrasados',
                  count: atrasado
                }],
                name: 'Series #1'
              }

            ],
            series = dataset.map(function(d) {
              return d.name;
            }),
            dataset = dataset.map(function(d) {
              return d.data.map(function(o, i) {
                // Structure it so that your numeric
                // axis (the stacked amount) is y
                return {
                  y: o.count,
                  x: o.month
                };
              });
            }),
            stack = d3.layout.stack();

          stack(dataset);

          var dataset = dataset.map(function(group) {
              return group.map(function(d) {
                // Invert the x and y values, and y0 becomes x0
                return {
                  x: d.y,
                  y: d.x,
                  x0: d.y0
                };
              });
            }),
            svg = d3.select('#grafico_impressao')
            .append('svg')
            .attr('width', width + margins.left + margins.right + legendPanel.width)
            .attr('height', height + margins.top + margins.bottom)
            .append('g')
            .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')'),
            xMax = d3.max(dataset, function(group) {
              return d3.max(group, function(d) {
                return d.x + d.x0;
              });
            }),
            xScale = d3.scale.linear()
            .domain([0, xMax])
            .range([0, width]),
            months = dataset[0].map(function(d) {
              return d.y;
            }),
            //_ = console.log(months),
            yScale = d3.scale.ordinal()
            .domain(months)
            .rangeRoundBands([0, height], .1),
            xAxis = d3.svg.axis()
            .scale(xScale)
            .orient('bottom'),
            yAxis = d3.svg.axis()
            .scale(yScale)
            .orient('left'),
            colours = d3.scale.category10(),
            groups = svg.selectAll('g')
            .data(dataset)
            .enter()
            .append('g')
            .style('fill', function(d, i) {
              return colours(1);
            }),
            rects = groups.selectAll('rect')
            .data(function(d) {
              return d;
            })
            .enter()
            .append('rect')
            .attr('id', function(d) {
              return 'bar_' + d.x;
            })
            .attr('x', function(d) {
              return xScale(d.x0);
            })
            .attr('y', function(d, i) {
              return yScale(d.y);
            })
            .attr('height', function(d) {
              return yScale.rangeBand();
            })
            .attr('width', function(d) {
              return xScale(d.x);
            })
            .style('fill', function(d, i) {
              return colorScale(i);
            })
            //.on('mouseover', function (d) {
            .text(function(d) {
              //var xPos = parseFloat(d3.select(this).attr('x')) / 2 + width / 2;
              var xPos = parseFloat(d3.select('#bar_' + d.x).attr('x') + (width + 35));
              //var yPos = parseFloat(d3.select('#bar_' + d.x).attr('y')) + yScale.rangeBand() / 2;
              var yPos = parseFloat(d3.select('#bar_' + d.x).attr('y'));
              d3.select('.tooltip_' + d.x)
                .style('left', xPos + 'px')
                .style('top', yPos + 'px')
                .select('#value')
                .text(d.x);

              d3.select('.tooltip_' + d.x).classed('hidden', false);
            });
          /*.on('mouseout', function () {
              d3.select('#tooltip').classed('hidden', true);
          });*/

          svg.append('g')
            .attr('class', 'axis')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxis);

          svg.append('g')
            .attr('class', 'axis')
            .call(yAxis);

        };

        vm.imprimir = function() {
          window.print();
        };

        vm.verificarNavegador = function() {
          // Opera 8.0+
          vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
          // Firefox 1.0+
          vm.isFirefox = typeof InstallTrigger !== 'undefined';
          // At least Safari 3+: "[object HTMLElementConstructor]"
          vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
          // Internet Explorer 6-11
          vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
          // Edge 20+
          vm.isEdge = !vm.isIE && !!window.StyleMedia;
          // Chrome 1+
          vm.isChrome = !!window.chrome && !!window.chrome.webstore;
          // Blink engine detection
          vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();

      }

 })();
