(function () {
    'use strict';

    angular
        .module('cobranca')
        .service('cobrancaService', cobrancaService);

    cobrancaService.$inject = ['$http', 'API'];

    function cobrancaService($http, API) {
        this.buscarDadosNeg = function (dados) {
            return $http.post(API.url + 'cobranca/service.php?t=buscarDadosNeg', dados);
        };
        this.gerarPagamento = function (dados) {
            return $http.post(API.url + 'cobranca/service.php?t=gerarPagamento', dados);
        };
        this.gerarLinkCobranca = function (dados) {
            return $http.post(API.url + 'cobranca/service.php?t=gerarLinkCobranca', dados);
        };
        this.buscaCobranca = function (dados) {
            return $http.post(API.url + 'cobranca/service.php?t=buscaCobranca', dados);
        };
        this.alterarDataCobranca = function (dados) {
            return $http.post(API.url + 'cobranca/service.php?t=alterarDataCobranca', dados);
        };
        this.removerCobranca = function (dados) {
            return $http.post(API.url + 'cobranca/service.php?t=removerCobranca', dados);
        };
    }
})();