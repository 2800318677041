(function() {
    'use strict';

    angular
        .module('app')
        .filter('formatDataUTC', function(){
            return function(date, format){
                if(format){
                    return moment(date).subtract(new Date().getTimezoneOffset(), 'm').format('DD/MM/YYYY HH:mm:ss');
                }
                return moment(date).subtract(new Date().getTimezoneOffset(), 'm').format();
            }
        })
}());
