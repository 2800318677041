(function() {
    'use strict';

    angular
        .module('cobranca')
        .config(Config);

    function Config($routeProvider) {
        $routeProvider
            .when('/cobranca/:hash_cobranca?', {
                templateUrl: 'cobranca/template/index.html' + version,
                controller: 'cobrancaController',
                controllerAs: 'vm',
            });
    }
})();