(function() {
  'use strict';

  angular
    .module('app')
    .factory('FactoryWebhook', FactoryWebhook);


  function FactoryWebhook($http, $q, $location, RC, $routeParams, API, $window, $timeout, $log, APINode) {

    var vm = this;

    var userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));

    // return function ⤵
    return {
      existEvent: existEvent,
      triggerCreate: triggerCreate,
      triggerUpdate: triggerUpdate,
      triggerSit: triggerSit,
      triggerPessoa: triggerPessoa,
      triggerPessoaEditar : triggerPessoaEditar,
      triggerEmpresa: triggerEmpresa,
      triggerEmpresaEditar: triggerEmpresaEditar
    };

    // set function ⤵

    function existEvent(ev) {
      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        return res;
      });

    };


    function triggerCreate(idProposta) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;
          var idNegWebHook = idProposta;

          //get existe event or not
          $http.get(APINode.url + 'webhook/consulta/neg', {
              headers: {
                "TokenFunil": staticTokenFunil
              }
            })
            .then(function(responseG) {
              console.log(responseG);
              // verifica se existe evento cadastrado
              if (responseG.data.existe) {

                var objEnviar = {
                  "idNegocicao": idNegWebHook
                }
                console.log('objeto enviado', objEnviar);
                //var dataSend = JSON.stringify(negociosC);

                $http({
                  method: 'POST',
                  url: APINode.url + "webhook/trigger/neg",
                  data: objEnviar,
                  headers: {
                    "TokenFunil": staticTokenFunil,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  timeout: 4000
                }).then(function(success) {
                  console.log(success);
                }, function(error) {
                  console.log(error);
                });
              } else {
                console.log('evento "NEG" não cadastrado ');
              }
            })
        }
      });
    }

    function triggerUpdate(idProposta) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;
          var idNegWebHook = idProposta;

          //get existe event or not
          $http.get(APINode.url + 'webhook/consulta/negalter', {
              headers: {
                "TokenFunil": staticTokenFunil
              }
            })
            .then(function(responseG) {
              // verifica se existe evento cadastrado
              if (responseG.data.existe) {

                var objEnviar = {
                  "idNegocicao": idNegWebHook
                }

                $http({
                  method: 'POST',
                  url: APINode.url + "webhook/trigger/negalter",
                  data: objEnviar,
                  headers: {
                    "TokenFunil": staticTokenFunil,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  timeout: 4000
                }).then(function(success) {
                  console.log(success);
                }, function(error) {
                  console.log(error);
                });
              } else {
                console.log('evento "NEGALTER" não cadastrado ');
              }
            })
        }
      });
    }

    function triggerSit(idProposta) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;
          var idNegWebHook = idProposta;

          //get existe event or not
          $http.get(APINode.url + 'webhook/consulta/negsit', {
              headers: {
                "TokenFunil": staticTokenFunil
              }
            })
            .then(function(responseG) {
              // verifica se existe evento cadastrado
              if (responseG.data.existe) {

                var objEnviar = {
                  "idNegocicao": idNegWebHook
                }

                $http({
                  method: 'POST',
                  url: APINode.url + "webhook/trigger/negsit",
                  data: objEnviar,
                  headers: {
                    "TokenFunil": staticTokenFunil,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  timeout: 4000
                }).then(function(success) {
                  console.log(success);
                }, function(error) {
                  console.log(error);
                });
              } else {
                console.log('evento "NEGALTER" não cadastrado ');
              }
            })
        }
      });
    }


    function triggerPessoa(idPessoa) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;

          //get existe event or not
          $http.get(APINode.url + 'webhook/consulta/pessoa', {
              headers: {
                "TokenFunil": staticTokenFunil
              }
            })
            .then(function(responseG) {
              console.log(responseG);
              // verifica se existe evento cadastrado
              if (responseG.data.existe) {

                var objEnviar = {
                  "idPessoa": idPessoa
                }
                console.log('objeto enviado', objEnviar);

                $http({
                  method: 'POST',
                  url: APINode.url + "webhook/trigger/pessoa",
                  data: objEnviar,
                  headers: {
                    "TokenFunil": staticTokenFunil,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  timeout: 4000
                }).then(function(success) {
                  console.log(success);
                }, function(error) {
                  console.log(error);
                });
              } else {
                console.log('evento "Pessoa" não cadastrado ');
              }
            })
        }
      });
    }

    function triggerPessoaEditar(idPessoa) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;

          //get existe event or not
          $http.get(APINode.url + 'webhook/consulta/pessoa_alter', {
              headers: {
                "TokenFunil": staticTokenFunil
              }
            })
            .then(function(responseG) {
              console.log(responseG);
              // verifica se existe evento cadastrado
              if (responseG.data.existe) {

                var objEnviar = {
                  "idPessoa": idPessoa
                }
                console.log('objeto enviado', objEnviar);

                $http({
                  method: 'POST',
                  url: APINode.url + "webhook/trigger/pessoa_alter",
                  data: objEnviar,
                  headers: {
                    "TokenFunil": staticTokenFunil,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  timeout: 4000
                }).then(function(success) {
                  console.log(success);
                }, function(error) {
                  console.log(error);
                });
              } else {
                console.log('evento "pessoa_alter" não cadastrado ');
              }
            })
        }
      });
    }


    function triggerEmpresa(idEmpresa) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;

          //get existe event or not
          $http.get(APINode.url + 'webhook/consulta/empresa', {
              headers: {
                "TokenFunil": staticTokenFunil
              }
            })
            .then(function(responseG) {
              console.log(responseG);
              // verifica se existe evento cadastrado
              if (responseG.data.existe) {

                var objEnviar = {
                  "idEmpresa": idEmpresa
                }
                console.log('objeto enviado', objEnviar);
                //var dataSend = JSON.stringify(negociosC);

                $http({
                  method: 'POST',
                  url: APINode.url + "webhook/trigger/empresa",
                  data: objEnviar,
                  headers: {
                    "TokenFunil": staticTokenFunil,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  timeout: 4000
                }).then(function(success) {
                  console.log(success);
                }, function(error) {
                  console.log(error);
                });
              } else {
                console.log('evento "Empresa" não cadastrado ');
              }
            })
        }
      });
    }


    function triggerEmpresaEditar(idEmpresa) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;

          //get existe event or not
          $http.get(APINode.url + 'webhook/consulta/empresa_alter', {
              headers: {
                "TokenFunil": staticTokenFunil
              }
            })
            .then(function(responseG) {
              console.log(responseG);
              // verifica se existe evento cadastrado
              if (responseG.data.existe) {

                var objEnviar = {
                  "idEmpresa": idEmpresa
                }
                console.log('objeto enviado', objEnviar);
                //var dataSend = JSON.stringify(negociosC);

                $http({
                  method: 'POST',
                  url: APINode.url + "webhook/trigger/empresa_alter",
                  data: objEnviar,
                  headers: {
                    "TokenFunil": staticTokenFunil,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  timeout: 4000
                }).then(function(success) {
                  console.log(success);
                }, function(error) {
                  console.log(error);
                });
              } else {
                console.log('evento "empresa_alter" não cadastrado ');
              }
            })
        }
      });
    }



  }
}());
