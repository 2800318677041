(function() {
    'use strict';

    angular
    .module('funilMkt')
    .controller('funilMktBouncesController', function(funilMktService, RC, $timeout, $scope){
        const vm = this;

        let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));

        $scope.filterByDate = [moment().subtract(7, 'days'), moment()];
        vm.listLimit = 15;

        const getList = (url) => {
            vm.showData = 'loading';

            funilMktService.getListBounces(url)
            .then((result) => {
                console.log(result.data.data.response);
                $timeout(() => {
                    if(result.data.data.response.length){
                        vm.showData = 'hasData';
                    }else{
                        vm.showData = 'noData';
                    }
                    vm.listBounces = result.data.data.response
                });
            })
            .catch((err) => {
                console.log(err);
                $timeout(() => vm.showData = 'noData');
            })
        }

        getList(`${$scope.filterByDate[0].format('YYYY-MM-DD')}/${$scope.filterByDate[1].format('YYYY-MM-DD')}/${userAPI[0].user_id}/${vm.emailFilter}/${vm.typeFilter}`);

        vm.sendFilter = (value) => {
            if(value == 'datePicker') $scope.$apply();

            let emailFilter = vm.emailFilter == '' ? 'undefined' : vm.emailFilter
            let typeFilter = vm.typeFilter == '' ? 'undefined' : vm.typeFilter            

            getList(`${moment($scope.filterByDate[0]).format('YYYY-MM-DD')}/${moment($scope.filterByDate[1]).format('YYYY-MM-DD')}/${userAPI[0].user_id}/${emailFilter}/${typeFilter}`)
        };
    });
})();