(function() {
    'use strict';

    angular
        .module('app')        
        .run(runBlock);

    function runBlock($rootScope, $timeout, $window, socket, RC, webNotification, $location, APINode) {

        var vm = this;

        if (window.localStorage.getItem('5c479de2')) {
            var storageSOCK = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
            if(storageSOCK) {
                var sockUser = storageSOCK['0'].user_id;
                var canalDoUser = 'canal_' + sockUser;

                // ------------------------------------------------------//
                //           escuta o socket do usuario                  //
                // ------------------------------------------------------//
                socket.on(canalDoUser, function (data) {
                    // html4
                    var mensageSockHTML5 = data['0'].mensagem;
                    var imgHTML5 = '/app/template/img/funil-pro-logo-notificacao.png';

                    // ------------------------------------------------------//
                    //           cria storage de notificação                 //
                    // ------------------------------------------------------//
                    var objNotification = RC.funilRC.encrypt(JSON.stringify(data));
                    window.localStorage.setItem('notSis', objNotification);


                    // ------------------------------------------------------//
                    //           notificação HTML5                           //
                    // ------------------------------------------------------//
                    webNotification.showNotification('Funil - Notificação', {
                    body: mensageSockHTML5,
                    icon: imgHTML5,
                    onClick: function onNotificationClicked($location) {
                        if (data['0'].link) {
                            window.location.replace(data['0'].link);
                        } else {
                            window.location.replace(APINode.notLinkHTML5);
                        }
                    },
                    autoClose: 4000 //fecha em 4seg
                    });

                });

            }  
        }

    }
})();