(function () {
    'use strict';

    angular
        .module ('app')
        .directive ('msgDirective', msgDirective);


    function msgDirective() {
        return {
            restrict: 'E',
            scope: {
                type        : '@',
                text        : '@'               
            },
            template : ''
        };
    }

    // alertify.set('notifier','delay', 200);
    // alertify.set('notifier','position', 'top-right');
    // alertify.warning('Warning notification message.'); 
    // alertify.success('Success notification message.'); 
    // alertify.error('Error notification message.');

} ());