(function() {
    'use strict';

    angular
        .module('app')
        .factory('tokenNodejs', function($http, APINode, RC) {
            return {
                get: (url) => {
                    return new Promise((resolve, reject) => {
                        let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
                        
                        $http.get(APINode.url + 'token/' + userAPI[0].saas_id).then((res) => {
                            $http.get(APINode.url + url, {
                                headers: {
                                    tokenFunil: res.data.data.api_token
                                }
                            })
                            .then((result) => {                    
                                resolve(result);
                            })
                            .catch((err) => reject(err))
                        })
                        .catch((err) => reject(err));
                    })
                },
                post: (url, data) => {
                    return new Promise((resolve, reject) => {
                        let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
                        
                        $http.get(APINode.url + 'token/' + userAPI[0].saas_id).then((res) => {
                            $http.post(APINode.url + url, data, {
                                headers: {
                                    tokenFunil: res.data.data.api_token
                                }
                            })
                            .then((result) => {                    
                                resolve(result);
                            })
                            .catch((err) => reject(err))
                        })
                        .catch((err) => reject(err));
                    })
                },
                delete: (url) => {
                    return new Promise((resolve, reject) => {
                        let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
                        
                        $http.get(APINode.url + 'token/' + userAPI[0].saas_id).then((res) => {
                            $http.delete(APINode.url + url, {
                                headers: {
                                    tokenFunil: res.data.data.api_token
                                }
                            })
                            .then((result) => {                    
                                resolve(result);
                            })
                            .catch((err) => reject(err))
                        })
                        .catch((err) => reject(err));
                    })
                },
                onlyToken: () => {
                    return new Promise((resolve, reject) => {
                        let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
                        
                        $http.get(APINode.url + 'token/' + userAPI[0].saas_id)
                        .then((res) => {
                            resolve({user: userAPI[0].user_hash, funil: res.data.data.api_token});
                        })
                        .catch((err) => reject(err));
                    })
                }
            }
        });
}());
