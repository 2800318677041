/*
 * @Author: Glauber Funez
 * @Date:   2018-06-28 13:54:04
 * @Last Modified by:   Glauber Funez
 * @Last Modified time: 2018-07-12 17:34:03
 */

(function() {
  'use strict';

  // funil-mask-fone - identificador do componente
  // funil-mask-fone-full-object="true"  - retorna objeto completo, incluido o país
  angular
    .module('app')
    .directive('funilMaskFone', function($timeout) {
      return {
        restrict: 'A',
        scope: {
          ngModel: '='
        },
        link: function($scope, $element, $attrs) {

          $timeout(function() {
            // $($element).inputmask("phone", $scope.$eval($attrs.funilMaskFone));
            if (!$element.val()) {
              $scope.$eval(function() {
                $element.val(55)
              });
            }

            $element.inputmask("phone", $scope.$eval($attrs.funilMaskFone));

            $element.on('keyup', function(e) {

              var dados = {
                value: $element.val(),
                pais: $($element).inputmask("getmetadata")
              }

              if ($($element).inputmask("isComplete")){
                // $element.css("border","1px solid #cccccc");

                if ($attrs.funilMaskFoneFullObject) {
                  $scope.$eval(function() {
                    $scope.ngModel = dados;
                  });
                } else {
                  $scope.$eval(function() {
                    $scope.ngModel = $element.val();
                  });
                }
              } else {
                // $element.css("border","1px solid red");                
                /*$scope.$eval(function() {
                  $scope.ngModel = 55;
                });*/
              }

            });

            $element.on('blur', function(e) {
              if (!$($element).inputmask("isComplete")){
                  $scope.$eval(function() {
                    $scope.ngModel = 55;
                  });
              }
            });
          });
        }
      };
    });    




}());
