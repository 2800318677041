(function() {
    'use strict';

    angular
        .module('clientes')
        .service('ClientesService', ClientesService);

    ClientesService.$inject = ['$http', 'API'];

    function ClientesService($http, API) {
        this.findAll = function(data) {
            return $http.put(API.url + 'clientes/service.php?t=clientes', data);
        }
        this.findSemEmail = function() {
            return $http.put(API.url + 'clientes/service.php?t=semEmail');
        }
        this.clientesSemEmail = function() {
            return $http.put(API.url + 'clientes/service.php?t=clientesSemEmail');
        }
        this.tagsAtivas = function() {
            return $http.put(API.url + 'clientes/service.php?t=tagsAtivas');
        }
        this.findPeriodo = function(intervalo) {
            return $http.put(API.url + 'clientes/service.php?t=intervalo', intervalo);
        }
        this.findPfPj = function(intervalo) {
            return $http.put(API.url + 'clientes/service.php?t=pf_pj', intervalo);
        }
        this.findPf = function(intervalo) {
            return $http.put(API.url + 'clientes/service.php?t=pf', intervalo);
        }
        this.findPj = function(intervalo) {
            return $http.put(API.url + 'clientes/service.php?t=pj', intervalo);
        }
        this.clientesPf = function(intervalo) {
            return $http.put(API.url + 'clientes/service.php?t=clientesPf', intervalo);
        }
        this.clientesPj = function(intervalo) {
            return $http.put(API.url + 'clientes/service.php?t=clientesPj', intervalo);
        }
        this.findCategorias = function() {
            return $http.get(API.url + 'clientes/service.php?t=categorias');
        }
        this.findCidades = function() {
            return $http.get(API.url + 'clientes/service.php?t=cidades');
        }
        this.findEstados = function() {
            return $http.get(API.url + 'clientes/service.php?t=estados');
        }
        this.findCategoriasCliente = function(cliente) {
            return $http.get(API.url + 'clientes/service.php?t=categorias_cliente&cliente=' + cliente);
        }
        this.findCategFilter = function(id) {
            return $http.get(API.url + 'clientes/service.php?t=categ_filter&id=' + id);
        }
        this.clientesCategoria = function(id, data) {
            return $http.post(API.url + 'clientes/service.php?t=clientesCategoria&id=' + id, data);
        }
        this.clientesTag = function(id) {
            return $http.post(API.url + 'clientes/service.php?t=clientesTag&id=' + id);
        }
        this.clientesPeriodo = function(data) {
            return $http.post(API.url + 'clientes/service.php?t=clientesPeriodo', data);
        }
        this.findUnique = function(slug) {
            return $http.get(API.url + 'clientes/service.php?t=cliente_unico&slug=' + slug);
        }
        this.findTags = function() {
            return $http.get(API.url + 'clientes/service.php?t=tags');
        }
        this.nomeTag = function(id) {
            return $http.get(API.url + 'clientes/service.php?t=nomeTag&id='+id);
        }
        this.findTagsHistorico = function(cliente) {
            return $http.get(API.url + 'clientes/service.php?t=tags_clientes&cliente=' + cliente);
        }
        this.create = function(cliente) {
            return $http.post(API.url + 'clientes/service.php?t=create', cliente);
        }
        this.salvaTagsCliente = function(tags, cliente) {
            return $http.put(API.url + 'clientes/service.php?t=tags&slug=' + cliente, tags);
        }
        this.update = function(cliente, slug) {
            return $http.put(API.url + 'clientes/service.php?slug=' + slug, cliente);
        }
        this.removeTag = function(tag, slug) {
            return $http.put(API.url + 'clientes/service.php?t=remover&slug=' + slug, tag);
        }
        this.deleteCliente = function(slug) {
            return $http.put(API.url + 'clientes/service.php?t=remover_cliente&slug=' + slug);
        }
        this.listarAtendimentos = function(slug) {
            return $http.get(API.url + 'clientes/service.php?t=listar_atendimentos&slug=' + slug);
        }
        this.cadastrarNovoAtendimento = function(dados) {
            return $http.post(API.url + 'clientes/service.php?t=cadastrar_atendimento', dados);
        }
        this.updateAtendimento = function(dados) {
            return $http.post(API.url + 'clientes/service.php?t=update_atendimento', dados);
        }
        this.excluirAtendimento = function(id) {
            return $http.post(API.url + 'clientes/service.php?t=excluir_atendimento&id=' + id);
        }
        this.verificarAdm = function(hash) {
            return $http.put(API.url + 'clientes/service.php?t=verificar_adm&hash=' + hash);
        }
    }
})();