(function() {
    'use strict';

    angular
        .module('app')
        .factory('FactoryRoles', FactoryRoles)

    function FactoryRoles($http, $q, $location, RC, $routeParams, API) {

        var vm = this;
        vm.roles = [];
        vm.rolesAcessos = [];

        // return function ⤵
        return {
            setRoles            :   setRoles,
            loadRoles           :   loadRoles,
            isModPessoas        :   isModPessoas,
            isModNegociacao     :   isModNegociacao,
            isModFunil          :   isModFunil,
            isModCompromissos   :   isModCompromissos,
            isModRelatorios     :   isModRelatorios,
            isModConfig         :   isModConfig,
            attRoles            :   attRoles,
            checkRolesUrl       :   checkRolesUrl,
            checkRolesStorage   :   checkRolesStorage,
            checkRolesVisu      :   checkRolesVisu,
            checkRolesExclui    :   checkRolesExclui,
            checkRolesAltera    :   checkRolesAltera,
            checkRolesCria      :   checkRolesCria
        }

        // set function ⤵

            function setRoles(roles) {
                vm.roles = roles;
                vm.rolesAcessos = vm.roles;
            }


            function isModPessoas() {
                //filtro um unico módulo
                var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                    return modAtual.mod_id == 1;
                });

                if (modAtual.length !== 0) {
                    var objRol = { 'acesso': true, 'cria': modAtual[0].permissoes[0].rol, 'visualiza': modAtual[0].permissoes[1].rol, 'altera': modAtual[0].permissoes[2].rol, 'exclui': modAtual[0].permissoes[3].rol };
                    var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                    if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                        window.localStorage.setItem('isModPessoas', RC.funilRC.encrypt('false'));
                        return false;

                    } else {
                        window.localStorage.setItem('isModPessoas', objRC);
                        return true;
                    }   
                }
             
            }

            function isModNegociacao() {
                //filtro um unico módulo
                var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                    return modAtual.mod_id == 2;
                });

                if (modAtual.length !== 0) {
                    var objRol = { 'acesso': true, 'cria': modAtual[0].permissoes[0].rol, 'visualiza': modAtual[0].permissoes[1].rol, 'altera': modAtual[0].permissoes[2].rol, 'exclui': modAtual[0].permissoes[3].rol };
                    var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                    if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                        window.localStorage.setItem('isModNegociacao', RC.funilRC.encrypt('false'));
                        return false;
                    } else {
                        window.localStorage.setItem('isModNegociacao', objRC);
                        return true;
                    }
                }
            }

            function isModFunil() {
                //filtro um unico módulo
                var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                    return modAtual.mod_id == 3;
                });

                if (modAtual.length !== 0) {
                    var objRol = { 'acesso': true, 'cria': modAtual[0].permissoes[0].rol, 'visualiza': modAtual[0].permissoes[1].rol, 'altera': modAtual[0].permissoes[2].rol, 'exclui': modAtual[0].permissoes[3].rol };
                    var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                    if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                        window.localStorage.setItem('isModFunil', RC.funilRC.encrypt('false'));
                        return false;
                    } else {
                        window.localStorage.setItem('isModFunil', objRC);
                        return true;
                    }
                }
            }

            function isModCompromissos() {
                //filtro um unico módulo
                var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                    return modAtual.mod_id == 4;
                }); 

                if (modAtual.length !== 0) {
                    var objRol = { 'acesso': true, 'cria': modAtual[0].permissoes[0].rol, 'visualiza': modAtual[0].permissoes[1].rol, 'altera': modAtual[0].permissoes[2].rol, 'exclui': modAtual[0].permissoes[3].rol };
                    var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                    if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                        window.localStorage.setItem('isModCompromissos', RC.funilRC.encrypt('false'));
                        return false;
                    } else {
                        window.localStorage.setItem('isModCompromissos', objRC);
                        return true;
                    }
                }
            }

            function isModRelatorios() {
                //filtro um unico módulo
                var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                    return modAtual.mod_id == 5;
                });

                if (modAtual.length !== 0) {
                    var objRol = { 'acesso': true, 'cria': modAtual[0].permissoes[0].rol, 'visualiza': modAtual[0].permissoes[1].rol, 'altera': modAtual[0].permissoes[2].rol, 'exclui': modAtual[0].permissoes[3].rol };
                    var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                    if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                        window.localStorage.setItem('isModRelatorios', RC.funilRC.encrypt('false'));
                        return false;
                    } else {
                        window.localStorage.setItem('isModRelatorios', objRC);
                        return true;
                    }
                }
            }

            function isModConfig() {
                //filtro um unico módulo
                var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                    return modAtual.mod_id == 6;
                });

                if (modAtual.length !== 0) {
                    var objRol = { 'acesso': true, 'cria': modAtual[0].permissoes[0].rol, 'visualiza': modAtual[0].permissoes[1].rol, 'altera': modAtual[0].permissoes[2].rol, 'exclui': modAtual[0].permissoes[3].rol };

                    if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                        window.localStorage.setItem('isModConfig', false);
                        return false;
                    } else {
                        window.localStorage.setItem('isModConfig', JSON.stringify(objRol));
                        return true;
                    }
                }
            }

            function loadRoles() {
                var hash = {
                    'hash': Cookies.get('crm_funil_hash')
                }

                $http.post('login/service.php?f=loadRoles', hash).then(function(response) {
                    if (response.data != '' && response.data != null) {
                        setRoles(response.data.acessos);
                        isModPessoas();
                        isModNegociacao();
                        isModFunil();
                        isModCompromissos();
                        isModRelatorios();
                        isModConfig();
                    }
                });
            }
            
            function attRoles(){
                isModPessoas();
                isModNegociacao();
                isModFunil();
                isModCompromissos();
                isModRelatorios();
                isModConfig();
            } 

            function checkRolesUrl(mod){
                attRoles();

                var modRC = RC.funilRC.decrypt(window.localStorage.getItem(mod));

                if (modRC == 'false') {
                    console.log(`vc passou o modulo ${mod}`);
                    $location.path('/');
                    alertify.set('notifier','delay', 10);
                    alertify.set('notifier','position', 'top-right');
                    alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');              

                    return false;                          
                }else{
                    return true;
                }
            }

            function checkRolesStorage(mod){ //check at roles in LocalStorage and return 
                attRoles()

                var modRC = RC.funilRC.decrypt(window.localStorage.getItem(mod));
                var rolesStorage = JSON.parse(modRC);
                return rolesStorage;
            }

            function checkRolesVisu(mod){
                attRoles();

                var modVisu = RC.funilRC.decrypt(window.localStorage.getItem(mod));
                var visuRolesStorage = JSON.parse(modVisu);            

                switch(visuRolesStorage.visualiza) {
                    case '1':
                        console.log('permissão 1 tudo | visualiza');
                        return true;
                        break;
                    case '2':
                        console.log('permissão 2 grupo | visualiza');

                        var dadosPut = [];
                        var userLoc = RC.funilRC.decrypt(window.localStorage.getItem('5c479de2'));
                        var dadosUser = JSON.parse(userLoc);
                        dadosPut.push(dadosUser[0]);

                        var rolIdDecry = RC.funilRC.decrypt(window.localStorage.getItem(mod));
                        var rolStorageObj = JSON.parse(rolIdDecry);
                        var rolID = rolStorageObj.visualiza;
                        dadosPut.push(rolID);
                        
                        setTimeout(function() {
                            vm.routParUser = $routeParams.slugCliente;
                        }, 5);

                        setTimeout(function() {
                            $http.put(API.url + 'pessoas/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                                .then(function(response){
                                    if(response.data === 0){
                                        $location.path('/');

                                        alertify.set('notifier','delay', 10);
                                        alertify.set('notifier','position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');  

                                        return false;
                                    }else{
                                        return true;
                                    };
                                }, function(error) {
                                   console.log(error);
                                });
                        }, 15);

                        return true;
                        break;
                    case '3':
                        var dadosPut = [];
                        var userLoc = RC.funilRC.decrypt(window.localStorage.getItem('5c479de2'));
                        var dadosUser = JSON.parse(userLoc);
                        dadosPut.push(dadosUser[0]);

                        var rolIdDecry = RC.funilRC.decrypt(window.localStorage.getItem(mod));
                        var rolStorageObj = JSON.parse(rolIdDecry);
                        var rolID = rolStorageObj.visualiza;
                        dadosPut.push(rolID);
                        
                        setTimeout(function() {
                            vm.routParUser = $routeParams.slugCliente;
                        }, 5);

                        setTimeout(function() {
                            $http.put(API.url + 'pessoas/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                                .then(function(response){
                                    if(response.data === 0){
                                        $location.path('/');

                                        alertify.set('notifier','delay', 10);
                                        alertify.set('notifier','position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>'); 

                                        return false;
                                    }else{
                                        return true;
                                    };
                                });
                        }, 15);

                        return true;
                        break;
                    case '4':
                        alertify.set('notifier','delay', 10);
                                        alertify.set('notifier','position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');
                        

                        $location.path('/');
                        return false;
                        break;
                }
            }

            function checkRolesExclui(mod){//use for btn | ng-if etc..
                attRoles()

                var modExclui = RC.funilRC.decrypt(window.localStorage.getItem(mod));
                var excluiRolesStorage = JSON.parse(modExclui);            

                switch(excluiRolesStorage.exclui) {
                    case '1':
                        return true;
                        break;
                    case '2':
                        return true;checkRolesAltera
                        break;
                    case '3':
                        return true;
                        break;
                    case '4':
                        return false;
                        break;
                } 
            }

            function checkRolesAltera(mod){//use for btn | ng-if etc..
                attRoles()

                var modAltera = RC.funilRC.decrypt(window.localStorage.getItem(mod));
                var excluiRolesStorage = JSON.parse(modAltera); 

                switch(excluiRolesStorage.altera) {
                    case '1':
                        return true;
                        break;
                    case '2':
                        return true;
                        break;
                    case '3':
                        return true;
                        break;
                    case '4':
                        return false;
                        break;
                } 
            }

            function checkRolesCria(mod){
                attRoles();

                var modCria = RC.funilRC.decrypt(window.localStorage.getItem(mod));
                var criaRoleStorage = JSON.parse(modCria);

                switch(criaRoleStorage.cria){
                    case '1':
                        return true;
                    break;
                    case '2':
                        return true;
                    break;
                    case '3':
                        return true;
                    break;
                    case '4':
                        return false;
                    break;

                }
            }
            
    

    //  ------ roles ------
    // se criar e visualizar for 4, não tem acesso
    //  ------ Encrypt e Decrypt ------
    // RC.funilRC.encrypt('') encrypt object
    // RC.funilRC.decrypt('') decrypt object
    //  ------ LocalStorage ------
    // var teste = window.localStorage.getItem('isModPessoas');             
    // console.log(JSON.parse(teste));
    // sendo que ao gravar e ao ler passa pelo Encrypt e Decrypt

    }
}());
