(function() {
    'use strict';

    angular
        .module('app')
        .controller('ControllerInfoEmailRecebido', function(dataParams, $http, APINode, RC){
            console.log('ControllerInfoEmailRecebido', dataParams)

            const $ctrl = this;

            $ctrl.data = dataParams;
        })
}());
