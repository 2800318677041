/*
* @Author: Glauber Funez
* @Date:   2018-08-27 09:39:37
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-09-06 15:16:29
*/



(function() {
    'use strict';

    angular
        .module('app')
        .controller('multicontaController', multicontaController)
        .directive('multiconta', multicontaDirective);

    /** @ngInject */
    function multicontaController($scope, $element, $timeout, $window, $location, FactoryRoles, RC, usuariosService) {
        
        // -------------------------- consts inicio ---------------------------------- //
        var vm = this;
        // -------------------------- consts final ---------------------------------- //

        // -------------------------- Data inicio ----------------------------------- //
        vm.usuarios;
        var header = $( "body" ).find('header');
        var main = $( "body" ).find('main');
        var main_login = $( "body" ).find('#blur_login');
        var body = $( "body" );
        // -------------------------- Data final ------------------------------------ //

        // -------------------------- Methods inicio ------------------------------- //
        
        $scope.cancelar = function() {
            header.css({
                '-webkit-filter': 'none',
                'filter': 'none',
                '-moz-filter': 'none',
                '-o-filter': 'none',
                '-ms-filter': 'none'
            });

            main.css({
                '-webkit-filter': 'none',
                'filter': 'none',
                '-moz-filter': 'none',
                '-o-filter': 'none',
                '-ms-filter': 'none'
            });


            main_login.css({
                '-webkit-filter': 'none',
                'filter': 'none',
                '-moz-filter': 'none',
                '-o-filter': 'none',
                '-ms-filter': 'none'
            });

            body.css({
                'overflow': 'auto'
            })

            var inputEl = $( "body" ).find('#multiconta');
            inputEl.hide();                        
        }

        $scope.removerBlur = function() {
            header.css({
                '-webkit-filter': 'none',
                'filter': 'none',
                '-moz-filter': 'none',
                '-o-filter': 'none',
                '-ms-filter': 'none'
            });

            main.css({
                '-webkit-filter': 'none',
                'filter': 'none',
                '-moz-filter': 'none',
                '-o-filter': 'none',
                '-ms-filter': 'none'
            });


            main_login.css({
                '-webkit-filter': 'none',
                'filter': 'none',
                '-moz-filter': 'none',
                '-o-filter': 'none',
                '-ms-filter': 'none'
            });

            body.css({
                'overflow': 'auto'
            })                       
        }

        $(window).keydown(function(event){
           if (event.keyCode == 27) {
                $scope.cancelar();
                $scope.removerBlur();
           }
        });


        $scope.logout = function() {
            Cookies.remove('crm_funil_nome');
            Cookies.remove('crm_funil_hash');
            Cookies.remove('crm_funil_url_email_comp');
            $location.url("/login");
            Cookies.remove('funil_u_id');
            Cookies.remove('funil-notiLog');
        }


        $scope.deletarConta = function (user, ind) {
            console.log('deletar user: ', user)
            var indentificador = `#deletando_${ind}`;

            angular.element(indentificador).html('<div class="spinner"></div>');

            //comparar se o user local é o mesmo que esta sendo deletado
            var userlocal = angular.copy(JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')))['0'])
            var userDeletar = angular.copy(user);

            // deleta e faz logout
            if (userlocal.saas_id == userDeletar.saas_id) {
                console.log('deletaria e faria logout')

                var dados = {
                    'id': userDeletar.user_id,
                    'saasid': userDeletar.saas_id,
                    'email': userDeletar.user_email,
                    'nome': userDeletar.user_nome
                };

                usuariosService.deleteUser(dados).then(function(response) {
                    
                    if (response.status==200) {
                        console.log('sucessssssso')
                        angular.element(indentificador).html('Sim');

                        swal({
                            title: 'Removido!',
                            text: 'Para retomar o acesso, uma nova solicitação deverá ser feita para o gestor da conta!',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 4000
                        });
                        $scope.cancelar()
                        $scope.removerBlur()

                        $timeout(function() {
                            $scope.cancelar()
                            $scope.removerBlur()
                            $scope.logout();
                            $timeout(function() {
                                $scope.cancelar()
                                $scope.removerBlur()
                            }, 500);
                        }, 4000);

                        $timeout(function() {
                            $scope.cancelar()
                            $scope.removerBlur()
                        }, 4500);
                    }

                }, function(error) {
                    $log.error(error);
                });

            } 
            // deleta, remove o deletado do storage e faz reload
            else {
                var multiConFuni = angular.copy(JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('multiConFuni'))))
                var indentificador = `#deletando_${ind}`;
                angular.element(indentificador).html('<div class="spinner"></div>');

                var posdeletar = []
                for (var i = 0; i < multiConFuni.length; i++) {
                    if ( multiConFuni[i].user_id != user.user_id && multiConFuni[i].saas_id != user.saas_id ) {
                        posdeletar.push(multiConFuni[i])
                    }
                }

                var dados = {
                    'id': user.user_id,
                    'saasid': user.saas_id,
                    'email': user.user_email,
                    'nome': user.user_nome
                };


                usuariosService.deleteUser(dados).then(function(response) {                    
                    if (response.status==200) {
                        angular.element(indentificador).html('Sim');

                        // novo storage
                        var multi = RC.funilRC.encrypt(JSON.stringify(posdeletar));
                        $window.localStorage.setItem('multiConFuni', multi);

                        swal({
                            title: 'Removido!',
                            text: 'Para retomar o acesso, uma nova solicitação deverá ser feita para o gestor da conta!',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 4000
                        });
                        $scope.cancelar()
                        $scope.removerBlur()

                        $timeout(function() {
                            $window.location.reload();

                            $scope.cancelar()
                            $scope.removerBlur()
                            $timeout(function() {
                                $scope.cancelar()
                                $scope.removerBlur()
                            }, 500);
                        }, 4000);                        
                    }

                }, function(error) {
                    $log.error(error);
                });

                


            }


        }


        $scope.setaSaas = function(click) {
            console.log('login', click)

            if ($location.$$url == '/login') {
                console.log('esta no login')

                // array para contornar diferença da obtenção de dados
                var usuarioClicado = []
                usuarioClicado.push(click)

                FactoryRoles.setRoles(click.acessos);
                FactoryRoles.isModPessoas();
                FactoryRoles.isModNegociacao();
                FactoryRoles.isModFunil();
                FactoryRoles.isModCompromissos();
                //FactoryRoles.isModRelatorios();
                FactoryRoles.isModConfig();

                var uIdLocalObj = JSON.stringify(usuarioClicado);
                var encryUserLocal = RC.funilRC.encrypt(uIdLocalObj);
                var nomeUser = RC.funilRC.encrypt('user');

                // Cookies.set('funil_u_id', response.data.user[0].user_id);
                
                $window.localStorage.setItem(nomeUser, encryUserLocal); 
                Cookies.set('funil_u_id', click.user_id);
                
                Cookies.set('crm_funil_nome', click.user_nome);
                Cookies.set('crm_funil_hash', click.user_hash);


                // add no storage a hash do user usuario Clicado
                // para controle do user padrão
                $window.localStorage.setItem('multiconta_padrao', click.user_hash); 

                $scope.removerBlur()

                $('.box_login').hide()
                $('#carregamento_principal').show()

                if (click.saas_ativo == 0) { // SAAS INATIVO
                    window.location.href = vm.urlFUNIL + '/minhaconta';
                } else { // SAAS ATIVO
                    if (Cookies.get('crm_funil_url_email_comp')) {
                        $location.url(Cookies.get('crm_funil_url_email_comp'));
                    } else {
                        $location.url('/');
                    }
                }
            } else {
                console.log('fora do login')

                swal({
                    title: 'Deseja realmente trocar a conta?',
                    text: 'Os dados serão recarregados e qualquer informação não salva será perdida',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function(isConfirm) {
                    if (isConfirm) {
                        $window.onkeydown = null;
                        $window.onfocus = null;
                        swal.close();

                        // array para contornar diferença da obtenção de dados
                         var usuarioClicado = []
                        usuarioClicado.push(click)

                        FactoryRoles.setRoles(click.acessos);
                        FactoryRoles.isModPessoas();
                        FactoryRoles.isModNegociacao();
                        FactoryRoles.isModFunil();
                        FactoryRoles.isModCompromissos();
                        //FactoryRoles.isModRelatorios();
                        FactoryRoles.isModConfig();

                        var uIdLocalObj = JSON.stringify(usuarioClicado);
                        var encryUserLocal = RC.funilRC.encrypt(uIdLocalObj);
                        var nomeUser = RC.funilRC.encrypt('user');

                        // Cookies.set('funil_u_id', response.data.user[0].user_id);
                        
                        $window.localStorage.setItem(nomeUser, encryUserLocal); 
                        Cookies.set('funil_u_id', click.user_id);
                        
                        Cookies.set('crm_funil_nome', click.user_nome);
                        Cookies.set('crm_funil_hash', click.user_hash);


                        // add no storage a hash do user usuario Clicado
                        // para controle do user padrão
                        $window.localStorage.setItem('multiconta_padrao', click.user_hash);  
                        
                        var inputEl = $( "body" ).find('#multiconta');
                        inputEl.hide();                        

                        header.css({
                            '-webkit-filter': 'none',
                            'filter': 'none',
                            '-moz-filter': 'none',
                            '-o-filter': 'none',
                            '-ms-filter': 'none'
                        });

                        main.css({
                            '-webkit-filter': 'none',
                            'filter': 'none',
                            '-moz-filter': 'none',
                            '-o-filter': 'none',
                            '-ms-filter': 'none'
                        });


                        main_login.css({
                            '-webkit-filter': 'none',
                            'filter': 'none',
                            '-moz-filter': 'none',
                            '-o-filter': 'none',
                            '-ms-filter': 'none'
                        });

                        body.css({
                            'overflow': 'auto'
                        })
                        
                        $location.url('/');
                        setTimeout(function() {
                            $window.location.reload();
                        },200);
                    } else {
                        swal.close();
                        // $scope.cancelar();
                    }
                });

            }
        }

        
        /*
        * Inicio - watch para pegar os usuários
        */
        $scope.$watch('dados', function(current) {
            if (current) {
                $timeout(function() {
                    vm.usuarios = current;
                }, 100);
            }
        });
        



        // -------------------------- Methods final ------------------------------- //


    }

    /** @ngInject */
    function multicontaDirective($document) {
        return {
            restrict: 'AE',
            scope: {
                dados:'=dados'
            },
            require: 'multiconta',
            controller: 'multicontaController as multiconta',
            bindToController: {
                // teste: '=?'
            },
            templateUrl: 'app/directive/multiconta/multiconta.html',
            compile: function(tElement, scope) {
                // tElement.addClass('ms-search-bar');

                return function postLink(scope, iElement) {
                    // Data
                    var inputEl,
                        bodyEl = $document.find('body');

                    // Methods                  

                    // Initialize
                    init();

                    /**
                     * Initialize
                     */
                    function init() {
                        // Grab the input element
                        inputEl = iElement.find('#multiconta');
                        inputEl.show();
                        
                    }

                    //////////                    
                };
            }
        };
    }

    //exemplo:
    // <multiconta dados="vm.usuariosComponente" ng-if="vm.multiconta"></multiconta>


})();

