(function() {
    'use strict';

    angular
        .module('app')
        .controller('ControllerInfoEmail', function(emailData, $http, APINode, RC){
            console.log('ControllerInfoEmail', emailData)

            const $ctrl = this;

            let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
            let BaseApi = APINode.url + 'token/' + userAPI[0].saas_id;

            $http.get(BaseApi).then((res) => {
                $http.get(APINode.url + 'mailmkt/dashboard/lead-info/' + emailData.id + '/' + emailData.email, {
                    headers: {
                        tokenFunil: res.data.data.api_token
                    }
                })
                .then((result) => {                    
                    $ctrl.userData = result.data.data.response[0]
                })
                .catch((err) => {
                    console.log(err)
                    $ctrl.userData = []
                })
            })
            .catch((err) => console.log(err));
        })
}());
