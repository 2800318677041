(function() {
    'use strict';

    angular
        .module('app')
        .factory('socket', socket);


    function socket($rootScope, socketFactory, APINode) {
      return socketFactory({
        ioSocket: io.connect(APINode.socket)
      });        
    }

}());
