(function() {
    'use strict';

    angular
        .module('app')
        .component('datePickerJs', {
            template: `
                <ng-if ng-if="!$ctrl.pickerType || $ctrl.pickerType == 'input'">
                    <input
                        type="text"
                        name="{{$ctrl.name}}"
                        class="form-control font_input background_field"
                        placeholder="{{$ctrl.placeholder}}"
                        value="{{$ctrl.placeholder}} {{$ctrl.textValue}}"
                        readonly="true"
                        style="background-color: #F3F3F3;"
                        ng-style="$ctrl.pickerStyle">{{$ctrl.pickerStyle}}
                </ng-if>
                <ng-if ng-if="$ctrl.pickerType == 'button'">
                    <button class="button-datePicker">{{$ctrl.placeholder}}</button>
                </ng-if>
                <ng-if ng-if="$ctrl.pickerType == 'onlyText'">
                    <div style="position: relative;">{{$ctrl.placeholder}}</div>
                </ng-if>
            `,
            bindings: {
                ngModel: '=',
                formatType: '@',
                placeholder: '@',
                name: '@',
                maxDate: '<',
                pickerType: '@',
                pickerChange: '&',
                pickerStyle: '<',
                pickerSingle: '@',
                pickerTime: '@'
            },
            controller: function($element, $filter){

                const $ctrl = this;
                let configPicker = {
                    //autoUpdateInput: false,
                    'opens': 'center',
                    "singleDatePicker": $ctrl.pickerSingle ? true : false,
                    "timePicker": $ctrl.pickerTime ? true : false,
                    'locale': {
                        'format': 'DD/MM/YYYY'
                    },
                    ranges: {
                        'Hoje': [moment(), moment()],
                        'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Há 7 dias': [moment().subtract(7, 'days'), moment()],
                        'Há 30 dias': [moment().subtract(30, 'days'), moment()],
                        'Este mês': [moment().startOf('month'), moment()],
                        'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    },
                    maxDate: $ctrl.maxDate ? moment() : false,
                    timePicker24Hour: true
                }

                if($ctrl.ngModel != null && Object.prototype.toString.call($ctrl.ngModel) === '[object Array]'){
                    if($ctrl.pickerSingle){
                        $ctrl.textValue = $ctrl.ngModel[0].format($ctrl.formatType);
                        
                        configPicker.startDate = $ctrl.ngModel[0].format('DD/MM/YYYY hh:mm:ss');
                    }else{
                        $ctrl.textValue = $ctrl.ngModel[0].format($ctrl.formatType) + ' - ' + $ctrl.ngModel[1].format($ctrl.formatType);
                    }
                }

                $($element[0]).daterangepicker(configPicker);

                $($element[0]).on('apply.daterangepicker', function (ev, picker) {
                    if(!$ctrl.pickerTime){
                        $ctrl.textValue = picker.startDate.format($ctrl.formatType) + ' - ' + picker.endDate.format($ctrl.formatType);
                    }else{
                        $ctrl.textValue = picker.startDate.format($ctrl.formatType);
                    }
                    
                    $ctrl.ngModel = [picker.startDate.format(), picker.endDate.format()]
                    $ctrl.pickerChange({value: $ctrl.ngModel});
                });
            }
        })
}());
