(function() {
    'use strict';

    angular
        .module('app')
        .component('listLink', {
            templateUrl: 'pessoas/component/listLink.template.html',
            bindings: {
                list: '<',
                callbackList: '&'
            },
            controller: function($http, RC, tokenNodejs, APINode, $routeParams, socket, $interval, CONST_FRONT) {
                const $ctrl = this;
                $ctrl.userData = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
                $ctrl.hrefOrigin = window.location.origin;
                $ctrl.loading = 'loading';
                $ctrl.showModalLink = false;
                let intervalSocket = null;
                $ctrl.test = [];
                $ctrl.access_negociacao = CONST_FRONT.negociacao;

                /*$ctrl.list = [{
                    "proplin_id": 426,
                    "proplin_titulo": "Proposta CRM Funil PRÓ",
                    "proplin_hash": "a341fb1f3d98e292529dad0c66250156",
                    "proplin_tempo_limite": "2019-06-10T15:00:00.000Z",
                    "proplin_data_criacao": "2019-05-27T20:14:27.000Z",
                    "proplin_data_alteracao": "2019-06-03T17:25:18.000Z",
                    "proplin_ativo": 1,
                    "proplin_mensagem_expiracao": "Infelizmente essa negociação já expirou :( Mas não se preocupe, entre em contato conosco para que possamos atualizar sua proposta.",
                    "proplin_download": 0
                }]*/

                $ctrl.$onChanges = () => {
                    if ($ctrl.list !== undefined) {
                        $ctrl.loading = 'loaded'

                        if ($ctrl.list.length) {
                            socket.emit('show who is seeing', $ctrl.list);
                            intervalSocket = $interval(() => {
                                socket.emit('show who is seeing', $ctrl.list);
                            }, 10000);
                        }
                    } else {
                        $ctrl.loading = 'loaded';
                    }
                }
                $ctrl.$onDestroy = () => {
                    $interval.cancel(intervalSocket);
                }

                $ctrl.copyLinkNegociacao = (element) => {
                    var $temp = $("<input>");
                    $("body").append($temp);
                    $temp.val($('#' + element).attr('href')).select();
                    document.execCommand("copy");
                    $temp.remove();
                }

                $ctrl.modalLinkCompromisso = function(param) {
                    if (param !== undefined && param.toString()) {
                        $ctrl.formLink = {
                            titulo: $ctrl.list[param].proplin_titulo,
                            tempo_limite: [moment($ctrl.list[param].proplin_tempo_limite), moment($ctrl.list[param].proplin_tempo_limite)],
                            ativo: $ctrl.list[param].proplin_ativo,
                            alteracao: true,
                            hash: $ctrl.list[param].proplin_hash,
                            msg_expirar: $ctrl.list[param].proplin_mensagem_expiracao,
                            download_arquivo: $ctrl.list[param].proplin_download
                        }
                    }

                    $ctrl.showModalLink = true;

                    var modalLink = $('#modalLinkCompromisso');
                    modalLink.modal({
                        backdrop: 'static',
                        keyboard: false,
                        show: true
                    });
                    if (modalLink.parent().get(0).tagName != 'BODY') {
                        $('.modal-backdrop').insertAfter(modalLink)
                    }
                }

                $('#modalLinkCompromisso').on('hidden.bs.modal', function() {
                    $ctrl.showModalLink = false;
                    $ctrl.formLink = {};
                    $ctrl.callbackList();
                });

                $ctrl.cadastrarLinkCompromisso = (data) => {
                    // let data = document.querySelector('form[name=formLink]');
                    console.log(data, 'qwoerhwqperh');

                    if (typeof data.tempo_limite === 'undefined' && data.tempo_limite !== '') {
                        swal({
                            title: 'Preencha o campo "expira às" com a data que você deseja.',
                            type: 'error',
                            timer: 3000
                        });
                        return;
                    }

                    var formData = new FormData();
                    angular.forEach(data, function(value, key) {
                        formData.append(key, value);
                    });
                    formData.append('file', document.getElementById('pdf-upload').files[0]);
                    formData.append('prop_id', $routeParams.slugNegocio);

                    let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));

                    $http.get(APINode.url + 'token/' + userAPI[0].saas_id).then((res) => {
                            let postUrl = '';
                            if ($ctrl.formLink.alteracao) {
                                postUrl = 'amazon/s3/proposta/atualizar/upload';
                            } else {
                                postUrl = 'amazon/s3/proposta/upload';
                            }

                            $http({
                                    url: APINode.url + postUrl,
                                    method: 'POST',
                                    data: formData,
                                    headers: {
                                        tokenFunil: res.data.data.api_token,
                                        'Content-Type': undefined
                                    },
                                    transformRequest: angular.identity,
                                    uploadEventHandlers: {
                                        progress: function(e) {
                                            if (e.lengthComputable) {
                                                $ctrl.valueProgress = e.loaded * 100 / e.total;
                                                $ctrl.blockButton = true;
                                            }
                                        }
                                    }
                                })
                                .then((result) => {
                                    console.log(result);
                                    $('#modalLinkCompromisso').modal('hide');
                                    $ctrl.callbackList();
                                    $ctrl.blockButton = false;
                                    $ctrl.valueProgress = 0;
                                })
                                .catch((err) => {
                                    $ctrl.blockButton = false;
                                    $ctrl.valueProgress = 0;
                                    $ctrl.showError = true;
                                })
                        })
                        .catch((err) => console.log(err));
                }

                socket.on('send who is seeing', (data) => {
                    let pushWhoIsSeeing = {};

                    for (let item of data) {
                        if (typeof pushWhoIsSeeing[item.hash] === 'undefined') {
                            pushWhoIsSeeing[item.hash] = []
                        }

                        if (item.history.length) {
                            const itemLength = item.history.length - 1;
                            const startDate = moment(item.history[0].firstAccess);
                            const endDate = moment();
                            const calcTime = moment.duration(endDate.diff(startDate));

                            let showHistory = {
                                actual_page: item.history[item.history.length - 1].id,
                                primeiro_acesso: item.history[0].firstAccess,
                                tempo_total: calcTime.asSeconds()
                            }

                            pushWhoIsSeeing[item.hash].push(showHistory);
                        }
                    }

                    for (let item of $ctrl.list) {
                        item.whoIsSeeing = [];
                    }

                    for (let hash of Object.keys(pushWhoIsSeeing)) {
                        const findIndex = function() {
                            for (let index in $ctrl.list) {
                                if ($ctrl.list[index].proplin_hash.contains(hash)) {
                                    return index;
                                } else {
                                    continue;
                                }
                            }
                        }

                        const indexItem = findIndex();

                        if (indexItem) {
                            if (pushWhoIsSeeing[hash].length) {
                                $ctrl.list[indexItem].whoIsSeeing = pushWhoIsSeeing[hash];
                            }
                        }
                    }
                });
            }
        });
}());