/*
 * @Author: Glauber Funez
 * @Date:   2018-03-19 09:59:14
 * @Last Modified by:   Glauber Funez
 * @Last Modified time: 2018-08-21 09:39:52
 */

(function() {
  'use strict';

  angular
    .module('negocios')
    .controller('enviarEmailController', enviarEmailController);

  enviarEmailController.$inject = ['$routeParams', '$location', 'negociosService', '$http', 'API', '$filter', '$timeout', '$q', 'orderByFilter', '$mdBottomSheet', 'loadSaas', 'validarSaas', '$window', 'FactoryRoles', '$rootScope', 'modelosEmailService', '$mdSelect', 'RC', 'FactoryLogEmail'];

  function enviarEmailController($routeParams, $location, negociosService, $http, API, $filter, $timeout, $q, orderBy, $mdBottomSheet, loadSaas, validarSaas, $window, FactoryRoles, $rootScope, modelosEmailService, $mdSelect, RC, FactoryLogEmail) {
    var vm = this;

    let userData = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));

    vm.enviarEmail = {
      'de_mostrar': '',
      'de': '',
      'para': [],
      'cc': [],
      'cco': [],
      'assunto': '',
      'modelo': 'vazio',
      'html': '',
      'user_id': ''
    }

    vm.limparEmail = function() {
      vm.enviarEmail.para = [];
      vm.enviarEmail.cc = [];
      vm.enviarEmail.cco = [];
      vm.enviarEmail.assunto = '';
      vm.enviarEmail.modelo = '';
      vm.enviarEmail.html = '';
      var editor = tinyMCE.get('corpoEmail');
      if (editor != null) {
        $timeout(function() {
          tinyMCE.activeEditor.setContent('');
        }, 500);
      }
    }

    $http.get(API.url + 'login/service.php?f=carregar_dados&hash=' + Cookies.get('crm_funil_hash')).then(function(response) {
      vm.enviarEmail.de_mostrar = response.data[0].user_nome + " <" + response.data[0].user_email + ">";
      vm.enviarEmail.de = response.data[0].user_email;
      vm.enviarEmail.de_nome = response.data[0].user_nome;
      vm.enviarEmail.user_id = response.data[0].user_id;
      console.log(response)
    }, function(error) {
      $log.error(error);
    });

    $('#textareaEmail').html('<textarea name="corpoEmail" id="corpoEmail"></textarea>');

    function loadTiny() {
      vm.carregandoTinyMCE = true;

      const loadTinyMce = () => tinymce.init({
        selector: '#corpoEmail',
        height: 400,
        language: 'pt_BR',
        statusbar: false,
        plugins: "code image imagetools colorpicker textcolor table preview emoticons link contextmenu responsivefilemanager",
        toolbar1: 'undo redo | fontselect fontsizeselect | bold italic underline forecolor backcolor | strikethrough inline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
        toolbar2: 'preview code responsivefilemanager link emoticons',
        resize: false,
        tooltip: true,
        paste_data_images: true,
        entity_encoding: 'raw',
        relative_urls: false,
        remove_script_host: false,
        external_filemanager_path: "/app/plugins/responsivefilemanager/filemanager/",
        filemanager_title: "Galeria",
        external_plugins: { "filemanager": "/app/plugins/responsivefilemanager/filemanager/plugin.min.js" },
        init_instance_callback: function(editor) {
          $timeout(function() {
            vm.carregandoTinyMCE = false;
            tinyMCE.activeEditor.setContent('');
            clearInterval(fnInterval)
          });
        }
      });

      const fnInterval = setInterval(() => {
        if (tinymce.editors.length > 0) {
          tinymce.remove("#corpoEmail");
          loadTinyMce()
        } else {
          loadTinyMce()
        }

      }, 1000);
    }

    loadTiny()

    vm.neg = {
      'id_neg': '',
      'responsavel': '',
      'cliente_empresa': ''
    }

    vm.getBuscarEmails = function(cliente_empresa) {
      var dados = {
        'id': cliente_empresa.id,
        'tipo': cliente_empresa.tipo
      };
      modelosEmailService.buscarEmails(dados).then(function(response) {
        /*console.log("buscarEmails");
        console.log(response.data);*/
        if (response.data != 0) {
          $timeout(function() {
            vm.emails = response.data;
            vm.emailsTodos = angular.copy(vm.emails);
            carregar();
          });
        } else {
          $timeout(function() {
            vm.emails = '';
            carregar();
          });
        }
      }, function(error) {
        $log.error(error);
      });
    }

    $rootScope.$on('modal_enviar_email', function(event, data) {
      vm.neg.id_neg = data.id;
      vm.neg.nome = data.cliente_empresa.nome;
      vm.neg.responsavel = data.responsavel;
      vm.neg.cliente_empresa = data.cliente_empresa;
      vm.getBuscarEmails(data.cliente_empresa);
      console.log(data.cliente_empresa)
      vm.limparEmail();
      vm.ativarCc = false;
      vm.ativarCco = false;
    });


    var self = this;

    function carregar() {
      self.states = loadAll();
      self.selectedItem = '';
      self.selectedItem2 = '';
      self.selectedItem3 = '';
      self.searchText = '';
      self.searchText2 = '';
      self.searchText3 = '';
      self.querySearch = querySearch;
      self.selectedItemChange = selectedItemChange;
      self.transformChip = transformChip;
      self.removeChip = removeChip;

      self.emailsPara = [];
      self.emailsCc = [];
      self.emailsCco = [];
    }

    vm.emailAdicionados = [];

    function transformChip(qual, chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        $timeout(function() {
          if (qual == 1) { // PARA
            vm.enviarEmail.para = angular.copy(self.emailsPara);
            vm.validar.para = false;
            vm.emailAdicionados.push(chip.email);
          } else if (qual == 2) { // Cc
            vm.enviarEmail.cc = angular.copy(self.emailsCc);
            vm.emailAdicionados.push(chip.email);
          } else if (qual == 3) { // Cco
            vm.enviarEmail.cco = angular.copy(self.emailsCco);
            vm.emailAdicionados.push(chip.email);
          }
        });

        // REMOVER E-MAIL ADICIONADO DA LISTA
        vm.emails = vm.emails.filter(function(e) {
          return e.email !== chip.email
        })
        return chip;
      }

      // Otherwise, create a new one
      return false;
    }

    function removeChip(qual, chip) {
      if (qual == 1) { // PARA
        var index = '';
        angular.forEach(vm.enviarEmail.para, function(value, key) {
          if (value.email == chip.email) {
            index = key;
          }
        });
        vm.enviarEmail.para.splice(index, 1);
      } else if (qual == 2) { // Cc
        var index = '';
        angular.forEach(vm.enviarEmail.cc, function(value, key) {
          if (value.email == chip.email) {
            index = key;
          }
        });
        vm.enviarEmail.cc.splice(index, 1);
      } else if (qual == 3) { // Cco
        var index = '';
        angular.forEach(vm.enviarEmail.cco, function(value, key) {
          if (value.email == chip.email) {
            index = key;
          }
        });
        vm.enviarEmail.cco.splice(index, 1);
      }

      vm.emailAdicionados = vm.emailAdicionados.filter(function(e) {
        return e !== chip.email
      })

      vm.emails = angular.copy(vm.emailsTodos);
      angular.forEach(vm.emailAdicionados, function(value, key) {
        vm.emails = vm.emails.filter(function(e) {
          return e.email !== value
        })
      })

    }

    function selectedItemChange(item) {
      if (item) {
        /*vm.enviarEmail.para = [];
        vm.enviarEmail.para.push(item);
        vm.validar.para = false;*/
      } else {
        //vm.enviarEmail.para = [];
      }
    }

    function querySearch(query) {
      var results = query ? self.states.filter(createFilterFor(query)) : self.states,
        deferred;
      return results;
    }

    function loadAll() {
      self.states = vm.emails;
      var emails = vm.emails;
      return angular.forEach(emails, function(values, key) {
        return {
          value: values.id_email,
          display: values.email
        };
      });
    }

    function createFilterFor(query) {
      var lowercaseQuery = angular.lowercase(query);
      return function filterFn(state) {
        //return (state.email.toLowerCase().indexOf(lowercaseQuery) == 0) || (state.prod_cl_tipo_nome.toLowerCase().indexOf(lowercaseQuery) == 0);
        return (state.email.toLowerCase().indexOf(lowercaseQuery) == 0);
      };
    }

    vm.buscarModelosPadrao = function() {
      modelosEmailService.buscarModelosPadrao().then(function(response) {
        /*console.log("ModelosPadrao");
        console.log(response.data);*/
        if (response.data != 0) {
          $timeout(function() {
            vm.modelosPadrao = [];
            angular.forEach(response.data, function(value, key) {
              vm.modelosPadrao.push({
                'id': value.id,
                'nome': value.nome,
                'tipo': 'padrao',
                'data_alteracao': value.data_alteracao,
                'data_criacao': value.data_criacao,
                'html': value.html,
              });
            });
          });
        } else {
          $timeout(function() {
            vm.modelosPadrao = '';
          });
        }
      }, function(error) {
        $log.error(error);
      });
    };
    vm.buscarModelosPadrao();

    vm.buscarModelosPersonalizados = function() {
      vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
      vm.items.then(function(items) { // ler array de retorno
        vm.saasid = items;
        var dados = {
          'saasid': vm.saasid
        };
        modelosEmailService.buscarModelosPersonalizados(dados).then(function(response) {
          /*console.log("ModelosPersonalizados");
          console.log(response.data);*/
          if (response.data != 0) {
            $timeout(function() {
              vm.modelosPersonalizados = [];
              angular.forEach(response.data, function(value, key) {
                vm.modelosPersonalizados.push({
                  'id': value.id,
                  'nome': value.nome,
                  'tipo': 'personalizado',
                  'data_alteracao': value.data_alteracao,
                  'data_criacao': value.data_criacao,
                  'html': value.html,
                });
              });
            });
          } else {
            $timeout(function() {
              vm.modelosPersonalizados = '';
            });
          }
        }, function(error) {
          $log.error(error);
        });
      });
    };
    vm.buscarModelosPersonalizados();

    vm.alterarModelo = function(modelo) {
      vm.carregandoTinyMCE = true;
      if (modelo.id) {
        var dados = {
          'id_modelo': modelo.id,
          'tipo_modelo': modelo.tipo,
          //'id_PE': vm.enviarEmail.para[0].id,
          'id_PE': vm.neg.cliente_empresa.id,
          'tipo_PE': vm.enviarEmail.para[0].tipo_PE,
          'id_neg': vm.neg.id_neg,
          'user_id': vm.enviarEmail.user_id,
        };
        modelosEmailService.preencherModelo(dados).then(function(response) {
          /*console.log("preencherModelo");
          console.log(response.data);*/
          if (response.data != 0) {
            $timeout(function() {
              tinyMCE.activeEditor.setContent(response.data);
              vm.carregandoTinyMCE = false;
            });
          } else {
            $timeout(function() {
              vm.carregandoTinyMCE = false;
            });
          }
        }, function(error) {
          $log.error(error);
        });
      } else {
        tinyMCE.activeEditor.setContent('');
        vm.carregandoTinyMCE = false;
      }
    }

    vm.preview = function() {
      tinyMCE.activeEditor.execCommand('mcePreview', true);
    }

    vm.enviandoEmail = false;
    vm.validar = {
      'para': false,
      'assunto': false,
      'html': false,
    }
    vm.enviarEmailProposta = function() {
      vm.enviandoEmail = true;
      var erro = 0;
      if (!vm.enviarEmail.para || vm.enviarEmail.para.length == 0) {
        erro = 1;
        vm.validar.para = true;
        vm.enviandoEmail = false;
        return null;
      }

      if (!vm.enviarEmail.assunto) {
        erro = 1;
        vm.validar.assunto = true;
        vm.enviandoEmail = false;
        return null;
      }

      var html = tinyMCE.get('corpoEmail').getContent();
      if (html == '') {
        erro = 1;
        vm.validar.html = true;
        vm.enviandoEmail = false;
        return null;
      } else {
        vm.validar.html = false;
      }

      if (erro == 1) {
        vm.enviandoEmail = false;
        return null;
      }

      if (erro == 0) {
        var dados = {
          'de': vm.enviarEmail.de,
          'de_nome': vm.enviarEmail.de_nome,
          'para': vm.enviarEmail.para,
          'cc': vm.enviarEmail.cc,
          'cco': vm.enviarEmail.cco,
          'assunto': vm.enviarEmail.assunto,
          'html': html,
          'prop_id': vm.neg.id_neg,
          'user_id': vm.enviarEmail.user_id,
          'saas_id': vm.saasid,
          'user_email': userData[0].user_email
        }
        let defer = $q.defer();
        $timeout(() => {
          defer.resolve('reason');
        }, 30000)


        // inicio - grava no bd mongo
        var dadosLogEmail = {
          email_neg_id: vm.neg.id_neg,
          email_cliente_id: vm.neg.cliente_empresa.id,
          email_conteudo_html: html,
          email_assunto : vm.enviarEmail.assunto          
        }

        dadosLogEmail.email_destinatario = "";
        for (var i = 0; i < vm.enviarEmail.para.length; i++) {
          dadosLogEmail.email_destinatario = dadosLogEmail.email_destinatario ? dadosLogEmail.email_destinatario + '; ' + vm.enviarEmail.para[i].email : vm.enviarEmail.para[i].email
        }

        dadosLogEmail.email_cc = "";
        for (var icc = 0; icc < vm.enviarEmail.cc.length; icc++) {
          dadosLogEmail.email_cc = dadosLogEmail.email_cc ? dadosLogEmail.email_cc + '; ' + vm.enviarEmail.cc[icc].email : vm.enviarEmail.cc[icc].email;
        }

        dadosLogEmail.email_cco = "";
        for (var icco = 0; icco < vm.enviarEmail.cco.length; icco++) {
          dadosLogEmail.email_cco =dadosLogEmail.email_cco ? dadosLogEmail.email_cco + '; ' + vm.enviarEmail.cco[icco].email : vm.enviarEmail.cco[icco].email;
        }

        console.log('dados a serem enviados', dados);

        FactoryLogEmail.salvaLog(dadosLogEmail).then(function(a) {
          console.log('reeeeeeees *salvaLog*', a.data.data)
          if (a.data.data) {
            // "idEmail": a.data.data.response._id
            dados.idEmail = a.data.data.response._id;

            modelosEmailService.enviarEmail(dados, defer.promise)
              .then(function(response) {
                console.log("response *enviarEmail*", response);
                vm.enviandoEmail = false;
                swal({
                  title: 'Enviado!',
                  text: 'E-mail enviado com sucesso!',
                  type: 'success',
                  showConfirmButton: false,
                  timer: 2000
                });

                $timeout(function() {
                  $('#modalEnviarEmail').modal("hide");
                  vm.limparEmail();
                  vm.enviandoEmail = false;
                  var dados = {
                    "id": vm.neg.id_neg,
                    "idEmail": a.data.data.response._id
                  }
                  $rootScope.$broadcast('atualizar_historico', dados);
                }, 2000);

              })
              .catch((err) => {
                vm.enviandoEmail = false;
                console.error(err)
                swal({
                  title: '',
                  //text: 'Ocorreu um erro ao enviar, verifique as configurações no painel de integrações!',
                  text: 'Ocorreu um erro ao enviar, se o error persistir entre em contato através do chat ou por e-mail!',
                  type: 'error',
                  showConfirmButton: true
                });
              });

          }
        })



      }
    }

    /* CÓDIGO UTILIZADO PARA O TINYMCE FUNCIONAR CORRETAMENTE NA MODAL DO BOOTSTRAP --- INICIO*/
    // $(document).on('focusin', function(e) {
    //     if ($(event.target).closest(".mce-window").length) {
    //         e.stopImmediatePropagation();
    //     }
    // });
    /* CÓDIGO UTILIZADO PARA O TINYMCE FUNCIONAR CORRETAMENTE NA MODAL DO BOOTSTRAP --- FIM*/

    vm.mostrarCc = function() {
      vm.ativarCc = true;
    }
    vm.mostrarCco = function() {
      vm.ativarCco = true;
    }
  }

})();
