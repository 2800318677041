(function() {
    'use strict';

    angular
        .module('app')
        .component('headerPessoaEmpresa', {
            templateUrl: 'app/components/headerPessoaEmpresa/headerPessoaEmpresa.template.html',
            bindings: {
                dados: '=',
                local: '=',
                callbackAtd: '&'
            },
            controller: function($element, $filter, $timeout, FactoryTotalVoice, API, $http){

                const $ctrl = this;

                $ctrl.cliente = $ctrl.dados;

                if ($ctrl.cliente.tipo == 'p'){
                    $ctrl.qual = "pessoas";
                } else {
                    $ctrl.qual = "empresas";
                }

                $timeout(function() {
                    $('.button_call').tooltip({placement: 'bottom'});
                });

                $ctrl.ligar = function(cliente, qual){
                    FactoryTotalVoice.modalLigando(cliente, qual, $ctrl.callbackAtd);
                }

                $ctrl.buscarSaldoConta = function(){
                    var dados = {
                        'user_hash': Cookies.get('crm_funil_hash')
                    }
                    $http.post(API.url + 'ligacao/service.php?f=buscarSaldoConta', dados).then(function(response) {
                        if (response.data.saldo && response.data.saldo != null){ // POSSUI SALDO, CONTA EXISTENTE
                                $ctrl.buscarRamalUsuario();
                        } else { // NÃO POSSUI SALDO, CONTA INEXISTENTE
                            $timeout(function() {
                                $('.box_button_call').addClass('disable_a2');
                                $ctrl.semIntegracao = true;
                                $('.box_button_call').tooltipster({
                                    interactive: true,
                                    trigger: 'hover',
                                    maxWidth: 250,
                                    minWidth: 250,
                                    position: 'right',
                                    theme: 'tooltipster-light',
                                    delay: 100
                                });
                            });
                        }
                    }, function(error) {
                        console.error(error);
                    });
                }
                $ctrl.buscarSaldoConta();

                $ctrl.buscarRamalUsuario = function(){
                    var dados = {
                        'user_hash': Cookies.get('crm_funil_hash')
                    }
                    $http.post(API.url + 'ligacao/service.php?f=buscarRamalUsuario', dados).then(function(response) {
                        if (response.data == ''){
                            $timeout(function() {
                                $('.dropdown_telefones').addClass('disable_a2');
                                $ctrl.semRamal = true;
                                $('.box_button_call').tooltipster({
                                    interactive: true,
                                    trigger: 'hover',
                                    maxWidth: 250,
                                    minWidth: 250,
                                    position: 'right',
                                    theme: 'tooltipster-light',
                                    delay: 100
                                });
                            });
                        } else {
                            if (!Cookies.get('webPhoneConectado')){
                                $(".box_button_call").addClass('disable_a2');
                                $('.box_button_call').tooltip({title:"Conectando...", placement: 'bottom'});
                            }
                        }
                    }, function(error) {
                        console.error(error);
                    });
                }
            }
        })
}());
