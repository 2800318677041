(function() {
    'use strict';

    angular
        .module('app')
        .component('anexosNegComp', {
            templateUrl: 'app/components/anexosNegComp/anexosNegComp.template.html',
            bindings: {
                dados: '=',
                local: '=',
                callbackAtd: '&'
            },
            controller: function($element, $filter, $timeout, API, $http, $window, RC){

                const $ctrl = this;

                $ctrl.userData = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));

                /*console.log("$ctrl.dados");
                console.log($ctrl.dados);*/

                $ctrl.baseurl = API.url;

                $ctrl.getFiles = function(){
                    $ctrl.filesUploaded = [];
                    $ctrl.loadingFilesUploaded = true;
                    var dados = {
                        'user_hash': Cookies.get('crm_funil_hash'),
                        'local': $ctrl.local,
                        'id': $ctrl.dados.id,
                        'tipo': $ctrl.dados.cliente_empresa.tipo,
                        'cliente_empresa': $ctrl.dados.cliente_empresa.id,
                    }
                    $http.post(API.url + 'app/components/anexosNegComp/service.php?f=getFiles', dados).then(function(response) {
                        console.log("getFiles");
                        console.log(response.data);
                        //return null;
                        if (response.data.length && response.data != 'null'){
                            $timeout(function() {
                                $ctrl.filesUploaded = response.data;
                            });
                        } else {
                            $ctrl.filesUploaded = [];
                            $ctrl.loadingFilesUploaded = false;
                        }
                    }, function(error) {
                        console.log("error");
                        console.error(error);
                    });
                }

                $ctrl.acabouFiles = function(){
                    $timeout(function() {
                        $ctrl.loadingFilesUploaded = false;
                        $('[data-toggle="tooltip"]').tooltip();
                    });
                }

                $ctrl.selectFiles = function(){
                    $('#files-upload').change(function(){
                        $ctrl.uploadFiles();
                    });
                }

                $ctrl.clickSelectFiles = function(){
                    $('#files-upload').val('');
                    $('#files-upload').trigger('click');
                }

                $ctrl.uploadFiles = function(){
                    $(".files_uploading").fadeIn();
                    const files = document.getElementById('files-upload').files;
                    const formData = new FormData();
                    for (let i = 0; i < files.length; i++) {
                        let file = files[i]
                        formData.append('files[]', file);
                    }
                    
                    formData.append('user_hash', Cookies.get('crm_funil_hash'));
                    formData.append('local', $ctrl.local);
                    formData.append('id', $ctrl.dados.id);
                    formData.append('tipo', $ctrl.dados.cliente_empresa.tipo);
                    formData.append('cliente_empresa', $ctrl.dados.cliente_empresa.id);

                    $http({
                            url: API.url + 'app/components/anexosNegComp/service.php?f=insertFiles',
                            method: 'POST',
                            data: formData,
                            headers: {
                                'Content-Type': undefined
                            },
                            transformRequest: angular.identity,
                        })
                        .then((result) => {
                            console.log("uploadFiles PHP");
                            console.log(result.data);
                            $(".files_uploading").fadeOut();
                            $ctrl.getFiles();
                            $timeout(function() {
                                $("#files-upload").val('');
                            }, 100);
                        })  
                        .catch((err) => {
                            console.log("error");
                            console.error(error);
                        })
                }

                $ctrl.removeFile = function(file){
                    swal({
                        title: 'Deseja remover este arquivo?',
                        text: 'Esta ação é irreversível',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        closeOnConfirm: false,
                        showLoaderOnConfirm: true
                    },
                    function() {
                        $window.onkeydown = null;
                        $window.onfocus = null;
                        var dados = {
                            'user_hash': Cookies.get('crm_funil_hash'),
                            'local': $ctrl.local,
                            'id': $ctrl.dados.id,
                            'tipo': $ctrl.dados.cliente_empresa.tipo,
                            'cliente_empresa': $ctrl.dados.cliente_empresa.id,
                            'file': file,
                        }
                        $http.post(API.url + 'app/components/anexosNegComp/service.php?f=removeFile', dados).then(function(response) {
                            /*console.log("removeFile");
                            console.log(response.data);*/
                            //return null;
                            swal({
                                title: 'Removido com sucesso!',
                                type: 'success',
                                text: 'Arquivo removido com sucesso!',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            $ctrl.getFiles();
                        }, function(error) {
                            console.log("error");
                            console.error(error);
                        });
                    });
                }

                $ctrl.saveDescription = function(file, index){
                    var descricao = '';
                    descricao = $("#description_img_"+index).val();
                    console.log(descricao);
                    $("#description_load_"+index).fadeIn();
                    $(".description_loading_"+index).show();
                    var dados = {
                        'saas_id': $ctrl.userData[0].saas_id,
                        'user_hash': Cookies.get('crm_funil_hash'),
                        'local': $ctrl.local,
                        'id': $ctrl.dados.id,
                        'tipo': $ctrl.dados.cliente_empresa.tipo,
                        'file': file,
                        'descricao': descricao,
                    }
                    $http.post(API.url + 'app/components/anexosNegComp/service.php?f=saveDescription', dados).then(function(response) {
                        console.log("saveDescription");
                        console.log(response.data);
                        $timeout(function() {
                            $(".description_loading_"+index).hide();
                            $(".description_check_"+index).fadeIn();
                            $timeout(function() {
                                $("#description_load_"+index).fadeOut();
                                $(".description_check_"+index).hide();
                            }, 1000);
                        }, 500);
                    }, function(error) {
                        console.log("error");
                        console.error(error);
                    });
                }

                $ctrl.previewAnexo = function(f){
                    $ctrl.preview = [];
                    $ctrl.preview.img = f.url;
                    $ctrl.preview.name = f.name;

                    $('[data-toggle="tooltip"]').tooltip('hide');

                    var modalPreview = $("#modalPreviewImg");
                    modalPreview.modal('show');
                    if(modalPreview.parent().get(0).tagName != 'BODY'){
                        $('.modal-backdrop').insertAfter(modalPreview);
                    }
                }
                
            }
        })
}());
