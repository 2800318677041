/*
* @Author: Glauber Funez
* @Date:   2018-03-26 17:51:35
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-04-30 15:35:59
*/
angular.module('percentage', [])
    .filter("percentage", function () {
        // return function (value, total) {
        //     total = total || 1;
        //     return (isNaN(value) || isNaN(total)) ? '' : value/total*100;
        // };
        // glauber
        return function (value) {
        	return value*100;
        }
                   
       
});
