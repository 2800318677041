(function () {
    'use strict';

    angular
        .module('cobranca')
        .controller('cobrancaController', cobrancaController);

    cobrancaController.$inject = ['$routeParams', '$scope', 'cobrancaService', '$window', '$http', '$timeout'];

    function cobrancaController($routeParams, $scope, cobrancaService, $window, $http, $timeout) {
        var vm = this;

        if ($routeParams.hash_cobranca){
            vm.hash_cobranca = $routeParams.hash_cobranca;
            
            vm.form = {
                "tipo": "cc",
            };
            vm.cobranca = '';
            vm.loading = true;
            vm.loadingSave = false;
            vm.cpfCnpjInvalido = false;
            vm.data_expirada = false;
            vm.erroHash = false;
            vm.cepInvalido = false;

            vm.buscarDadosNeg = function() {
                vm.loading = true;
                var dados = {
                    'hash': $routeParams.hash_cobranca
                };
                cobrancaService.buscarDadosNeg(dados).then(function (response) {
                    if (response.data.prop_id){
                        vm.dados = response.data;
                        vm.form.tipo = response.data.cobranca_tipo;
                        if (vm.dados.cobranca_vencimento < moment().format('YYYY-MM-DD') && vm.dados.cobranca_id == ''){
                            vm.data_expirada = true;
                        }
                        if (response.data.cobranca_parcelas != 0){
                            vm.form.parcelas = response.data.cobranca_parcelas;
                        }
                    } else {
                        vm.erroHash = true;
                    }
                    vm.loading = false;
                }, function(error) {
                    console.log(error);
                });
            }
            vm.buscarDadosNeg();

            vm.testaCpf = function(num) {
                var cpf = num.replaceAll('.', '').replaceAll('-', '');
                var i;
                var s = cpf;
                var c = s.substr(0, 9);
                var dv = s.substr(9, 2);
                var d1 = 0;
                var v = false;
    
                for (i = 0; i < 9; i++) {
                    d1 += c.charAt(i) * (10 - i);
                }
                if (d1 == 0) {
                    v = true;
                    return false;
                }
                d1 = 11 - (d1 % 11);
                if (d1 > 9) d1 = 0;
                if (dv.charAt(0) != d1) {
                    v = true;
                    return false;
                }
    
                d1 *= 2;
                for (i = 0; i < 9; i++) {
                    d1 += c.charAt(i) * (11 - i);
                }
                d1 = 11 - (d1 % 11);
                if (d1 > 9) d1 = 0;
                if (dv.charAt(1) != d1) {
                    v = true;
                    return false;
                }
                if (cpf == "00000000000" ||
                    cpf == "11111111111" ||
                    cpf == "22222222222" ||
                    cpf == "33333333333" ||
                    cpf == "44444444444" ||
                    cpf == "55555555555" ||
                    cpf == "66666666666" ||
                    cpf == "77777777777" ||
                    cpf == "88888888888" ||
                    cpf == "99999999999") {
                    return false;
                }
    
    
                if (!v) {
                    return true;
                }
            }

            vm.testaCnpj = function(cpfcnpj) {
                var cnpj = cpfcnpj;
                cnpj = cnpj.replace(/[^\d]+/g, '');
    
                if (cnpj == '') return false;
    
                if (cnpj.length != 14)
                    return false;
    
                // Elimina CNPJs invalidos conhecidos
                if (cnpj == "00000000000000" ||
                    cnpj == "11111111111111" ||
                    cnpj == "22222222222222" ||
                    cnpj == "33333333333333" ||
                    cnpj == "44444444444444" ||
                    cnpj == "55555555555555" ||
                    cnpj == "66666666666666" ||
                    cnpj == "77777777777777" ||
                    cnpj == "88888888888888" ||
                    cnpj == "99999999999999")
                    return false;
    
                // Valida DVs
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0, tamanho);
                var digitos = cnpj.substring(tamanho);
                var soma = 0;
                var pos = tamanho - 7;
                for (var i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2)
                        pos = 9;
                }
                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(0))
                    return false;
    
                tamanho = tamanho + 1;
                numeros = cnpj.substring(0, tamanho);
                soma = 0;
                pos = tamanho - 7;
                for (i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2)
                        pos = 9;
                }
                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(1))
                    return false;
    
                return true;
    
            }

            vm.mascaraCpfCnpj = function() {
                setTimeout(function() {
                    $(".cpfcnpj").inputmask({
                        mask: ['999.999.999-99', '99.999.999/9999-99'],
                        placeholder: ' ',
                        keepStatic: true,
                        showMaskOnFocus: false,
                        showMaskOnHover: false
                    });
                }, 100);
            }

            vm.changeType = function() {
                $scope.paymentForm.$setPristine();
                $scope.paymentForm.$setUntouched();
            }

            vm.buscaCep = function() {
                if (vm.form.cep && vm.form.cep.length == 9) {
                    var cep = vm.form.cep;
                    vm.loadingCep = true;
                    $http({
                        method: 'GET',
                        url: 'https://viacep.com.br/ws/'+ cep + '/json'
                    }).then(function successCallback(response) {
                        if (response.data.erro){
                            vm.cepInvalido = true;
                        } else {
                            vm.cepInvalido = false;
                        }
                        vm.loadingCep = false;
                    }, function errorCallback(response) {
                        vm.loadingCep = false;
                    });
                }
            };

            vm.savePayment = function() {
                // console.log("vm.form");
                // console.log(vm.form);
                if ($scope.paymentForm.$valid){
                    if (vm.form.tipo == 'cc'){
                        if (vm.form.cpfcnpj.length == 14) {
                            var validar = vm.testaCpf(vm.form.cpfcnpj);
                        } else {
                            var validar = vm.testaCnpj(vm.form.cpfcnpj);
                        }
                        if (validar){
                            vm.cpfCnpjInvalido = false;
                        } else {
                            vm.cpfCnpjInvalido = true;
                            vm.loadingSave = false;
                            return null;
                        }
                        if (vm.cepInvalido){
                            vm.loadingSave = false;
                            return null;
                        }
                    }
                    swal({
                        title: 'Deseja realmente confirmar o pagamento?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#20ff76',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        closeOnConfirm: false
                      },
                      function() {
                        $window.onkeydown = null;
                        $window.onfocus = null;
                        swal.close();
                        vm.loadingSave = true;
                        var dados = {
                            'dados': vm.dados,
                            'pagamento': vm.form,
                        };
                        cobrancaService.gerarPagamento(dados).then(function (response) {
                            // console.log("gerarPagamento");
                            // console.log(response.data);
                            if (response.data.error){
                                swal({
                                    title: 'Atenção!',
                                    type: 'error',
                                    text: response.data.error,
                                    showConfirmButton: true
                                });
                            } else {
                                // swal.close();
                                vm.cobranca = response.data;
                                vm.form = {
                                    "tipo": "cc",
                                };
                                $scope.paymentForm.$setPristine();
                                $scope.paymentForm.$setUntouched();
                                vm.dados.cobranca_id = response.data.cobranca_id;
                                vm.dados.cobranca_tipo = response.data.cobranca_tipo;
                                vm.dados.cobranca_status = response.data.cobranca_status;
                                vm.dados.cobranca_link = response.data.cobranca_link;
                                vm.dados.cobranca_pix_qrcode = response.data.cobranca_pix_qrcode;
                                vm.dados.cobranca_pix_codigo = response.data.cobranca_pix_codigo;
                                swal({
                                    title: 'Sucesso!',
                                    type: 'success',
                                    text: "Cobrança gerada com sucesso",
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            }
                            vm.loadingSave = false;
                        }, function(error) {
                            console.log(error);
                        });
                    });
                } else {
                    vm.loadingSave = false;
                }
            }

            vm.copiar = function() {
                var copyText = document.querySelector('#chave_pix');
                copyText.select();
                // var successful = document.execCommand('copy');
                // copyText.setSelectionRange(0, 99999); /* For mobile devices */
                navigator.clipboard.writeText(copyText.value);
                $('.icon_check_pix').fadeIn('fast');
                $timeout(function() {
                $('.icon_check_pix').fadeOut('fast');
                }, 1000);
            };

        }
        
    }
})();