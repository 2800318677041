/*
 * @Author: Glauber Funez
 * @Date:   2018-05-29 10:27:18
 * @Last Modified by:   Glauber Funez
 * @Last Modified time: 2018-05-29 11:55:09
 */

(function() {
  'use strict';

  angular
    .module('app')
    .factory('FactoryLogEmail', FactoryLogEmail);


  function FactoryLogEmail($http, $q, $location, RC, $routeParams, API, $window, $timeout, $log, APINode) {

    var vm = this;

    var userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));

    // return function ⤵
    return {
      salvaLog: salvaLog,
      getEmail: getEmail,
      AllEmailCliente : AllEmailCliente
    };

    // set function ⤵
    function salvaLog(dadosEmail) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      console.log("urlEndpoint");
      console.log(urlEndpoint);

      console.log("APINode.url");
      console.log(APINode.url);

      var deferred = $q.defer();

      $http.get(urlEndpoint).then(function(res) {
        
        console.log("res");
        console.log(res);

        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;

          if (dadosEmail) {

            $http.post(APINode.url + 'email-logs/novo', dadosEmail, {
                headers: {
                  "TokenFunil": staticTokenFunil
                }
              })
              .then(function(responseG) {
                console.log('FactoryLogEmail ==>', responseG);
                deferred.resolve(responseG);
                // verifica se existe evento cadastrado

              })
          }
        }
      });

      return deferred.promise;
    }


    function getEmail(idEmail) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      var deferred = $q.defer();

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {
          var staticTokenFunil = res.data.data.api_token;

          if (idEmail) {
            $http.get(APINode.url + 'email-logs/email/' + idEmail, {
                headers: {
                  "TokenFunil": staticTokenFunil
                }
              })
              .then(function(responseG) {
                // console.log('FactoryLogEmail ==>', responseG);
                deferred.resolve(responseG);
                // verifica se existe evento cadastrado

              })
          }
        }
      });

      return deferred.promise;
    }



    function AllEmailCliente(idCliente) {

      var BaseApi = APINode.url + 'token/';
      var localSaas = userAPI['0'].saas_id;
      var urlEndpoint = BaseApi + localSaas;

      var deferred = $q.defer();

      $http.get(urlEndpoint).then(function(res) {
        if (res.data.data) {

          var staticTokenFunil = res.data.data.api_token;

          if (idCliente) {
            $http.get(APINode.url + 'email-logs/cliente/' + idCliente, {
                headers: {
                  "TokenFunil": staticTokenFunil
                }
              })
              .then(function(responseG) {
                // console.log('FactoryLogEmail ==>', responseG);
                deferred.resolve(responseG);
                // verifica se existe evento cadastrado

              })
          }
        }
      });
      return deferred.promise;
    }



  }
}());
