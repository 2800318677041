/*
* @Author: Glauber Funez
* @Date:   2018-03-19 09:57:29
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:52
*/

(function() {
    'use strict';

    angular
        .module('negocios')
        .controller('boxDetalhesCtrl', boxDetalhesCtrl)

    boxDetalhesCtrl.$inject = ['$routeParams', 'id', 'mensagem', '$location', 'negociosService', '$http', 'API', '$filter', '$timeout', '$q', 'orderByFilter', '$mdBottomSheet', 'loadSaas', 'validarSaas', 'produtosService', '$window', 'FactoryRoles', '$log', 'FactoryWebhook', 'FactoryModalMotivo'];

    
    function boxDetalhesCtrl($routeParams, id, mensagem, $location, negociosService, $http, API, $filter, $timeout, $q, orderBy, $mdBottomSheet, loadSaas, validarSaas, produtosService, $window, FactoryRoles, $log, FactoryWebhook, FactoryModalMotivo) {
        var vm = this;

        vm.alterado = false;
        $(function() {
            $timeout(function() {
                $('[data-toggle="tooltip"]').tooltip();
            }, 100);
        });

        vm.tooltipsterNegDESC = function() {
            $timeout(function() {
                if (vm.mobile) {
                    var acao = 'click';
                    var posicao = 'left';
                } else {
                    var acao = 'hover';
                    var posicao = 'left';
                }
                $('.tooltipster_neg_desc').tooltipster({
                    interactive: true,
                    // trigger: acao,
                    maxWidth: 100,
                    minWidth: 100,
                    position: posicao,
                    theme: 'tooltipster-light',
                    delay: 100,
                });
            });
        };

        vm.acabou = function() {
            vm.tooltipsterNegDESC();
        }


        vm.carregarDetalhes = function() {
            var dados = {
                'id': id
            };
            negociosService.carregarDetalhesNegocio(dados).then(function(response) {
                console.log(response)
                if (!response.data["0"].descontos) {
                    response.data["0"].descontos = [];
                }
                vm.negocio = response.data;
                vm.buscarProdutosProposta(response.data[0].prop_slug);
                vm.preencherResponsavel(response.data[0]);
                vm.verifMostrarBtnEditar();
            }, function(error) {
                $log.error(error);
            });
        };
        vm.carregarDetalhes();

        vm.preencherResponsavel = function(dados) {
            var responsavel = [];
            if (dados.user_nome != null) {
                var sigla = dados.user_nome.substr(0, 1);
            } else {
                var sigla = '';
            }
            if (dados.user_avatar) {
                responsavel.push({
                    'icon': API.url + '/usuarios/avatar/' + dados.user_avatar,
                    'id': dados.user_id,
                    'name': dados.user_nome,
                    'ticked': true,
                    'slug': dados.user_slug,
                    'sigla': sigla,
                    'ativo': dados.user_ativo
                });
            } else {
                responsavel.push({
                    'icon': null,
                    'id': dados.user_id,
                    'name': dados.user_nome,
                    'ticked': true,
                    'slug': dados.user_slug,
                    'sigla': sigla,
                    'ativo': dados.user_ativo
                });
            }
            vm.responsavel = responsavel;
        };

        vm.integrarCompufacil = function(id) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'id_prop': id,
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                negociosService.integrarCompufacil(dados).then(function(response) {
                    /*console.log("integrarCompufacil");
                    console.log(response.data);*/
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.situacaoCompufacil = function(id) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                var dados = {
                    'saasid': vm.saasid,
                    'id_prop': id,
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                negociosService.situacaoCompufacil(dados).then(function(response) {}, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.descartarLeadExactSales = function(id) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'id_prop': id
                }
                negociosService.descartarLeadExactSales(dados).then(function(response) {
                    /*console.log("descartarLeadExactSales");
                    console.log(response.data);*/
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.venderLeadExactSales = function(id) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                var dados = {
                    'saasid': vm.saasid,
                    'id_prop': id
                }
                negociosService.venderLeadExactSales(dados).then(function(response) {
                    /*console.log("venderLeadExactSales");
                    console.log(response.data);*/
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        /*
        // FUNÇÃO ANTIGA - SEM MOTIVO DE PERDA
        vm.marcar_situacao = function(qual, id) {

            FactoryWebhook.triggerSit(id);

            var dados = {
                'qual': qual,
                'id': id,
                'user_hash': Cookies.get('crm_funil_hash')
            };
            $('.spinner_' + id).toggleClass('hide'); // aparecer loading
            $('.ganhou_' + id).toggleClass('hide'); // ocultar icone ganhou
            $('.perdeu_' + id).toggleClass('hide'); // ocultar icone perdeu
            vm.removerTooltipAcao(id);
            if (qual == 1) { // clicou em ganhou
                negociosService.marcarSituacao(dados).then(function(response) {
                    vm.alterado = true;
                    //$(".ganhou_" + id).toggleClass("botao_ganhar_marcado"); // faz a troca da classe marcado no icone 'ganhou'
                    //$(".perdeu_" + id).removeClass("botao_perder_marcado"); // remove a classe marcado do icone 'perdeu' caso esteja marcado
                    $('.spinner_' + id).toggleClass('hide');
                    $('.ganhou_' + id).toggleClass('hide');
                    $('.perdeu_' + id).toggleClass('hide');
                    vm.mostrarTooltipAcao(id);
                    $timeout(function() {
                        vm.removerTooltipAcao(id);
                    }, 4000);
                    vm.situacaoCompufacil(id);
                    vm.venderLeadExactSales(id);
                }, function(error) {
                    $log.error(error);
                });
                if (vm.negocio[0].prop_ganho == 1) {
                    vm.negocio[0].prop_ganho = 0;
                    vm.negocio[0].prop_perdido = 0;
                } else {
                    vm.negocio[0].prop_ganho = 1;
                    vm.negocio[0].prop_perdido = 0;
                }
            } else { // clicou em perdeu
                negociosService.marcarSituacao(dados).then(function(response) {
                    vm.alterado = true;
                    //$(".perdeu_" + id).toggleClass("botao_perder_marcado");
                    //$(".ganhou_" + id).removeClass("botao_ganhar_marcado");
                    $('.spinner_' + id).toggleClass('hide');
                    $('.ganhou_' + id).toggleClass('hide');
                    $('.perdeu_' + id).toggleClass('hide');
                    vm.mostrarTooltipAcao(id);
                    $timeout(function() {
                        vm.removerTooltipAcao(id);
                    }, 4000);
                    vm.situacaoCompufacil(id);
                    vm.descartarLeadExactSales(id);
                }, function(error) {
                    $log.error(error);
                });
                if (vm.negocio[0].prop_perdido == 1) {
                    vm.negocio[0].prop_ganho = 0;
                    vm.negocio[0].prop_perdido = 0;
                } else {
                    vm.negocio[0].prop_ganho = 0;
                    vm.negocio[0].prop_perdido = 1;
                }
            }
        };
        // FUNÇÃO ANTIGA - SEM MOTIVO DE PERDA
        */

        vm.marcar_situacao = function(qual, id, negocio, ev) {
            if ((!($('.perdeu_' + id).hasClass('marcado_perdeu')) && !($('.perdeu_' + id).hasClass('botao_perder_marcado'))) && qual == 2){ // CLICOU EM PERDEU E A NEGOCIAÇÃO NÃO ESTAVA PERDIDA
                $('.spinner_' + id).toggleClass('hide'); // aparecer loading
                $('.ganhou_' + id).toggleClass('hide'); // ocultar icone ganhou
                $('.perdeu_' + id).toggleClass('hide'); // ocultar icone perdeu
                vm.removerTooltipAcao(id);
                
                vm.modalMotivoNeg(negocio, ev);
            } else { // CLICOU EM GANHAR OU REABRIR NEGOCIAÇÃO
                FactoryWebhook.triggerSit(id);

                var dados = {
                    'qual': qual,
                    'id': id,
                    'user_hash': Cookies.get('crm_funil_hash')
                };
                $('.spinner_' + id).toggleClass('hide'); // aparecer loading
                $('.ganhou_' + id).toggleClass('hide'); // ocultar icone ganhou
                $('.perdeu_' + id).toggleClass('hide'); // ocultar icone perdeu
                vm.removerTooltipAcao(id);

                negociosService.marcarSituacao(dados).then(function(response) {
                    vm.alterado = true;
                    $('.spinner_' + id).toggleClass('hide');
                    $('.ganhou_' + id).toggleClass('hide');
                    $('.perdeu_' + id).toggleClass('hide');

                    if (qual == 1) { // CLICOU NO BOTÃO GANHOU
                        vm.situacaoCompufacil(id, 1);
                        vm.venderLeadExactSales(id);

                        if (vm.negocio[0].prop_ganho == 1) { // SE JÁ ESTÁ MARCADO COMO 'GANHO', 'ABRE' A NEGOCIAÇÃO
                            vm.negocio[0].prop_ganho = 0;
                            vm.negocio[0].prop_perdido = 0;
                            vm.negocio[0].data_ganho_perda = '';
                        } else { // NÃO ESTÁ MARCADO COMO 'GANHO', 'GANHAR' NEGOCIAÇÃO
                            vm.negocio[0].prop_ganho = 1;
                            vm.negocio[0].prop_perdido = 0;
                            vm.negocio[0].data_ganho_perda = moment().format('DD/MM/YYYY');
                            $('.perdeu_' + id).removeClass('marcado_perdeu');
                            $('.perdeu_' + id).removeClass('botao_perder_marcado');
                        }
                    } else { // CLICOU NO BOTÃO PERDER
                        vm.situacaoCompufacil(id, 2);
                        vm.descartarLeadExactSales(id);

                        if (vm.negocio[0].prop_perdido == 1 || $('.perdeu_' + id).hasClass('marcado_perdeu') || $('.perdeu_' + id).hasClass('botao_perder_marcado')) { // SE JÁ ESTA MARCADO COMO 'PERDIDA', 'ABRE' A NEGOCIAÇÃO
                            vm.negocio[0].prop_ganho = 0;
                            vm.negocio[0].prop_perdido = 0;
                            vm.negocio[0].data_ganho_perda = '';
                            
                            $('.perdeu_' + id).removeClass('marcado_perdeu');
                            $('.perdeu_' + id).removeClass('botao_perder_marcado');
                        }
                    }

                    if ($(".perdeu_" + id).hasClass('tooltipstered')){
                        $(".perdeu_" + id).tooltipster("destroy");
                        $timeout(function() {
                            $(".perdeu_" + id).tooltip({
                                title: '',
                                placement: 'top'
                            });
                        }, 200);
                    }

                    vm.mostrarTooltipAcao(id);
                    $timeout(function() {
                        vm.removerTooltipAcao(id);
                    }, 4000);
                }, function(error) {
                    $log.error(error);
                });
            }
        };

        vm.mostrarTooltipAcao = function(id) {
            var left = $('.situacao_' + id).offset().left;
            var top = $('.situacao_' + id).offset().top;
            var width = $('.situacao_' + id).width();
            var height = $('.situacao_' + id).height();
            var width_t = $('.acao_' + id).width(); // largura tooltip
            $('.msg_' + id).html('Alterado com sucesso!<br>Clique novamente para desfazer.');
            $('.acao_' + id).css('left', ((width / 2) - (width_t / 2) + 15) + 'px');
            $('.acao_' + id).css('top', (height - 75) + 'px');
            $('.acao_' + id).fadeIn('fast');
        };

        vm.removerTooltipAcao = function(id) {
            $('.acao_' + id).fadeOut('fast');
        };

        vm.esconder = function() {
            $mdBottomSheet.hide();
        };

        vm.adm = false;
        vm.verificarAdm = function() {
            negociosService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
                if (response.data == 'Administrador') {
                    vm.adm = true;
                } else {
                    vm.adm = false;
                }
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };
        vm.verificarAdm();

        vm.excluirNegocio = function(slug) {
            var dados = {
                'slug': slug
            };
            swal({
                    title: 'Deseja excluir este registro?',
                    text: 'Esta ação é irreversível',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    negociosService.apagarNegocio(dados).then(function(response) {
                        vm.alterado = true;
                        swal({
                            title: 'Removido!',
                            text: 'Negócio removido com sucesso!',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            //validação para saber para qual tela voltar depois que excluir pessoa/empresa
                            vm.esconder();
                        }, 1000);
                    }, function(error) {
                        $log.error(error);
                    });
                });
        };


        vm.validaAtrasado = function(data) {
            var dataatual = new Date();
            var d = dataatual.getDate();
            var m = dataatual.getMonth();
            m += 1;
            var y = dataatual.getFullYear();
            if (d < 10) {
                d = '0' + d;
            }
            if (m < 10) {
                console.
                m = '0' + m;
            }
            var data_formatada = (y + '-' + m + '-' + d);
            var atual = moment(data_formatada, 'YYYY-MM-DD');
            var escolhida = moment(data, 'YYYY-MM-DD');

            var diferenca = escolhida.diff(atual, 'days');

            return diferenca;
        };

        vm.buscarProdutosProposta = function(slug_proposta) {
            var dados = {
                'slug_proposta': slug_proposta
            };
            produtosService.buscarProdutosProposta(dados).then(function(response) {
                if (response.data != '') {
                    vm.negocio[0].produtos = response.data;
                } else {
                    vm.negocio[0].produtos = [];
                }
                $timeout(function() {
                    $('.tooltipster_neg').tooltipster({
                        interactive: true,
                        maxWidth: 250,
                        minWidth: 250,
                        position: 'right',
                        theme: 'tooltipster-light',
                        delay: 100,
                    });
                });
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };

        vm.mostrarBtnEditar = false;
        vm.verifMostrarBtnEditar = function() {
            var local = $window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localPessoas = $window.localStorage.getItem('isModFunil');
            var valLocalPessoas = JSON.parse(API.funilRC.decrypt(localPessoas));
            switch (parseInt(valLocalPessoas.altera)) {
                case 4:
                    $timeout(function() {
                        vm.mostrarBtnEditar = false;
                        vm.roleSemAcesso = true;
                    });
                    break;
                case 3:
                    if (valLocal[0].user_id == vm.negocio[0].user_id) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                case 2:
                    if (valLocal[0].fk_gru_id == vm.negocio[0].fk_gru_id) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                default:
                    $timeout(function() {
                        vm.mostrarBtnEditar = true;
                    });
            }
        }



        /*MOTIVO PERDA DA NEGOCIAÇÃO --- INICIO*/
        vm.modalMotivoNeg = function(Prop, $event) {
            $timeout(function() {
                $("#enviandomotivoPerdaModal").on('click', function(){
                    $timeout(function() {
                        console.log(Prop.prop_id);
                        $('.spinner_' + Prop.prop_id).toggleClass('hide');
                        $('.ganhou_' + Prop.prop_id).toggleClass('hide');
                        $('.perdeu_' + Prop.prop_id).toggleClass('hide');
                        vm.alterado = true;
                        vm.negocio[0].prop_ganho = 0;
                        vm.negocio[0].prop_perdido = 1;
                        vm.negocio[0].data_ganho_perda = moment().format('DD/MM/YYYY');
                    }, 1000);
                });
            }, 1000);

            FactoryModalMotivo.openModal(Prop, $event);
        }
        /*MOTIVO PERDA DA NEGOCIAÇÃO --- FIM*/
    }

})();