/*
 * @Author: Marcelo Seben
 * @Date:   2018-05-12 08:15:04
 * @Last Modified by:   Marcelo Seben
 * @Last Modified time: 2018-05-12 08:16:25
 */

(function() {
    'use strict';

    angular
        .module('app')
        .factory('FactoryTotalVoice', FactoryTotalVoice);


    function FactoryTotalVoice($http, $q, $location, $filter, RC, $routeParams, API, $window, $timeout, $log, APINode, loadSaas, ligacaoService, $mdDialog) {

        var vm = this;

        var inicio = 0;
        var conversando = false;

        // return function ⤵
        return {
            modalLigando: modalLigando,
            desligarChamada,
            enviaDTMF,
            mostrarKeyboard,
            mute,
            abrirDiscador,
            gravarChamada,
            playGravacao,
            //DISCADOR
            clickNumber,
            clearNumber,
            ligar,
            muteDiscador,
            mostrarKeyboardDiscador,
        };

        // set function ⤵

        function modalLigando(cliente, qual, callback) {
            /*console.log("cliente");
            console.log(cliente);*/
            var telefone = '';
            if (qual == 1) {
                telefone = cliente.telefone;
            } else if (qual == 2) {
                telefone = cliente.telefone2;
            }
            if (telefone) {
                var numero = telefone.replace(/[^0-9]+/g, '');
                $('#modalLigando').modal({
                    backdrop: 'static',
                    keyboard: false
                });

                //numero = '49999059138';

                $("#modalLigando .circle").removeClass("hide");
                $("#modalLigando .ring").addClass("animate_ring");
                $(".ligandoTempo").addClass("hide");
                $(".btn_desligar_chamada").removeClass("hide");
                $(".btn_fechar_discador").addClass("hide");
                $(".ligandoStatus").addClass("calling_animate");

                $(".display_keyboard_ligacao").val('');
                $(".box_keyboard").hide();
                $(".btn_mute_ligacao").removeClass('ctrl_on');
                $(".btn_mostrar_keyboard").removeClass('ctrl_on');
                $(".btn_mostrar_keyboard").hide();
                $(".btn_mute_ligacao").addClass('btn_100');

                //$(".ligandoNome").html(cliente.nome);
                $(".ligandoTelefone").html(telefone);

                webphone.contentWindow.postMessage({
                    message: 'chamaNumero',
                    'numero': numero
                }, '*');

            }

            var timer = new Timer();

            var chamada_id = '';
            window.onmessage = function(e) {
                if (e.data.message == 'status') {
                    $(".ligandoStatus").html(e.data.status);
                    switch (e.data.status) {
                        case 'conversando':
                            $(".ligandoStatus").html("Conversando...");
                            $("#modalLigando .circle").addClass("hide");
                            $("#modalLigando .ring").removeClass("animate_ring");
                            $(".ligandoTempo").removeClass("hide");
                            $(".btn_mute_ligacao").removeClass('btn_100');
                            $(".btn_mostrar_keyboard").fadeIn();

                            timer.start();
                            timer.addEventListener('secondsUpdated', function(e) {
                                $('.ligandoTempo').html(timer.getTimeValues().toString());
                            });
                            break;
                        case 'encerrada':
                            timer.stop();
                            $(".ligandoStatus").removeClass("calling_animate");
                            $(".btn_desligar_chamada").addClass("hide");
                            $(".btn_fechar_discador").removeClass("hide");
                            $(".btn_mute_ligacao").addClass('btn_100');
                            $(".btn_mostrar_keyboard").hide();
                            atualizarSaldo()
                            gravarChamada(chamada_id, cliente);
                            callback();
                            break;
                        default:
                    }
                }
                if (e.data.message == 'chamada_id') { // RECUPERAR ID DA CHAMADA QUE ESTÁ SENDO REALIZADA
                    chamada_id = e.data.chamada_id;
                    console.log("chamada_id");
                    console.log(chamada_id);
                }
            }

            if (inicio == 0){
                var modalCon = '';
                var apnData = '';
                var modal = '';


                $(".button_minimize_ligacao").on("click", function() {
                    modalCon = $(this).closest("#modalLigando").attr("id");
                    apnData = $(this).closest("#modalLigando");
                    modal = "#modalLigando";
                    $(modal).toggleClass("min_modal");
                    if ($(modal).hasClass("min_modal")) { // MINIMIZAR
                        $(".modal-backdrop").fadeOut();
                        $("body").toggleClass("modal-open");
                        $(".minmaxLigacao").removeClass("hide");
                        $(".minmaxLigacao").append(apnData);
                        $(".button_minimize_ligacao").removeClass('fa-minus').addClass('fa-clone');
                        $("body").css({"padding-right": "0"});
                    } else { // MAXIMIZAR
                        $("body").toggleClass("modal-open");
                        $(".modal-backdrop").fadeIn();
                        $("body").append(apnData);
                        $(".minmaxLigacao").addClass("hide");
                        $(".button_minimize_ligacao").removeClass('fa-clone').addClass('fa-minus');
                        $("body").css({"padding-right": "15px"});
                    };
                })

                $(".btn_desligar_chamada, .btn_fechar_discador").click(function() {
                    $timeout(function() {
                        $(this).closest("#modalLigando");
                        $("#modalLigando").removeClass("min_modal");
                        $("body").removeClass(apnData);
                        $(".button_minimize_ligacao").removeClass('fa-clone').addClass('fa-minus');
                    }, 500);
                });

                inicio++;
            }
        }

        function gravarChamada(id_chamada, dados_cliente, numero) {
            if (id_chamada){
                var dados = {
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'id_chamada': id_chamada
                }
                if (dados_cliente){
                    dados.dados = dados_cliente;
                }
                if (numero){
                    dados.numero = numero;
                }
                ligacaoService.gravarChamada(dados).then(function(response) {
                    /*console.log("***** gravarChamada *****");
                    console.log(response.data);*/
                }, function(error) {
                    console.error(error);
                });
            }
        }

        function enviaDTMF(meuDTMF) {
            var display = $(".display_keyboard_ligacao").val() + meuDTMF;
            $(".display_keyboard_ligacao").val(display);
            webphone.contentWindow.postMessage({
                message: 'enviaDTMF',
                'dtmf': meuDTMF
            }, '*');
        }

        function mute() {
            if ($(".btn_mute_ligacao").hasClass('ctrl_on')){
                $(".btn_mute_ligacao").removeClass('ctrl_on');
                $(".ligandoStatus").html("Conversando...");
            } else {
                $(".btn_mute_ligacao").addClass('ctrl_on');
                $(".ligandoStatus").html("Mudo...");
            }

            webphone.contentWindow.postMessage({
                message: 'mute'
            }, '*');
        }

        function mostrarKeyboard() {
            $(".btn_mostrar_keyboard").toggleClass('ctrl_on');
            $(".box_keyboard").slideToggle();
            $(".box_numeros_discador").slideToggle();
        }

        function muteDiscador() {
            if ($(".btn_mute_discador").hasClass('ctrl_on')){
                $(".btn_mute_discador").removeClass('ctrl_on');
                $(".box_status_discador").html("Conversando...");
            } else {
                $(".btn_mute_discador").addClass('ctrl_on');
                $(".box_status_discador").html("Mudo...");
            }

            webphone.contentWindow.postMessage({
                message: 'mute'
            }, '*');
        }

        function mostrarKeyboardDiscador() {
            $(".btn_keyboard_discador").toggleClass('ctrl_on');
            $(".box_numeros_discador").slideToggle();
        }

        function atualizarSaldo() {
            $timeout(function() {
                var dados = {
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                $http.post(API.url + 'ligacao/service.php?f=buscarSaldoConta', dados).then(function(response) {
                    /*console.log("***** SALDO *****");
                    console.log(response.data.dados);*/
                    if (response.data.dados) {
                        $timeout(function() {
                            var saldo_anterior = $(".saldoLigacaoHeader").html();
                            if (saldo_anterior != $filter('currency')(response.data.saldo, "R$ ")) { // VERIFICA SE O SALDO MUDOU
                                $(".saldoLigacaoHeader").html($filter('currency')(response.data.saldo, "R$ "));
                                if (response.data.saldo < 20){
                                    $timeout(function() {
                                        $('.saldoLigacaoHeader').addClass('saldo_baixo');
                                    });
                                }
                            } else { // SE NÃO MUDOU EXECUTA NOVAMENTE A FUNÇÃO
                                atualizarSaldo();
                            }
                        });
                    }
                }, function(error) {
                    console.error(error);
                });
            }, 5000);
        }

        function abrirDiscador() {
            $('#modalDiscadorFunil').modal({
                backdrop: 'static',
                keyboard: false
            });
            $timeout(function() {
                $("#display_discador").focus();
            }, 500);
            $(".modal-backdrop").css("z-index", "77");
            //$("#widget-evoline-api-container").show();
        }

        function playGravacao(url_gravacao, id_chamada, qual, rel){
            $('.fechar_gravacao').toggleClass('hide');
            var box_player = $('#playerLigacao');
            var player = $('#playerLigacao .player');
            player.empty();
            if (id_chamada){
                var el = $(".box_play_ligacao_"+id_chamada);
                var offset = el.offset();
            }
            if (qual == 1){ // ABRIR AUDIO
                $timeout(function() {
                    $(".play_audio_"+id_chamada).hide();
                    $(".close_audio_"+id_chamada).show();
                    player.append('<audio controls id="audioplayer"><source src="'+url_gravacao+'" type="audio/mpeg"></audio>');
                    var audio = $('#audioplayer');
                    if (rel){ // relatório
                        box_player.css({visibility: 'visible', top: offset.top+el.height()+5, left: offset.left-(audio.width() / 2)});
                    } else {
                        box_player.css({visibility: 'visible', top: offset.top-16, left: offset.left+30});
                    }
                    audio[0].play();
                });
            } else { // FECHAR AUDIO
                $timeout(function() {
                    if (id_chamada){
                        $(".close_audio_"+id_chamada).hide();
                        $(".play_audio_"+id_chamada).show();
                    } else {
                        $(".close_audio").hide();
                        $(".play_audio").show();
                    }
                    box_player.css({visibility: 'hidden'});
                });
            }
        }

        // DISCADOR --- INICIO
        function clickNumber(num){
            if (conversando){ // EM LIGAÇÃO
                var display = $("#display_discador").val() + num;
                $("#display_discador").val(display);
                webphone.contentWindow.postMessage({
                    message: 'enviaDTMF',
                    'dtmf': num
                }, '*');
            } else { // DISCANDO NÚMEROS
                var display = $("#display_discador").val() + num;
                $("#display_discador").val(display);
                $("#display_discador").focus();
                if (display.length){
                    $(".b_ligar").prop("disabled", false);
                }
            }
        }

        function clearNumber(num){
            var txtPhoneNumber = $("#display_discador");
            var val = txtPhoneNumber.val();
            if(val.length > 0){
                val = val.substr(0,val.length-1);
            } else {
                $(".b_ligar").prop("disabled", true);
            }
            $("#display_discador").val(val);
            $("#display_discador").focus();
        }

        function verificarNumeroExistente(numero, chamada_id){
            if (numero){
                var dados = {
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'numero': numero
                }
                ligacaoService.verificarNumeroExistente(dados).then(function(response) {
                    /*console.log("***** verificarNumero *****");
                    console.log(response.data);*/

                    if (response.data.length > 0){
                        var parentEl = angular.element(document.body);
                        $mdDialog.show({
                            parent: parentEl,
                            templateUrl: 'ligacao/template/dialog_numeros_associados.html',
                            locals: {
                                clientes: response.data
                            },
                                controller: ControllerNumeroExiste
                            }).then(function(cliente) {
                                atualizarGravacaoChamada(chamada_id, cliente);
                            }, function() {
                                
                            });
                    }
                }, function(error) {
                    console.error(error);
                });
            }
        }

        function atualizarGravacaoChamada(chamada_id, cliente){
            if (chamada_id){
                var dados = {
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'chamada_id': chamada_id,
                    'dados': cliente
                }
                ligacaoService.atualizarGravacaoChamada(dados).then(function(response) {
                    console.log("***** atualizarGravacaoChamada *****");
                    console.log(response.data);
                }, function(error) {
                    console.error(error);
                });
            }
        }

        function ControllerNumeroExiste($scope, $mdDialog, clientes) {
            $scope.clientes = clientes;

            $scope.salvar = function() {
                console.log("$scope.cliente");
                console.log($scope.cliente);
                var cliente = $filter('filter')($scope.clientes, { id: $scope.cliente });
                $mdDialog.hide(cliente[0]);
            }

            $scope.closeDialog = function() {
                $mdDialog.hide();
            }
        }

        function ligar(){
            var txtPhoneNumber = $("#display_discador");
            var numero = txtPhoneNumber.val();
            numero = numero.replace(/[^0-9]+/g, '');

            if (numero.length){
                webphone.contentWindow.postMessage({
                    message: 'chamaNumero',
                    'numero': numero
                }, '*');

                var chamada_id = '';
                window.onmessage = function(e) {
                    if (e.data.message == 'status') {
                        //$(".box_status_discador").html(e.data.status);
                        switch (e.data.status) {
                            case 'chamando':
                                $(".b_desligar").prop("disabled", false);
                                $(".b_ligar").prop("disabled", true);
                                $(".box_status_discador").slideDown();
                                $(".box_status_discador").html("Chamando...");
                                $(".box_numeros_discador").slideUp();
                                $(".box_ctrl_discador").slideDown();
                                $(".b_apagar").hide();
                                $(".close_discador").fadeOut();
                                break;
                            case 'conversando':
                                $(".box_status_discador").html("Conversando...");
                                conversando = true;
                                break;
                            case 'encerrada':
                                conversando = false;
                                $(".close_discador").fadeIn();
                                $(".b_desligar").prop("disabled", true);
                                $(".b_ligar").prop("disabled", false);
                                $(".box_status_discador").toggleClass("status_encerrada");
                                $(".box_status_discador").html("Chamada encerrada.");
                                $(".box_numeros_discador").slideDown();
                                $(".box_ctrl_discador").slideUp();
                                $(".b_apagar").show();
                                $timeout(function() {
                                    $(".box_status_discador").slideUp();
                                    $timeout(function() {
                                        $(".box_status_discador").toggleClass("status_encerrada");
                                    }, 500);
                                }, 2000);
                                atualizarSaldo();
                                gravarChamada(chamada_id, '', numero);
                                verificarNumeroExistente(numero, chamada_id);
                                $("#display_discador").val('');
                                $("#display_discador").focus();
                                break;
                            default:
                        }
                    }
                    if (e.data.message == 'chamada_id') { // RECUPERAR ID DA CHAMADA QUE ESTÁ SENDO REALIZADA
                        chamada_id = e.data.chamada_id;
                    }
                }
            }
        }

        function desligarChamada() {
            webphone.contentWindow.postMessage({
                message: 'hangup'
            }, '*');
        }
        // DISCADOR --- FIM

    }
}());