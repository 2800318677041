(function() {
    'use strict';

    angular
        .module('relatorios')
        .controller('gerarRelMetasController', gerarRelMetasController)


    gerarRelMetasController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas'];

    function gerarRelMetasController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas) {

        var vm = this;

        vm.url = API.url;
        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        var now = new Date;
        vm.anoatual = now.getFullYear();

        vm.periodo = '';
        vm.periodo_inicio = '';
        vm.periodo_fim = '';

        vm.options = { width: 100, height: 200, 'bar': 'bbb' };
        vm.data = [0, 2, 3];

        vm.adm = false;
        vm.verificarAdm = function() {
            categoriasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
                if (response.data == 'Administrador') {
                    vm.adm = true;
                } else {
                    vm.adm = false;
                }
            }, function(error) {
                console.log('Erro: ', error);
            });
        };

        vm.rangePeriodo = function() {
            function cb(start, end) {
                if (start._isValid) { // datas validas
                    vm.periodo_inicio = start.format('DD_MM_YYYY');
                    vm.periodo_fim = end.format('DD_MM_YYYY');;
                } else {

                }
            }
            $('.campo_periodo').daterangepicker({
                //autoUpdateInput: false,
                'opens': 'center',
                'locale': {
                    'format': 'DD/MM/YYYY',
                    'cancelLabel': 'Limpar'
                },
                ranges: {
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Há 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Há 30 dias': [moment().subtract(29, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            });
            //}, cb);
            $('.campo_periodo').on('apply.daterangepicker', function(ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
                vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
            });

            $('.campo_periodo').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                vm.periodo = '';
                vm.periodo_inicio = '';
                vm.periodo_fim = '';
            });
        };

        vm.expandirAcc = function() { //EXPANDIR ACCORDION
            $('.box_filtrar_atividades').slideToggle();
        };

        vm.acc_chamadas = function(id) {
            $timeout(function() {
                $('.box_lista_chamadas_' + id).slideToggle();
                $('.seta_acc_chamada_' + id).toggleClass('seta_ativa');
            });
        };

        vm.imprimir = function() {
            window.print();
        };

        var tabClasses;

        vm.initTabs = function() {
            tabClasses = ['', '', '', ''];
        };

        vm.getTabClass = function(tabNum) {
            return tabClasses[tabNum];
        };

        vm.getTabPaneClass = function(tabNum) {
            return 'tab-pane ' + tabClasses[tabNum];
        };

        vm.setActiveTab = function(tabNum, ativo) {
            vm.initTabs();
            tabClasses[tabNum] = 'active';
            vm.metaAtiva = ativo;
        };

        vm.initTabs();
        vm.setActiveTab(1);
        vm.metaAtiva = 1;
        vm.existeInativa = 0;

        vm.listar = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;

                var dados = {
                    'saasid': vm.saasid
                };

                if ($routeParams.inicio) { // PERIODO SELECIONADO
                    vm.inicio = $routeParams.inicio;
                    vm.fim = $routeParams.fim;
                    vm.inicioExibir = vm.inicio.replace('_', '/').replace('_', '/');
                    vm.fimExibir = vm.fim.replace('_', '/').replace('_', '/');
                    dados.inicio = vm.inicio;
                    dados.fim = vm.fim;
                }

                dados.tipo = $routeParams.tipo;
                dados.user_hash = Cookies.get('crm_funil_hash');

                if ($routeParams.tipo == 'negociacoes'){
                    dados.mes_meta = $routeParams.mes_meta;
                    vm.tituloPrincipal = 'Metas de Negociações';
                    vm.tituloFiltro = 'Metas de Negociações';
                    vm.subtitulo = 'Metas de Negociações';

                    relatoriosService.carregarMetasNegociacao(dados).then(function(response) {
                        console.log("carregarMetas");
                        console.log(response.data);
                        //return null;
                        if (response.data != 'null' && response.data.length != 0) {
                            vm.metas = response.data;
                            vm.existeInativa = $filter('filter')(vm.metas, { ativo: 0 }).length;
                        } else {
                            vm.metas = '';
                        }
                        vm.carregando = false;
                    }, function(error) {
                        console.log('Erro: ', error);
                    });
                } else if ($routeParams.tipo == 'ligacoes'){
                    vm.tituloPrincipal = 'Metas de Ligações';
                    vm.tituloFiltro = 'Metas de Ligações';
                    vm.subtitulo = 'Metas de Ligações';

                    relatoriosService.carregarMetasLigacao(dados).then(function(response) {
                        console.log("carregarMetas");
                        console.log(response.data);
                        //return null;
                        if (response.data != 'null' && response.data.length != 0) {
                            vm.metas = response.data;
                            vm.existeInativa = $filter('filter')(vm.metas, { ativo: 0 }).length;
                        } else {
                            vm.metas = '';
                        }
                        vm.carregando = false;
                    }, function(error) {
                        console.log('Erro: ', error);
                    });
                } else if ($routeParams.tipo == 'agendamentosodontotop'){
                    dados.mes_meta = $routeParams.mes_meta;
                    vm.tituloPrincipal = 'Metas de Agendamentos OtontoTop';
                    vm.tituloFiltro = 'Metas de Agendamentos OtontoTop';
                    vm.subtitulo = 'Metas de Agendamentos OtontoTop';

                    relatoriosService.carregarMetasAgendamentoOdontotop(dados).then(function(response) {
                        console.log("carregarMetas");
                        console.log(response.data);
                        // return null;
                        if (response.data != 'null' && response.data.length != 0) {
                            vm.metas = response.data;
                            vm.existeInativa = $filter('filter')(vm.metas, { ativo: 0 }).length;
                        } else {
                            vm.metas = '';
                        }
                        vm.carregando = false;
                    }, function(error) {
                        console.log('Erro: ', error);
                    });
                }
            });
        }
        vm.listar();

        vm.tipo_meta = $routeParams.tipo;
        if ($routeParams.mes_meta){
            vm.mes_meta = $routeParams.mes_meta.replace("-", "/");
        }

        var myChart;
        vm.renderizarGraficoMetaLig = function(meta) {
            /*console.log("meta");
            console.log(meta);*/

            vm.carregando = true;

            vm.metaSelecionada = meta;

            if (!meta.metas[0].registro_meta) {
                $(".interno_grafico_meta").hide();
                $(".nenhum_registro_meta").show();
            } else {
                $(".nenhum_registro_meta").hide();

                var maxValue = 0;

                var randomColorFactor = function() {
                    return Math.round(Math.random() * 255);
                };

                var type = '';
                if (meta.metas[0].labels.length > 2) {
                    type = 'line';
                } else {
                    type = 'bar';
                }

                var title = meta.nome;

                // TOTAIS
                vm.totaisGrafico = [];
                if (meta.tipo == "L") {
                    //if (meta.controle == 'I') {
                    if (meta.metas[0].usuarios != "Todos") {
                        angular.forEach(meta.metas[0].labels, function(value, key) {
                            var label = {
                                'data': value,
                                'total': 0
                            }
                            angular.forEach(meta.metas[0].registro_meta, function(value2, key2) {
                                if (value2.med_data == value) {
                                    label.total += parseInt(value2.alcancado);
                                }
                            });
                            vm.totaisGrafico.push(label);
                        });
                    }
                }

                var baseMetaAlcancada = [];
                vm.metaAlcancada = [];

                // ANNOTATIONS
                var annotations = [];
                if (meta.tipo == "L") { // LINHA HORIZONTAL DA META SOMENTE PARA LIGAÇÕES
                    var base_annotation = {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        value: 0,
                        borderColor: '',
                        borderWidth: 3,
                        borderDash: [2, 2],
                        label: {
                            enabled: true,
                            backgroundColor: 'rgba(133, 133, 133, 0.9)',
                            content: ''
                        }
                    }

                }
                angular.forEach(meta.metas, function(value, key) {
                    if (meta.tipo == "L") {
                        var new_ann = angular.copy(base_annotation);
                        new_ann.value = parseInt(value.meta);
                        if (value.meta > maxValue) {
                            maxValue = value.meta;
                        }
                        new_ann.borderColor = value.cor;
                        new_ann.label.content = value.nome + " |" + parseInt(value.meta) + "|";
                        annotations.push(new_ann);

                        // BASE META ALCANÇADA
                        baseMetaAlcancada.push({ "nome": value.nome, "meta": parseInt(value.meta) });
                    } else if (meta.tipo == "N") {
                        // BASE META ALCANÇADA
                        //baseMetaAlcancada.push({ "nome": value.nome, "meta": parseInt(value.meta) });
                        angular.forEach(value.registro_meta, function(value3, key3) {
                            baseMetaAlcancada.push({ "nome": value.nome, "meta": parseFloat(value3.meta) });
                        });
                    }
                });

                // LABELS
                var labels = meta.metas[0].labels;

                // DATA
                var datasets = [];
                var color = 'rgba(' + randomColorFactor() + ',' + randomColorFactor() + ',' + randomColorFactor();
                var base_datasets = {
                    label: '',
                    backgroundColor: color + ',0.5)',
                    borderColor: color + ',1)',
                    borderWidth: 2,
                    fill: true,
                    lineTension: 0.4,
                    data: []
                }
                var base_datasetsNeg = {
                    label: '',
                    backgroundColor: '',
                    borderColor: '',
                    borderWidth: 2,
                    fill: true,
                    lineTension: 0,
                    pointRadius: 0,
                    data: []
                }

                var new_data = angular.copy(base_datasets);
                if (meta.metas[0].usuarios == "Todos") { // TODOS
                    if (meta.tipo == "L") { // META DE LIGAÇÕES
                        new_data.label = meta.metas[0].usuarios;

                        angular.forEach(meta.metas[0].registro_meta, function(value, key) {
                            new_data.data.push(parseInt(value.alcancado));
                            if (parseInt(value.alcancado) > parseInt(maxValue)) {
                                maxValue = parseInt(value.alcancado);
                            }

                            // REGISTRAR META ALCANÇADA
                            angular.forEach(baseMetaAlcancada, function(value2, key2) {
                                if (parseInt(value.alcancado) >= parseInt(value2.meta)) {
                                    var array = {
                                        "nome_meta": value2.nome,
                                        "meta": parseInt(value2.meta),
                                        "alcancado": parseInt(value.alcancado),
                                        "data": value.med_data,
                                    }
                                    if (vm.metaAlcancada.length) {
                                        vm.metaAlcancada[0].metas.push(array);
                                    } else {
                                        vm.metaAlcancada.push({
                                            "nome": "Todos",
                                            "metas": [array]
                                        });
                                    }
                                }
                            });
                        });
                    } else if (meta.tipo == "N") { // META DE NEGOCIAÇÕES
                        new_data.lineTension = 0;
                        new_data.backgroundColor = 'rgba(0,0,0,0)';
                        new_data.label = 'Atingido';
                        angular.forEach(meta.metas[0].registro_meta, function(value, key) { // PEGAR O REGISTRO DE SOMENTE UMA DAS METAS, POIS OS VALORES SÃO REPLICADOS EM TODAS
                            if (moment(value.med_data, "DD/MM/YYYY").format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")){
                                new_data.data.push(parseFloat(value.alcancado));
                            }
                        });

                        angular.forEach(meta.metas, function(value, key) {
                            angular.forEach(value.registro_meta, function(value2, key2) {
                                
                                /*if (value2.alcancado > 0){
                                    new_data.data.push(parseFloat(value2.alcancado));
                                }*/
                                
                                if (parseFloat(value2.alcancado) > parseFloat(maxValue)) {
                                    maxValue = parseFloat(value2.alcancado);
                                }

                                // REGISTRAR META ALCANÇADA
                                if (parseFloat(value2.alcancado) >= parseFloat(value2.meta)) {
                                    var array = {
                                        "nome_meta": value.nome,
                                        "meta": parseFloat(value2.meta),
                                        "alcancado": parseFloat(value2.alcancado).toFixed(2),
                                        "data": value2.med_data,
                                    }
                                    if (vm.metaAlcancada.length) {
                                        vm.metaAlcancada[0].metas.push(array);
                                    } else {
                                        vm.metaAlcancada.push({
                                            "nome": "Todos",
                                            "metas": [array]
                                        });
                                    }
                                }
                            });
                        });
                    }
                    datasets.push(new_data);
                    console.log("baseMetaAlcancada");
                    console.log(baseMetaAlcancada);

                    if (meta.tipo == "N") {
                        angular.forEach(meta.metas, function(value2, key2) {
                            var new_data = angular.copy(base_datasetsNeg);
                            angular.forEach(value2.registro_meta, function(value, key) {
                                new_data.label = value.mec_nome;
                                new_data.borderColor = value.cor;
                                new_data.backgroundColor = 'rgba(0,0,0,0)';
                                new_data.data.push(parseFloat(value.meta));
                                if (parseFloat(value.meta) > parseFloat(maxValue)) {
                                    maxValue = parseFloat(value.meta);
                                }
                            });
                            datasets.push(new_data);
                        });
                    }
                } else { // USUARIOS ESPECÍFICOS
                    angular.forEach(meta.metas[0].usuarios, function(value2, key2) {
                        var color = 'rgba(' + randomColorFactor() + ',' + randomColorFactor() + ',' + randomColorFactor();
                        var new_data = angular.copy(base_datasets);
                        new_data.label = value2.user_nome ? value2.user_nome : value2.gru_nome;
                        if (value2.user_id) {
                            new_data.user_id = value2.user_id;
                        } else {
                            new_data.gru_id = value2.gru_id;
                        }
                        new_data.backgroundColor = color + ',0.5)';
                        new_data.borderColor = color + ',1)';
                        datasets.push(new_data);
                    });
                    angular.forEach(meta.metas[0].registro_meta, function(value3, key3) {
                        angular.forEach(datasets, function(value4, key4) {
                            if (value4.user_id) {
                                if (value3.user_id == value4.user_id) {
                                    datasets[key4].data.push(parseInt(value3.alcancado));
                                    if (parseInt(value3.alcancado) > parseInt(maxValue)) {
                                        maxValue = parseInt(value3.alcancado);
                                    }
                                }
                            } else {
                                if (value3.gru_id == value4.gru_id) {
                                    datasets[key4].data.push(parseInt(value3.alcancado));
                                    if (parseInt(value3.alcancado) > parseInt(maxValue)) {
                                        maxValue = parseInt(value3.alcancado);
                                    }
                                }
                            }
                        });

                        // REGISTRAR META ALCANÇADA
                        angular.forEach(baseMetaAlcancada, function(value5, key5) {
                            if (parseInt(value3.alcancado) >= parseInt(value5.meta)) {
                                var array = {
                                    "nome_meta": value5.nome,
                                    "meta": parseInt(value5.meta),
                                    "alcancado": parseInt(value3.alcancado),
                                    "data": value3.med_data
                                }
                                var id_atual = value3.user_id ? value3.user_id : value3.gru_id;
                                var registrado = $filter('filter')(vm.metaAlcancada, { id: id_atual });
                                if (registrado.length) { // USUÁRIO JÁ ATINGIU ALGUMA META
                                    var key6 = Object.keys(registrado)[0];
                                    vm.metaAlcancada[key6].metas.push(array);
                                } else { // USUÁRIO AINDA NÃO ATINGUIU META, ADICIONAR NO ARRAY
                                    vm.metaAlcancada.push({
                                        "id": value3.user_id ? value3.user_id : value3.gru_id,
                                        "nome": value3.user_nome ? value3.user_nome : value3.gru_nome,
                                        "avatar": value3.user_avatar,
                                        "sigla": value3.user_nome ? value3.user_nome.substring(0, 1) : value3.gru_nome.substring(0, 1),
                                        "metas": [array]
                                    });
                                }
                            }
                        });
                    });
                }
                maxValue += 2;

                var datalabels = [];
                if (meta.tipo == "L") { // SÓ APLICA O DATA LABELS PARA GRÁFICO DE LIGAÇÕES
                    datalabels = {
                        backgroundColor: 'white',
                        borderColor: function(context) {
                            return context.dataset.borderColor;
                        },
                        borderWidth: 1,
                        borderRadius: 4,
                        color: function(context) {
                            return context.dataset.borderColor;
                        },
                        font: {
                            weight: 'bold'
                        },
                        //formatter: Math.round
                        formatter: function(value, context) {
                            return value > 0 ? value : null;
                        }
                    };
                } else if (meta.tipo == "N") {
                    datalabels = {
                        display: false
                    }
                }

                console.log("annotations");
                console.log(annotations);
                console.log("labels");
                console.log(labels);
                console.log("datasets");
                console.log(datasets);
                console.log("vm.metaAlcancada");
                console.log(vm.metaAlcancada);

                if (document.getElementById("canvas_grafico_meta")) {
                    var ctx = document.getElementById("canvas_grafico_meta").getContext("2d");

                    if (myChart != null) {
                        myChart.destroy();
                    }

                    myChart = new Chart(ctx, {
                        type: type,
                        responsive: true,
                        maintainAspectRatio: true,
                        data: {
                            labels: labels,
                            datasets: datasets
                        },
                        options: {
                            responsive: true,
                            title: {
                                display: true,
                                text: title
                            },
                            tooltips: {
                                itemSort: (a, b, data) => b.yLabel - a.yLabel,
                                mode: 'label',
                                intersect: false,
                                bodySpacing: 8,
                                callbacks: {
                                    label: function(tooltipItem, data) { // aquiiii
                                        if (meta.tipo == "N") {
                                            return data.datasets[tooltipItem.datasetIndex].label + ': ' +
                                            'R$ ' + Number(tooltipItem.yLabel).toFixed(2).toString().replace('.', ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                        } else {
                                            return data.datasets[tooltipItem.datasetIndex].label + ': ' + Number(tooltipItem.yLabel)
                                        }
                                    }
                                }
                            },
                            //tooltips: false,
                            annotation: {
                                annotations: annotations
                            },
                            plugins: {
                                datalabels: datalabels
                            },
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        //stepSize: 30000,
                                        suggestedMax: maxValue
                                    }
                                }]
                            },
                        }
                    });
                }
            }

            $timeout(function() {
                $(".box_grafico_meta").show();
                vm.carregando = false;
                $timeout(function() {
                    $('html, body').animate({
                        scrollTop: $('.box_grafico_meta').offset().top - 25
                    }, 1000);
                }, 200);
            }, 500);
        }

        vm.renderizarGraficoMetaNeg = function(data){
            console.log("data");
            console.log(data);

            vm.metaSelecionada = data;

            if (!data.registro_meta) {
                $(".interno_grafico_meta").hide();
                $(".nenhum_registro_meta").show();
            } else {
                $(".nenhum_registro_meta").hide();
                $(".interno_grafico_meta").show();

                var randomColorFactor = function() {
                    return Math.round(Math.random() * 255);
                };

                var title = data.nome;

                var labels = [];
                data.dias.forEach(element => {
                    labels.push(element);
                });

                var datasets = [];
                var base_datasets = {
                    label: '',
                    backgroundColor: '#fff',
                    borderColor: '',
                    borderWidth: 2,
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    data: []
                }

                angular.forEach(data.metas, function(value, key) {
                    var new_data = JSON.parse(JSON.stringify(base_datasets));
                    new_data.label = value.nome;
                    new_data.data = value.valores_meta;
                    new_data.borderColor = value.cor;
                    // new_data.pointBackgroundColor = 'rgba(0,0,0,0)';
                    datasets.push(new_data);
                })

                var color = 'rgba(' + randomColorFactor() + ',' + randomColorFactor() + ',' + randomColorFactor();
                var new_data = JSON.parse(JSON.stringify(base_datasets));
                new_data.label = 'Atingido';
                new_data.data = data.registro_meta;
                new_data.borderColor = color + ',0.5)';
                new_data.pointBackgroundColor = color + ',1)';
                datasets.push(new_data);

                console.log("datasets");
                console.log(datasets);

                if (document.getElementById("canvas_grafico_meta")) {
                    var ctx = document.getElementById("canvas_grafico_meta").getContext("2d");

                    if (myChart != null) {
                        myChart.destroy();
                    }

                    myChart = new Chart(ctx, {
                        type: 'line',
                        responsive: true,
                        maintainAspectRatio: true,
                        data: {
                            labels: labels,
                            datasets: datasets
                        },
                        options: {
                            responsive: true,
                            title: {
                                display: true,
                                text: title
                            },
                            tooltips: {
                                itemSort: (a, b, data) => b.yLabel - a.yLabel,
                                mode: 'label',
                                intersect: false,
                                bodySpacing: 8,
                                callbacks: {
                                    label: function(tooltipItem, data) {
                                        return data.datasets[tooltipItem.datasetIndex].label + ': ' +
                                            'R$ ' + Number(tooltipItem.yLabel).toFixed(2).toString().replace('.', ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }
                                }
                            },
                            //tooltips: false,
                            plugins: {
                                datalabels: {
                                    display: false
                                }
                            },
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        // stepSize: 30000,
                                        // suggestedMax: maxValue
                                    }
                                }]
                            },
                        }
                    });
                }

            }

            $timeout(function() {
                $(".box_grafico_meta").show();
                vm.carregando = false;
                $timeout(function() {
                    $('html, body').animate({
                        scrollTop: $('.box_grafico_meta').offset().top - 25
                    }, 1000);
                }, 200);
            }, 500);
            
        };

        vm.renderizarDadosAgendamentos = function(data){
            vm.carregando = true;
            vm.metaSelecionada = data;

            if (!data.total) { // NENHUM REGISTRO DE META
                $(".interno_dados_agendamentos").hide();
                $(".interno_grafico_meta").hide();
                $(".nenhum_registro_meta").show();
                console.log("aaaaaaaaaaaaaa");
            } else {
                console.log("bbbbbbbbbbbb");
                $(".interno_grafico_meta").hide();
                $(".nenhum_registro_meta").hide();
                $(".interno_dados_agendamentos").show();

            }

            $timeout(function() {
                $(".box_grafico_meta").show();
                vm.carregando = false;
                $timeout(function() {
                    $('html, body').animate({
                        scrollTop: $('.box_grafico_meta').offset().top - 25
                    }, 1000);
                }, 200);
            }, 500);
        }

        vm.carregando = true;

        vm.acabou = function() {
            vm.carregando = false;
            vm.carregando_mais_inf = false;
        };

        vm.verificarNavegador = function() {
            // Opera 8.0+
            vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
            // Firefox 1.0+
            vm.isFirefox = typeof InstallTrigger !== 'undefined';
            // At least Safari 3+: "[object HTMLElementConstructor]"
            vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // Internet Explorer 6-11
            vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
            // Edge 20+
            vm.isEdge = !vm.isIE && !!window.StyleMedia;
            // Chrome 1+
            vm.isChrome = !!window.chrome && !!window.chrome.webstore;
            // Blink engine detection
            vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();

        vm.verifDadosUser = function() {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localNeg = window.localStorage.getItem('isModNegociacao');
            var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

            if (valLocalNeg.visualiza) {
                vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
            }
        }
        vm.verifDadosUser();

    }

})();