(function () {
    'use strict';

    angular
        .module('empresas')
        .controller('timelineController', function timelineController($http, API, $routeParams, $filter, $timeout, empresasService) {
            const vm = this;
            vm.dadosEmpresa = { nome: '562-empresa-teste-andr-', ativo: 1, tipo: 'e' }
            vm.limitTimeLine = 20
            vm.timeline = []
            let arrayFreeze = []
            vm.filter = []
            vm.filtersCheckbox = []
            vm.markCheckbox = true;
            vm.loadedTimeline = false;

            empresasService.findUnique($routeParams.slug).then((response) => {
                vm.empresa = {
                    'emp_id': response.data[0].emp_id,
                    'nome': response.data[0].emp_nome,
                    'slug': response.data[0].emp_slug
                };
            })
            .catch((err) => console.log(err))

            $http.get(`${API.url}empresas/service.php?t=timeline&slug=${$routeParams.slug}`).then((result) => {
                for (let a = 0; a < result.data.length; a++) {
                    let history = result.data[a];
                    if (history.info_anterior && !isNaN(Number(history.info_anterior))) history.info_anterior = $filter('currency')(history.info_anterior)
                    if (history.info_novo && !isNaN(Number(history.info_novo))) history.info_novo = $filter('currency')(history.info_novo)
                    history.order_data = moment(history.order_data).format('DD/MM/YYYY hh:mm:ss')

                    if (vm.filtersCheckbox.findIndex((item) => item.value === history.tipo) === -1) {
                        arrayFreeze.push(history.tipo)
                        // vm.filter.push(history.tipo)
                        vm.filtersCheckbox.push({ nome: history.titulo, value: history.tipo })
                    }

                    if(a > 0){
                        $timeout(() => {
                            vm.timeline.push(history)
                        }, 450 * a)
                    } else vm.timeline.push(history)

                    
                }

                vm.loadedTimeline = true;
            })
            .catch((e) => {
                console.log(e)
            })

            vm.changeCheckbox = (obj) => {
                console.log(obj)
                const findValue = vm.filter.indexOf(obj.value)
                if (obj.remove && findValue > -1) {
                    return vm.filter.splice(findValue, 1)
                }
                vm.filter.push(obj.value)
            }

            vm.cleanHistory = () => {
                vm.markCheckbox = false;
                $timeout(() => vm.filter = [])
            }

            vm.markAll = () => {
                vm.markCheckbox = false;
                $timeout(() => {
                    vm.markCheckbox = true;
                })
            }
        });

    angular.module('app').filter('filterTimeline', () => (array, params) => array.filter((item) => params.indexOf(item.tipo) > -1))
})();