
(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelExtratoChamadasController', gerarRelExtratoChamadasController)


  gerarRelExtratoChamadasController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'FactoryTotalVoice'];

  function gerarRelExtratoChamadasController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas, FactoryTotalVoice) {

        var vm = this;

        vm.url = API.url;
        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        var now = new Date;
        vm.anoatual = now.getFullYear();

        vm.periodo = '';
        vm.periodo_inicio = '';
        vm.periodo_fim = '';

        vm.options = { width: 100, height: 200, 'bar': 'bbb' };
        vm.data = [0, 2, 3];

        vm.adm = false;
        vm.verificarAdm = function() {
          categoriasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
            if (response.data == 'Administrador') {
              vm.adm = true;
            } else {
              vm.adm = false;
            }
          }, function(error) {
            console.log('Erro: ', error);
          });
        };

        vm.rangePeriodo = function() {
          function cb(start, end) {
            if (start._isValid) { // datas validas
              vm.periodo_inicio = start.format('DD_MM_YYYY');
              vm.periodo_fim = end.format('DD_MM_YYYY');;
            } else {

            }
          }
          $('.campo_periodo').daterangepicker({
            //autoUpdateInput: false,
            'opens': 'center',
            'locale': {
              'format': 'DD/MM/YYYY',
              'cancelLabel': 'Limpar'
            },
            ranges: {
              'Hoje': [moment(), moment()],
              'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Há 7 dias': [moment().subtract(6, 'days'), moment()],
              'Há 30 dias': [moment().subtract(29, 'days'), moment()],
              'Este mês': [moment().startOf('month'), moment().endOf('month')],
              'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
          });
          //}, cb);
          $('.campo_periodo').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
            vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
            vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
          });

          $('.campo_periodo').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            vm.periodo = '';
            vm.periodo_inicio = '';
            vm.periodo_fim = '';
          });
        };

        vm.selectUsuarios = [];
        vm.selectedUsuarios = [];
        vm.buscaUsuarios = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid
            };
            relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
              var array = [];

              var local = window.localStorage.getItem('5c479de2');
              var valLocal = JSON.parse(API.funilRC.decrypt(local));
              var localNeg = window.localStorage.getItem('isModPessoas');
              var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

              vm.roleVisualiza = parseInt(valLocalNeg.visualiza);

              /* GESTOR --- INICIO*/
              if (vm.roleVisualiza == 1) {
                var gestores = $filter('filter')(response.data.usuarios, { fk_perfil_id: '1' });
                if (gestores != '') {
                  array.push({ name: "Gestor", msGroup: true });
                  angular.forEach(gestores, function(value, key) {
                    if (value.user_avatar == null) {
                      var icon =
                        '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                        '<div class="cell table">' +
                        '<div class="row">' +
                        '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                        '</span>' +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    } else {
                      var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                    }
                    if (value.user_ativo == 0) {
                      var name = value.user_nome + ' (Inativo)';
                    } else {
                      name = value.user_nome;
                    }
                    if (value.user_hash == Cookies.get('crm_funil_hash')) {
                      var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                    }
                    array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                  });
                  array.push({ msGroup: false });
                }
              }
              /* GESTOR --- FIM*/

              vm.montarSelectUsuarios(array, response.data.usuarios, response.data.grupos);
            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaUsuarios();

        vm.montarSelectUsuarios = function(array, usuarios, grupos) {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var grupo_id = valLocal[0].fk_gru_id;
          var localNeg = window.localStorage.getItem('isModPessoas');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
          if (vm.roleVisualiza != 4) {
            /* GRUPOS --- INICIO*/
            if (vm.roleVisualiza == 2 || vm.roleVisualiza == 1) {
              if (grupos != '') {
                angular.forEach(grupos, function(value, key) {
                  if (vm.roleVisualiza == 2) {
                    if (grupo_id == value.gru_id) {
                      array.push({ name: value.gru_nome, msGroup: true });
                      if (value.usuarios.length != '') {
                        angular.forEach(value.usuarios, function(value, key) {
                          if (value.user_avatar == null) {
                            var icon =
                              '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                              '<div class="cell table">' +
                              '<div class="row">' +
                              '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                              '</span>' +
                              '</div>' +
                              '</div>' +
                              '</div>';
                          } else {
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                          }
                          if (value.user_ativo == 0) {
                            var name = value.user_nome + ' (Inativo)';
                          } else {
                            name = value.user_nome;
                          }
                          if (value.user_hash == Cookies.get('crm_funil_hash')) {
                            var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                          }
                          array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                        });
                      } else {
                        array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                      }
                      array.push({ msGroup: false });
                    }
                  } else {
                    array.push({ name: value.gru_nome, msGroup: true });
                    if (value.usuarios.length != '') {
                      angular.forEach(value.usuarios, function(value, key) {
                        if (value.user_avatar == null) {
                          var icon =
                            '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                            '<div class="cell table">' +
                            '<div class="row">' +
                            '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                            '</span>' +
                            '</div>' +
                            '</div>' +
                            '</div>';
                        } else {
                          var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 0) {
                          var name = value.user_nome + ' (Inativo)';
                        } else {
                          name = value.user_nome;
                        }
                        if (value.user_hash == Cookies.get('crm_funil_hash')) {
                          var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                        }
                        array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                      });
                    } else {
                      array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                    }
                    array.push({ msGroup: false });
                  }

                });
              }
            }
            /* GRUPOS --- FIM*/

            if (vm.roleVisualiza == 1) {
              var representantes = $filter('filter')(usuarios, { fk_perfil_id: '5' });
              if (representantes != '') {
                array.push({ name: 'Representante', msGroup: true });
                angular.forEach(representantes, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });

              }
              var gerentesGerais = $filter('filter')(usuarios, { fk_perfil_id: '3' });
              if (gerentesGerais != '') {
                array.push({ name: 'Gerente Geral', msGroup: true });
                angular.forEach(gerentesGerais, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });
              }
            }

            if (vm.roleVisualiza == 3) {
              angular.forEach(usuarios, function(value, key) {
                if (value.user_hash == Cookies.get('crm_funil_hash')) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                }
              });
            }

            vm.selectUsuarios = array;

            vm.somente_users = $filter('filter')(vm.selectUsuarios, { slug: '' });
            //return null;
            vm.quantUsuarios = vm.somente_users.length;
            vm.fSelectAll();
          }
        }

        //TRADUÇÃO DO INPUT SELECT DE USUÁRIOS
        vm.ptbr = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar usuários'
        };

        vm.fSelectAll = function() {
          vm.novoTodos = [];
          angular.forEach(vm.somente_users, function(value, key) {
            vm.novoTodos.push(value.slug);
            vm.usuarioSelecionado = vm.novoTodos.join();
          });
          if (vm.novoTodos.length == vm.quantUsuarios) {
            vm.usuarioSelecionado = 'todos';
          }
        };

        vm.fClick = function(data) {
          vm.usuarioSelecionado = [];
          vm.novo = [];
          angular.forEach(vm.selectedUsuarios, function(value, key) {
            vm.novo.push(value.slug);
            vm.usuarioSelecionado = vm.novo.join();
          });
        };

        vm.fSelectNone = function() {
          vm.novoTodos = [];
          angular.forEach(vm.selectUsuarios, function(value, key) {
            vm.novoTodos.splice(key);
            vm.usuarioSelecionado = vm.novoTodos.join();
          });
          if (vm.novoTodos.length == 0) {
              vm.usuarioSelecionado = 'não selecionado';
          }
        };

        vm.expandirAcc = function() { //EXPANDIR ACCORDION
          $('.box_filtrar_atividades').slideToggle();
        };

        vm.acc_chamadas = function(id) {
            $timeout(function() {
                $('.box_lista_chamadas_' + id).slideToggle();
                $('.seta_acc_chamada_' + id).toggleClass('seta_ativa');
            });
        };

        //vm.nomeColuna = 'nome';
        //vm.reverse = false;
        vm.nomeColuna = 'valor_ganhas_user';
        vm.nomeColuna2 = 'valor_criadas_user';
        vm.nomeColuna3 = 'valor_perdidas_user';
        vm.reverse = true;
        vm.sortBy2 = function(nomeColuna) {
          vm.reverse = (vm.nomeColuna === nomeColuna) ? !vm.reverse : true;
          vm.nomeColuna = nomeColuna;
        };

        vm.imprimir = function() {
          window.print();
        };        

        vm.listar = function(){

          if ($routeParams) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
              vm.saasid = items;
              vm.tituloPrincipal = 'Extrato de chamadas';
              vm.tituloFiltro = 'Extrato de chamadas';
              vm.subtitulo = 'Extrato de chamadas';
              vm.usuariosSelecionados = $routeParams.usuarios;

              var dados = {
                'saasid': vm.saasid,
                'slug_usuarios': vm.usuariosSelecionados
              };
              if ($routeParams.inicio) { // PERIODO SELECIONADO
                vm.inicio = $routeParams.inicio;
                vm.fim = $routeParams.fim;
                vm.inicioExibir = vm.inicio.replace('_', '/').replace('_', '/');
                vm.fimExibir = vm.fim.replace('_', '/').replace('_', '/');
                dados.inicio = vm.inicio;
                dados.fim = vm.fim;
              }

              if (vm.usuariosSelecionados != 'todos'){
                relatoriosService.buscaUsuariosFiltro(dados).then(function(response) {
                  vm.usuarios = response.data;
                }, function(error) {
                  console.log('Erro ', error);
                });
              } else {
                vm.usuarios = 'Todos';
              }

              dados.user_hash = Cookies.get('crm_funil_hash');
              relatoriosService.carregarExtratoChamadas(dados).then(function(response) {
                // console.log("carregarExtratoChamadas");
                // console.log(response.data);
                // return null;
                if (response.data != 'null' && response.data[0].lista.length != 0) {
                  vm.listagemChamadas = response.data[0].lista;
                  vm.totaisChamadas = response.data[0].totais[0];
                } else {
                  vm.listagemChamadas = '';
                  vm.totaisChamadas = '';
                }
                vm.carregando = false;
              }, function(error) {
                console.log('Erro: ', error);
              });
            });
          }
        }
        vm.listar();

        vm.playGravacao = function(url_gravacao, id_chamada, qual, rel){
            FactoryTotalVoice.playGravacao(url_gravacao, id_chamada, qual, rel);
        }

        vm.carregando = true;

        vm.acabou = function() {
          vm.carregando = false;
          vm.carregando_mais_inf = false;
        };

        vm.verificarNavegador = function() {
          // Opera 8.0+
          vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
          // Firefox 1.0+
          vm.isFirefox = typeof InstallTrigger !== 'undefined';
          // At least Safari 3+: "[object HTMLElementConstructor]"
          vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
          // Internet Explorer 6-11
          vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
          // Edge 20+
          vm.isEdge = !vm.isIE && !!window.StyleMedia;
          // Chrome 1+
          vm.isChrome = !!window.chrome && !!window.chrome.webstore;
          // Blink engine detection
          vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();

        vm.verifDadosUser = function() {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var localNeg = window.localStorage.getItem('isModPessoas');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          if (valLocalNeg.visualiza) {
            vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
          }
        }
        vm.verifDadosUser();

      }

  })();
