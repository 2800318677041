(function() {
    'use strict';

    angular
        .module('app')
        .factory('FactoryNotificacao', FactoryNotificacao);


    function FactoryNotificacao($http, $q, $location, RC, $routeParams, API, $window, $timeout, $log, APINode) {

        var vm = this; 

        var userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));                   

        // return function ⤵
        return {
            createNotificacao: createNotificacao
        };

        // set function ⤵        


        function createNotificacao(objNotificacao) {
            var obj = {
              "idUser": objNotificacao.idUser,
              "origem": objNotificacao.origem,
              "mensagem": objNotificacao.mensagem,
            }
            if (objNotificacao.link) {
              obj.link = objNotificacao.link;
            }

            // console.log('[obj notificação dentro da factory] ',obj)

            var BaseApi = APINode.url + 'token/';
            var localSaas = userAPI['0'].saas_id;
            var urlEndpoint = BaseApi + localSaas;

            $http.get(urlEndpoint).then(function(res) {
                if (res.data.data) {

                    var staticTokenFunil = res.data.data.api_token;

                    $http({
                      method: 'POST',
                      url: APINode.url + "notification/novo",
                      data: obj,
                       headers: {
                          "TokenFunil": staticTokenFunil,
                          'Content-Type': 'application/json',
                          'Accept': 'application/json'
                      },
                      timeout: 4000
                   }).then(function (success) {
                      // console.log(success);
                   }, function (error) {
                      console.log(error);
                   });
         
                }
            });   
        }     
        
    }
}());
