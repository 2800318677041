/*
* @Author: Marcelo Seben
* @Date:   2018-03-23 11:00:00
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:52
*/

(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelOrigemContatoController', gerarRelOrigemContatoController);

    gerarRelOrigemContatoController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'PessoasService', 'produtosService', 'compromissosService', 'FactoryRoles', 'usuariosService', 'origemContatoService'];

    function gerarRelOrigemContatoController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas, PessoasService, produtosService, compromissosService, FactoryRoles, usuariosService, origemContatoService) {

        var Base64 = {
            _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
            encode: function(e) {
                var t = '';
                var n, r, i, s, o, u, a;
                var f = 0;
                e = Base64._utf8_encode(e);
                while (f < e.length) {
                    n = e.charCodeAt(f++);
                    r = e.charCodeAt(f++);
                    i = e.charCodeAt(f++);
                    s = n >> 2;
                    o = (n & 3) << 4 | r >> 4;
                    u = (r & 15) << 2 | i >> 6;
                    a = i & 63;
                    if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
                    t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
                }
                return t;
            },
            decode: function(e) {
                var t = '';
                var n, r, i;
                var s, o, u, a;
                var f = 0;
                e = e.replace(/[^A-Za-z0-9+/=]/g, '');
                while (f < e.length) {
                    s = this._keyStr.indexOf(e.charAt(f++));
                    o = this._keyStr.indexOf(e.charAt(f++));
                    u = this._keyStr.indexOf(e.charAt(f++));
                    a = this._keyStr.indexOf(e.charAt(f++));
                    n = s << 2 | o >> 4;
                    r = (o & 15) << 4 | u >> 2;
                    i = (u & 3) << 6 | a;
                    t = t + String.fromCharCode(n);
                    if (u != 64) { t = t + String.fromCharCode(r); }
                    if (a != 64) { t = t + String.fromCharCode(i); }
                }
                t = Base64._utf8_decode(t);
                return t;
            },
            _utf8_encode: function(e) {
                e = e.replace(/rn/g, 'n');
                var t = '';
                for (var n = 0; n < e.length; n++) {
                    var r = e.charCodeAt(n);
                    if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                        t += String.fromCharCode(r >> 6 | 192);
                        t += String.fromCharCode(r & 63 | 128);
                    } else {
                        t += String.fromCharCode(r >> 12 | 224);
                        t += String.fromCharCode(r >> 6 & 63 | 128);
                        t += String.fromCharCode(r & 63 | 128);
                    }
                }
                return t;
            },
            _utf8_decode: function(e) {
                var t = '';
                var n = 0;
                var c1 = 0;
                var c2 = 0;
                var r = c1 = c2 = 0;
                while (n < e.length) {
                    r = e.charCodeAt(n);
                    if (r < 128) {
                        t += String.fromCharCode(r);
                        n++;
                    } else if (r > 191 && r < 224) {
                        c2 = e.charCodeAt(n + 1);
                        t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                        n += 2;
                    } else {
                        c2 = e.charCodeAt(n + 1);
                        c3 = e.charCodeAt(n + 2);
                        t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                        n += 3;
                    }
                }
                return t;
            }
        };

        var vm = this;

        vm.url = API.url;

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.tituloPrincipal = 'Relatório de Origens do Contato';
        vm.subtitulo = 'Relatório de Origens do Contato';
        vm.carregando = true;

        vm.filtros = {
            'inicio': '',
            'fim': '',
            'tipo': '',
            'origens': ''
        };

        vm.carregarDados = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                vm.filtros.saasid = vm.saasid;
                var dados = {
                    'filtros': vm.filtros
                };
                if (FactoryRoles.checkRolesVisuFUNIL('isModPessoas')) {
                    relatoriosService.carregarDadosOrigemContato(dados).then(function(response) {
                        console.log("carregarDadosOrigemContato");
                        console.log(response.data);
                        if (response.data != '' && response.data != 0) {
                            vm.listagemOrigemContato = response.data;
                        } else {
                            vm.listagemOrigemContato = '';
                        }
                        vm.carregando = false;
                    }, function(error) {
                        console.error(error);
                    });
                } else {
                    vm.carregando = false;
                }
            });
        };

        vm.buscaOrigensFiltro = function(origens) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.origensFiltro = [];
                var dados = {
                    'origens': origens,
                    'saasid':items
                }
                relatoriosService.buscaOrigensFiltro(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.origensFiltro = response.data;
                    } else {
                        vm.origensFiltro = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.unidades_odontotop = [];
        vm.buscaUnidadesOdontoTop = function(name) {
            PessoasService.findUnidadesOdontoTop().then(function(response) {
                // console.log("buscaUnidadesOdontoTop");
                // console.log(response.data);
                if ($routeParams.unidade_odontotop != 'todas') {
                    angular.forEach(response.data, function(value, key) {
                        if (value.id == $routeParams.unidade_odontotop){
                            vm.unidade_odontotop = value.name;
                        }
                    });
                } else {
                    vm.unidade_odontotop = "Todas";
                }
            }, function(error) {
                $log.error(error);
            });
        };

        if ($routeParams) {
            vm.tipo = $routeParams.tipo;
            if ($routeParams.origens) {
                var origens = $routeParams.origens;
                //vm.filtros.origens = origens.split('-');
                vm.filtros.origens = origens;
                vm.buscaOrigensFiltro($routeParams.origens);
            }
            if ($routeParams.tipo) {
                vm.filtros.tipo = $routeParams.tipo;
            }
            if ($routeParams.inicio) { // PERIODO SELECIONADO
                vm.filtros.inicio = $routeParams.inicio;
                vm.filtros.fim = $routeParams.fim;
                vm.inicioExibir = vm.filtros.inicio.replace('_', '/').replace('_', '/');
                vm.fimExibir = vm.filtros.fim.replace('_', '/').replace('_', '/');
            }
            if ($routeParams.unidade_odontotop) {
                vm.filtros.unidade = $routeParams.unidade_odontotop;
                // vm.unidade_odontotop = $routeParams.unidade_odontotop;
                vm.buscaUnidadesOdontoTop();
            }
            vm.carregarDados();
        }

        vm.detalharComp = function(qual) {
            $(".txt_" + qual).toggleClass("hide");
            $(".link_" + qual).toggleClass("clicado");
            $(".lista_" + qual).slideToggle();
        }

        vm.detalharAtd = function(qual) {
            $(".txt_" + qual).toggleClass("hide");
            $(".link_" + qual).toggleClass("clicado");
            $(".lista_" + qual).slideToggle();
        }

        vm.acabou = function() {
            $timeout(function() {
                $('[data-toggle="tooltip"]').tooltip();
            }, 200);
        }

        vm.acc_produtos = function(prod_id) {
            $timeout(function() {
                $('.box_dados_neg_' + prod_id).slideToggle();
                $('.seta_acc_produto_' + prod_id).toggleClass('seta_ativa');
            });
        };

        vm.expandirAcc = function() {
            $('.box_filtrar_atividades').slideToggle();
        };

        /*ORIGEM --- INÍCIO*/
        vm.buscarOrigens = function(slug_comp) {
            vm.origensRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                };
                origemContatoService.findAll(dados).then(function(response) {
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.origensRepeat.push({ 'name': value.orc_nome, 'ticked': true, 'id': value.orc_id });
                        });
                        //vm.origensRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhuma origem', 'ticked': true, 'id': 'nenhum' });
                        vm.quantOrigem = vm.origensRepeat.length;
                        vm.selecionadosInicioOrigem = [];
                        angular.forEach(vm.origensRepeat, function(value, key) {
                            vm.selecionadosInicioOrigem.push(value.id);
                            vm.origemSelecionado = vm.selecionadosInicioOrigem.join();
                        });
                        vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
                    } else {
                        vm.origensRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        }
        /*ORIGEM --- FIM*/


        /*FILTROS --- INÍCIO*/
        vm.periodo = '';
        vm.periodo_inicio = '';
        vm.periodo_fim = '';

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.rangePeriodo = function() {
            function cb(start, end) {
                if (start._isValid) { // datas validas
                    vm.periodo_inicio = start.format('DD_MM_YYYY');
                    vm.periodo_fim = end.format('DD_MM_YYYY');;
                } else {

                }
            }
            $('.campo_periodo').daterangepicker({
                //autoUpdateInput: false,
                'opens': 'center',
                'locale': {
                    'format': 'DD/MM/YYYY',
                    'cancelLabel': 'Limpar'
                },
                ranges: {
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            });
            //}, cb);
            $('.campo_periodo').on('apply.daterangepicker', function(ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
                vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
            });

            $('.campo_periodo').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                vm.periodo = '';
                vm.periodo_inicio = '';
                vm.periodo_fim = '';
            });
        };

        vm.url_img = API.url;

        vm.ptbr = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar origens'
        };

        vm.fSelectAll = function(qual) {
            if (qual == 'origem') {
                vm.novoOrigem = [];
                angular.forEach(vm.origensRepeat, function(value, key) {
                    vm.novoOrigem.push(value.slug);
                    vm.origemSelecionado = vm.novoOrigem.join();
                });
                vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
            }
        };

        vm.fClick = function(data, qual) {
            if (qual == 'origem') {
                vm.origemSelecionado = [];
                vm.novoOrigem = [];
                angular.forEach(vm.selectedOrigem, function(value, key) {
                    vm.novoOrigem.push(value.id);
                    vm.origemSelecionado = vm.novoOrigem.join();
                });
                vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
            }
        };

        vm.fSelectNone = function(qual) {
            if (qual == 'origem') {
                vm.novoTodosOrigem = [];
                angular.forEach(vm.origensRepeat, function(value, key) {
                    vm.novoTodosOrigem.splice(key);
                    vm.origemSelecionado = vm.novoTodosOrigem.join();
                });
                vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
            }
        };

        vm.todosNenhumOrigem = function(array, qual) {
            if (array) {
                if (array != '') {
                    var array = array.split(',');
                } else {
                    var array = '';
                }
            }
            if (array.length == vm.quantOrigem) {
                vm.origemSelecionado = 'todos';
            } else if (array.length == '') {
                vm.origemSelecionado = 'nenhum';
            }
            vm.origemSelecionado = Base64.encode(vm.origemSelecionado);
        };
        /*FILTROS --- FIM*/


        vm.imprimir = function() {
            window.print();
        };

        vm.verificarNavegador = function() {
            // Opera 8.0+
            vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
            // Firefox 1.0+
            vm.isFirefox = typeof InstallTrigger !== 'undefined';
            // At least Safari 3+: "[object HTMLElementConstructor]"
            vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // Internet Explorer 6-11
            vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
            // Edge 20+
            vm.isEdge = !vm.isIE && !!window.StyleMedia;
            // Chrome 1+
            vm.isChrome = !!window.chrome && !!window.chrome.webstore;
            // Blink engine detection
            vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();
    }
})();