(function() {
    'use strict';

    angular
        .module('app')
        .factory('FactoryRoles', FactoryRoles);


    function FactoryRoles($http, $q, $location, RC, $routeParams, API, $window, $timeout, $log) {

        var vm = this;
        vm.roles = [];
        vm.rolesAcessos = [];

        // return function ⤵
        return {
            setRoles: setRoles,
            loadRoles: loadRoles,
            isModPessoas: isModPessoas,
            isModNegociacao: isModNegociacao,
            isModFunil: isModFunil,
            isModCompromissos: isModCompromissos,
            //isModRelatorios: isModRelatorios,
            isModConfig: isModConfig,
            attRoles: attRoles,
            checkRolesUrl: checkRolesUrl,
            checkRolesUrlFUNIL: checkRolesUrlFUNIL,
            checkRolesStorage: checkRolesStorage,
            checkRolesVisu: checkRolesVisu,
            checkRolesVisuFUNIL: checkRolesVisuFUNIL,
            checkRolesVisuRel: checkRolesVisuRel,
            checkRolesExclui: checkRolesExclui,
            checkRolesAltera: checkRolesAltera,
            checkRolesCria: checkRolesCria,
            checkRolesUrlCria: checkRolesUrlCria,
            isGestORGerGeral: isGestORGerGeral,
            icangroup: icangroup,
            visuGroupCargoSelec: visuGroupCargoSelec,
            checkAtivo: checkAtivo
        };

        // set function ⤵

        function setRoles(roles) {
            vm.roles = roles;
            vm.rolesAcessos = vm.roles;
        };


        function isModPessoas() {
            //filtro um unico módulo
            var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                return modAtual.mod_id == 1;
            });

            if (modAtual.length !== 0) {
                var objRol = {
                    'acesso': true,
                    'cria': modAtual[0].permissoes[0].rol,
                    'visualiza': modAtual[0].permissoes[1].rol,
                    'altera': modAtual[0].permissoes[2].rol,
                    'exclui': modAtual[0].permissoes[3].rol
                };
                var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                    $window.localStorage.setItem('isModPessoas', RC.funilRC.encrypt('false'));
                    return false;

                } else {
                    $window.localStorage.setItem('isModPessoas', objRC);
                    return true;
                }
            }

        }

        function isModNegociacao() {
            //filtro um unico módulo
            var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                return modAtual.mod_id == 2;
            });

            if (modAtual.length !== 0) {
                var objRol = {
                    'acesso': true,
                    'cria': modAtual[0].permissoes[0].rol,
                    'visualiza': modAtual[0].permissoes[1].rol,
                    'altera': modAtual[0].permissoes[2].rol,
                    'exclui': modAtual[0].permissoes[3].rol
                };
                var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                    $window.localStorage.setItem('isModNegociacao', RC.funilRC.encrypt('false'));
                    return false;
                } else {
                    $window.localStorage.setItem('isModNegociacao', objRC);
                    return true;
                }
            }
        }

        function isModFunil() {
            //filtro um unico módulo
            var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                return modAtual.mod_id == 3;
            });

            if (modAtual.length !== 0) {
                var objRol = {
                    'acesso': true,
                    'cria': modAtual[0].permissoes[0].rol,
                    'visualiza': modAtual[0].permissoes[1].rol,
                    'altera': modAtual[0].permissoes[2].rol,
                    'exclui': modAtual[0].permissoes[3].rol
                };
                var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                    $window.localStorage.setItem('isModFunil', RC.funilRC.encrypt('false'));
                    return false;
                } else {
                    $window.localStorage.setItem('isModFunil', objRC);
                    return true;
                }
            }
        }

        function isModCompromissos() {
            //filtro um unico módulo
            var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                return modAtual.mod_id == 4;
            });

            if (modAtual.length !== 0) {
                var objRol = {
                    'acesso': true,
                    'cria': modAtual[0].permissoes[0].rol,
                    'visualiza': modAtual[0].permissoes[1].rol,
                    'altera': modAtual[0].permissoes[2].rol,
                    'exclui': modAtual[0].permissoes[3].rol
                };
                var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                    $window.localStorage.setItem('isModCompromissos', RC.funilRC.encrypt('false'));
                    return false;
                } else {
                    $window.localStorage.setItem('isModCompromissos', objRC);
                    return true;
                }
            }
        }

        /*function isModRelatorios() {
            //filtro um unico módulo
            var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                return modAtual.mod_id == 5;
            });

            if (modAtual.length !== 0) {
                var objRol = {
                    'acesso': true,
                    'cria': modAtual[0].permissoes[0].rol,
                    'visualiza': modAtual[0].permissoes[1].rol,
                    'altera': modAtual[0].permissoes[2].rol,
                    'exclui': modAtual[0].permissoes[3].rol
                };
                var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                    $window.localStorage.setItem('isModRelatorios', RC.funilRC.encrypt('false'));
                    return false;
                } else {
                    $window.localStorage.setItem('isModRelatorios', objRC);
                    return true;
                }
            }
        }*/

        function isModConfig() {
            //filtro um unico módulo
            var modAtual = vm.rolesAcessos.filter(function(modAtual) {
                return modAtual.mod_id == 6;
            });

            if (modAtual.length !== 0) {
                var objRol = {
                    'acesso': true,
                    'cria': modAtual[0].permissoes[0].rol,
                    'visualiza': modAtual[0].permissoes[1].rol,
                    'altera': modAtual[0].permissoes[2].rol,
                    'exclui': modAtual[0].permissoes[3].rol
                };

                var objRC = RC.funilRC.encrypt(JSON.stringify(objRol));

                if (modAtual[0].permissoes[0].rol == 4 && modAtual[0].permissoes[1].rol == 4) {
                    $window.localStorage.setItem('isModConfig', RC.funilRC.encrypt('false'));
                    return false;
                } else {
                    $window.localStorage.setItem('isModConfig', objRC);
                    return true;
                }
            }
        }

        function loadRoles() {
            var hash = {
                'hash': Cookies.get('crm_funil_hash')
            };

            $http.post('login/service.php?f=loadRoles', hash).then(function(response) {
                if (response.data != '' && response.data != null) {
                    setRoles(response.data.acessos);
                    isModPessoas();
                    isModNegociacao();
                    isModFunil();
                    isModCompromissos();
                    //isModRelatorios();
                    isModConfig();
                }
            });
        }

        function attRoles() {
            isModPessoas();
            isModNegociacao();
            isModFunil();
            isModCompromissos();
            //isModRelatorios();
            isModConfig();
        }

        function checkRolesUrl(mod) {
            attRoles();

            var modRC = RC.funilRC.decrypt($window.localStorage.getItem(mod));

            if (modRC == 'false') {
                $location.path('/');
                alertify.set('notifier', 'delay', 10);
                alertify.set('notifier', 'position', 'top-right');
                alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                return false;
            } else {
                return true;
            }
        }

        function checkRolesUrlFUNIL(mod) {
            attRoles();

            var modRC = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var rolesStorage = JSON.parse(modRC);
            if (rolesStorage.visualiza == 4) {
                $location.path('/');
                alertify.set('notifier', 'delay', 10);
                alertify.set('notifier', 'position', 'top-right');
                alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                return false;
            } else {
                return true;
            }
        }

        function checkRolesStorage(mod) { //check at roles in LocalStorage and return 
            attRoles();

            var modRC = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var rolesStorage = JSON.parse(modRC);
            return rolesStorage;
        }

        function checkRolesVisu(mod, rota) {
            attRoles();

            var modVisu = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var visuRolesStorage = JSON.parse(modVisu);
            var deferred = $q.defer();

            //$timeout(function() {
            switch (visuRolesStorage.visualiza) {
                case '1':
                    return true;
                    break;
                case '2':

                    var dadosPut = [];
                    var userLoc = RC.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                    var dadosUser = JSON.parse(userLoc);
                    dadosPut.push(dadosUser[0]);

                    var rolIdDecry = RC.funilRC.decrypt($window.localStorage.getItem(mod));
                    var rolStorageObj = JSON.parse(rolIdDecry);
                    var rolID = rolStorageObj.visualiza;
                    dadosPut.push(rolID);

                    // removido os timeout que tinha na task 'FUNIL-726-perfil-gerente-nao-consegue-vi'

                    // verifica slug por modulo:
                    if (rota.slugNegocio) { // NEGOCIOS                            
                        vm.routParUser = rota.slugNegocio;
                        $http.put(API.url + 'negocios/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                            .then(function(response) {
                                if (response.data == 0) {
                                    //$location.path('/');
                                    deferred.resolve($location.path('/'));

                                    alertify.set('notifier', 'delay', 10);
                                    alertify.set('notifier', 'position', 'top-right');
                                    alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                    return false;
                                } else {
                                    return true;
                                };
                            }, function(error) {
                                $log.error(error);
                            });
                    } else if (rota.slugCompromisso) { // COMPROMISSOS
                        vm.routParUser = rota.slugCompromisso;
                        var testaURL = /\/pessoas\//;
                        var urlLocation = $location.url();
                        if (testaURL.test(urlLocation)) { // PESSOAS
                            $http.put(API.url + 'compromissos/service.php?t=podeVisualizarPessoa&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        deferred.resolve($location.path('/'));

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                }, function(error) {
                                    $log.error(error);
                                });
                        } else { // EMPRESAS
                            $http.put(API.url + 'compromissos/service.php?t=podeVisualizarEmpresa&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        deferred.resolve($location.path('/'));

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                }, function(error) {
                                    $log.error(error);
                                });
                        }
                    } else if (rota.slugCliente && mod != 'isModNegociacao' && mod != 'isModCompromissos') { //EMPRESAS OU PESSOAS
                        vm.routParUser = rota.slugCliente;
                        var testaURL = /\/pessoas\//;
                        var urlLocation = $location.url();

                        if (testaURL.test(urlLocation)) { // PESSOAS
                            $http.put(API.url + 'pessoas/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        //$location.path('/');
                                        deferred.resolve($location.path('/'));

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                }, function(error) {
                                    $log.error(error);
                                });
                        } else { // se for url empresas/info/
                            $http.put(API.url + 'empresas/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        $location.path('/');

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                }, function(error) {
                                    $log.error(error);
                                });
                        }
                    }

                    return true;
                    break;
                case '3':
                    var dadosPut = [];
                    var userLoc = RC.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                    var dadosUser = JSON.parse(userLoc);
                    dadosPut.push(dadosUser[0]);

                    var rolIdDecry = RC.funilRC.decrypt($window.localStorage.getItem(mod));
                    var rolStorageObj = JSON.parse(rolIdDecry);
                    var rolID = rolStorageObj.visualiza;
                    dadosPut.push(rolID);


                    if (rota.slugNegocio) { // NEGOCIOS
                        vm.routParUser = rota.slugNegocio;
                        $http.put(API.url + 'negocios/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                            .then(function(response) {
                                if (response.data == 0) {
                                    //$location.path('/');
                                    deferred.resolve($location.path('/'));

                                    alertify.set('notifier', 'delay', 10);
                                    alertify.set('notifier', 'position', 'top-right');
                                    alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                    return false;
                                } else {
                                    deferred.resolve();
                                    return true;
                                };
                            }, function(error) {
                                $log.error(error);
                            });

                    } else if (rota.slugCompromisso) { // COMPROMISSOS
                        vm.routParUser = rota.slugCompromisso;
                        var testaURL = /\/pessoas\//;
                        var urlLocation = $location.url();
                        if (testaURL.test(urlLocation)) { // PESSOAS
                            $http.put(API.url + 'compromissos/service.php?t=podeVisualizarPessoa&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        deferred.resolve($location.path('/'));

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                }, function(error) {
                                    $log.error(error);
                                });
                        } else { // EMPRESAS
                            $http.put(API.url + 'compromissos/service.php?t=podeVisualizarEmpresa&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        deferred.resolve($location.path('/'));

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                }, function(error) {
                                    $log.error(error);
                                });
                        }
                    } else if (rota.slugCliente && mod != 'isModNegociacao' && mod != 'isModCompromissos') { // EMPRESAS OU PESSOAS
                        vm.routParUser = rota.slugCliente;
                        var testaURL = /\/pessoas\//;
                        var urlLocation = $location.url();
                        if (testaURL.test(urlLocation)) { // PESSOAS
                            $http.put(API.url + 'pessoas/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        //$location.path('/');
                                        deferred.resolve($location.path('/'));

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                });
                        } else { // EMPRESAS
                            $http.put(API.url + 'empresas/service.php?t=podeVisualizar&slug=' + vm.routParUser, dadosPut)
                                .then(function(response) {
                                    if (response.data == 0) {
                                        //$location.path('/');
                                        deferred.resolve($location.path('/'));

                                        alertify.set('notifier', 'delay', 10);
                                        alertify.set('notifier', 'position', 'top-right');
                                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');

                                        return false;
                                    } else {
                                        return true;
                                    };
                                }, function(error) {
                                    $log.error(error);
                                });
                        }
                    }

                    return true;
                    break;
                case '4':
                    alertify.set('notifier', 'delay', 10);
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');


                    //$location.path('/');
                    deferred.resolve($location.path('/'));
                    return false;
                    break;
            }
            //}, 15);
            return deferred.promise;

        }

        function checkRolesExclui(mod) { //use for btn | ng-if etc..
            attRoles();

            var modExclui = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var excluiRolesStorage = JSON.parse(modExclui);

            switch (excluiRolesStorage.exclui) {
                case '1':
                    return true;
                    break;
                case '2':
                    return true;
                case '3':
                    return true;
                    break;
                case '4':
                    return false;
                    break;
            }
        }

        function checkRolesAltera(mod) { //use for btn | ng-if  etc..
            attRoles();

            var modAltera = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var excluiRolesStorage = JSON.parse(modAltera);

            switch (excluiRolesStorage.altera) {
                case '1':
                    return true;
                    break;
                case '2':
                    return true;
                    break;
                case '3':
                    return true;
                    break;
                case '4':
                    return false;
                    break;
            }
        }

        function checkRolesCria(mod) {
            attRoles();

            var modCria = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var criaRoleStorage = JSON.parse(modCria);

            switch (criaRoleStorage.cria) {
                case '1':
                    return true;
                    break;
                case '2':
                    return true;
                    break;
                case '3':
                    return true;
                    break;
                case '4':
                    return false;
                    break;

            }
        }

        function checkRolesVisuFUNIL(mod) {
            attRoles();

            var modVisu = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var criaRoleStorage = JSON.parse(modVisu);

            switch (criaRoleStorage.visualiza) {
                case '1':
                    return true;
                    break;
                case '2':
                    return true;
                    break;
                case '3':
                    return true;
                    break;
                case '4':
                    return false;
                    break;

            }
        }

        function checkRolesVisuRel(mod) {
            attRoles();

            var modVisu = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var criaRoleStorage = JSON.parse(modVisu);

            switch (criaRoleStorage.visualiza) {
                case '1':
                    return true;
                    break;
                case '2':
                    return true;
                    break;
                case '3':
                    return true;
                    break;
                case '4':
                    return false;
                    break;

            }
        }

        function checkRolesUrlCria(mod) {
            attRoles();

            var modCria = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var criaRoleStorage = JSON.parse(modCria);

            if (criaRoleStorage.cria == 4) {
                $location.path('/');
                alertify.set('notifier', 'delay', 10);
                alertify.set('notifier', 'position', 'top-right');
                alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/por-que-nao-consigo-acessar-algumas-informacoes/" target="_blank">Clique aqui para saber mais</a>');
            } else {
                return true;
            }
        }

        function isGestORGerGeral(dados) {
            var dadosRecebidosUser = dados.data[0].fk_perfil_id;

            if ((dadosRecebidosUser == '1') || (dadosRecebidosUser == '3')) {
                return true;
            } else {
                var testaURLUsuarios = /\/usuarios/;
                var testaURLGrupos = /\/usuarios\/grupo_usuario/;
                var testaURLPerfil = /\/usuarios\/perfis_acesso/;
                var testaURLIntegracao = /\/integracoes/;
                var testaURLMinhaConta = /\/minhaconta/;
                var urlLocation = $location.url();

                if ((urlLocation == '/usuarios') ||
                    (testaURLGrupos.test(urlLocation)) ||
                    (testaURLPerfil.test(urlLocation)) ||
                    (testaURLIntegracao.test(urlLocation)) ||
                    (testaURLMinhaConta.test(urlLocation))) {

                    alertify.set('notifier', 'delay', 10);
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! Não foi possível carregar a página. \n <a href="http://ajuda.funilpro.com.br/index.php/help/o-que-significa-a-mensagem-nao-foi-possivel-carregar-a-pagina/" target="_blank">Clique aqui para saber mais</a>');

                    $location.path('/');
                    return false;
                }
            }
        }

        function icangroup(mod) {

            var localConfGroup = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var localConfGroupParse = JSON.parse(localConfGroup);

            if (localConfGroupParse.cria == '4' ||
                localConfGroupParse == false ||
                localConfGroupParse == undefined ||
                localConfGroupParse == null) {
                return false;
            } else {
                return true;
            };
        }

        function visuGroupCargoSelec(mod) {
            var localVisuGroupCargoSelec = RC.funilRC.decrypt($window.localStorage.getItem(mod));
            var parseVisuGroupCargoSelec = JSON.parse(localVisuGroupCargoSelec);

            if (parseVisuGroupCargoSelec.visualiza == '4' ||
                parseVisuGroupCargoSelec == false ||
                parseVisuGroupCargoSelec == undefined ||
                parseVisuGroupCargoSelec == null) {
                return false;
            } else {
                return true;
            };
        }

        function checkAtivo(mod, rota) {
            var deferred = $q.defer();
            if (mod == 'isModNegociacao') {
                $http.put(API.url + 'negocios/service.php?t=verifNegAtivo&slug=' + rota.slugNegocio)
                    .then(function(response) {
                        if (response.data == 0) {
                            //deferred.resolve($location.path('/404'));
                            if ($window.history.length > 1){
                                deferred.resolve($window.history.back());
                            } else {
                                deferred.resolve($location.path('/'));
                            }
                            return false;
                        } else {
                            deferred.resolve();
                            return true;
                        };
                    }, function(error) {
                        $log.error(error);
                    });
            } else if (mod == 'isModCompromissos') {
                var testaURL = /\/pessoas\//;
                var urlLocation = $location.url();
                var qual = '';
                if (testaURL.test(urlLocation)) { // PESSOAS
                    var qual = 'p';
                } else {
                    var qual = 'e';
                }
                $http.put(API.url + 'compromissos/service.php?t=verifCompAtivo&slug=' + rota.slugCompromisso + '&qual='+ qual)
                    .then(function(response) {
                        if (response.data == 0) {
                            //deferred.resolve($location.path('/404'));
                            if ($window.history.length > 1){
                                deferred.resolve($window.history.back());
                            } else {
                                deferred.resolve($location.path('/'));
                            }
                            return false;
                        } else {
                            deferred.resolve();
                            return true;
                        };
                    }, function(error) {
                        $log.error(error);
                    });
            }
            return deferred.promise;
        }


        //  ------ roles ------
        // se criar e visualizar for 4, não tem acesso
        //  ------ Encrypt e Decrypt ------
        // RC.funilRC.encrypt('') encrypt object
        // RC.funilRC.decrypt('') decrypt object
        //  ------ LocalStorage ------
        // var teste = $window.localStorage.getItem('isModPessoas');             
        // $log.debug(JSON.parse(teste));
        // sendo que ao gravar e ao ler passa pelo Encrypt e Decrypt
    }
}());
