(function() {
    'use strict';

    angular
        .module('relatorios')
        .controller('gerarRelMotivosPerdasController', gerarRelMotivosPerdasController)


    gerarRelMotivosPerdasController.$inject = ['$routeParams', 'mensagem', '$location', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'FactoryRoles', 'relatoriosService'];

    function gerarRelMotivosPerdasController($routeParams, mensagem, $location, $http, API, $filter, $timeout, loadSaas, validarSaas, FactoryRoles, relatoriosService) {
        
        var Base64 = {
            _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
            encode: function(e) {
                var t = '';
                var n, r, i, s, o, u, a;
                var f = 0;
                e = Base64._utf8_encode(e);
                while (f < e.length) {
                    n = e.charCodeAt(f++);
                    r = e.charCodeAt(f++);
                    i = e.charCodeAt(f++);
                    s = n >> 2;
                    o = (n & 3) << 4 | r >> 4;
                    u = (r & 15) << 2 | i >> 6;
                    a = i & 63;
                    if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
                    t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
                }
                return t;
            },
            decode: function(e) {
                var t = '';
                var n, r, i;
                var s, o, u, a;
                var f = 0;
                e = e.replace(/[^A-Za-z0-9+/=]/g, '');
                while (f < e.length) {
                    s = this._keyStr.indexOf(e.charAt(f++));
                    o = this._keyStr.indexOf(e.charAt(f++));
                    u = this._keyStr.indexOf(e.charAt(f++));
                    a = this._keyStr.indexOf(e.charAt(f++));
                    n = s << 2 | o >> 4;
                    r = (o & 15) << 4 | u >> 2;
                    i = (u & 3) << 6 | a;
                    t = t + String.fromCharCode(n);
                    if (u != 64) { t = t + String.fromCharCode(r); }
                    if (a != 64) { t = t + String.fromCharCode(i); }
                }
                t = Base64._utf8_decode(t);
                return t;
            },
            _utf8_encode: function(e) {
                e = e.replace(/rn/g, 'n');
                var t = '';
                for (var n = 0; n < e.length; n++) {
                    var r = e.charCodeAt(n);
                    if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                        t += String.fromCharCode(r >> 6 | 192);
                        t += String.fromCharCode(r & 63 | 128);
                    } else {
                        t += String.fromCharCode(r >> 12 | 224);
                        t += String.fromCharCode(r >> 6 & 63 | 128);
                        t += String.fromCharCode(r & 63 | 128);
                    }
                }
                return t;
            },
            _utf8_decode: function(e) {
                var t = '';
                var n = 0;
                var c1 = 0;
                var c2 = 0;
                var r = c1 = c2 = 0;
                while (n < e.length) {
                    r = e.charCodeAt(n);
                    if (r < 128) {
                        t += String.fromCharCode(r);
                        n++;
                    } else if (r > 191 && r < 224) {
                        c2 = e.charCodeAt(n + 1);
                        t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                        n += 2;
                    } else {
                        c2 = e.charCodeAt(n + 1);
                        c3 = e.charCodeAt(n + 2);
                        t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                        n += 3;
                    }
                }
                return t;
            }
        };
        
        var vm = this;

        vm.url = API.url;

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.tituloPrincipal = 'Relatório de Motivos de Perdas';
        vm.subtitulo = 'Relatório de Motivos de Perdas';
        vm.carregando = true;

        vm.filtros = {
            'saasid': '',
            'produtos': [],
            'situacoes': [],
            'inicio': '',
            'fim': ''
        };

        vm.carregarDados = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                if ($routeParams.inicio) { // PERIODO SELECIONADO
                    vm.filtros.inicio = $routeParams.inicio;
                    vm.filtros.fim = $routeParams.fim;
                    vm.inicioExibir = vm.filtros.inicio.replace('_', '/').replace('_', '/');
                    vm.fimExibir = vm.filtros.fim.replace('_', '/').replace('_', '/');
                    dados.inicio = $routeParams.inicio;
                    dados.fim = $routeParams.fim;
                }

                if (FactoryRoles.checkRolesVisuFUNIL('isModNegociacao')) {
                    relatoriosService.carregarDadosMotivosPerdas(dados).then(function(response) {
                        /*console.log("carregarDadosMotivosPerdas");
                        console.log(response.data);*/
                        if (response.data != '' && response.data != 0) {
                            vm.listagemMotivos = response.data;
                            vm.graficod3pie(vm.listagemMotivos);
                        } else {
                            vm.listagemMotivos = '';
                        }
                        vm.carregando = false;
                    }, function(error) {
                        console.error(error);
                    });
                } else {
                    vm.carregando = false;
                    vm.naoMostrar = true;
                }
            });
        };
        vm.carregarDados();

        vm.periodo_inicio = '';

        vm.expandirAcc = function() {
            $('.box_filtrar_produtos').slideToggle();
        };

        vm.rangePeriodo = function() {
            function cb(start, end) {
                if (start._isValid) { // datas validas
                    vm.periodo_inicio = start.format('DD_MM_YYYY');
                    vm.periodo_fim = end.format('DD_MM_YYYY');;
                } else {

                }
            }
            $('.campo_periodo').daterangepicker({
                //autoUpdateInput: false,
                'opens': 'center',
                'locale': {
                    'format': 'DD/MM/YYYY',
                    'cancelLabel': 'Limpar'
                },
                ranges: {
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            });
            //}, cb);
            $('.campo_periodo').on('apply.daterangepicker', function(ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
                vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
            });

            $('.campo_periodo').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                vm.periodo = '';
                vm.periodo_inicio = '';
                vm.periodo_fim = '';
            });
        };

        function number_format(numero, decimal, decimal_separador, milhar_separador) {
            numero = (numero + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+numero) ? 0 : +numero,
                prec = !isFinite(+decimal) ? 0 : Math.abs(decimal),
                sep = (typeof milhar_separador === 'undefined') ? '.' : milhar_separador,
                dec = (typeof decimal_separador === 'undefined') ? ',' : decimal_separador,
                s = '',
                toFixedFix = function(n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
            // Fix para IE: parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }

            return s.join(dec);
        }

        vm.acc_motivos = function(id) {
          $timeout(function() {
            $('.box_dados_neg_' + id).slideToggle();
            $('.seta_acc_produto_' + id).toggleClass('seta_ativa');
          });
        };

        vm.graficoNaoZerado = false;
        vm.graficod3pie = function(dados) {

            var randomColorFactor = function() {
                return Math.round(Math.random() * 255);
            };
            
            var preencher = [];
            angular.forEach(dados, function(value, key) {
                if (value.total_neg > 0){
                    vm.graficoNaoZerado = true;
                }
                preencher.push({
                    'label': value.descricao,
                    'value': parseInt(value.total_neg),
                    'color': 'rgb(' + randomColorFactor() + ',' + randomColorFactor() + ',' + randomColorFactor()+')'
                });
            });

            $timeout(function() {

                if (vm.graficoNaoZerado) {
                    var largura = angular.element(".box_graficos").width();

                    var pie = new d3pie('canvas_grafico_motivos', {
                        'header': {
                            'title': {
                                'text': 'Motivos de Perdas',
                                'fontSize': 14,
                                'font': 'open sans'
                            },
                        },
                        'size': {
                            'canvasWidth': largura,
                            'canvasHeight': 360,
                            'pieInnerRadius': '0',
                            'pieOuterRadius': '75%'
                        },
                        'data': {
                            'sortOrder': 'value-desc',
                            'content': preencher
                        },
                        'labels': {
                            'outer': {
                                'format': 'label',
                                'pieDistance': 20
                            },
                            /*'inner': {
                                //'format': 'none',
                                "hideWhenLessThanPercentage": 3
                            },*/
                            "percentage": {
                                "color": "#ffffff",
                                "fontSize": 11,
                                "decimalPlaces": 2
                            },
                            'mainLabel': {
                                'fontSize': 11
                            },
                            'value': {
                                'color': '#999999',
                                'fontSize': 11
                            },
                            'lines': {
                                'enabled': true,
                                'color': '#777777'
                            }
                        },
                        "tooltips": {
                            "enabled": true,
                            "type": "placeholder",
                            "string": "{label}: {value} |{percentage}%|"
                        },
                        'effects': {
                            'pullOutSegmentOnClick': {
                                'effect': 'linear',
                                'speed': 400,
                                'size': 8
                            },
                            'highlightSegmentOnMouseover': false,
                        }
                    });

                    // PRINT
                    var pie = new d3pie('canvas_grafico_motivos_print', {
                        'header': {
                            'title': {
                                'text': 'Motivos de Perdas',
                                'fontSize': 14,
                                'font': 'open sans'
                            },
                        },
                        'size': {
                            'canvasWidth': 565,
                            'canvasHeight': 320,
                            'pieInnerRadius': '0',
                            'pieOuterRadius': '75%'
                        },
                        'data': {
                            'sortOrder': 'value-desc',
                            'content': preencher
                        },
                        'labels': {
                            'outer': {
                                'format': 'label',
                                'pieDistance': 20
                            },
                            /*'inner': {
                                'format': 'none',
                            },*/
                            "percentage": {
                                "color": "#ffffff",
                                "fontSize": 11,
                                "decimalPlaces": 2
                            },
                            'mainLabel': {
                                'fontSize': 11
                            },
                            'value': {
                                'color': '#999999',
                                'fontSize': 11
                            },
                            'lines': {
                                'enabled': true,
                                'color': '#777777'
                            }
                        },
                        'effects': {
                            'pullOutSegmentOnClick': {
                                'effect': 'linear',
                                'speed': 400,
                                'size': 8
                            },
                            'highlightSegmentOnMouseover': false,
                        }
                    });
                }

            }, 100);

        };

        vm.imprimir = function() {
            window.print();
        };

        vm.imprimirGrafico = true;

        vm.toggleImprmirGrafico = function() {
            vm.imprimirGrafico = !vm.imprimirGrafico;
        };

        vm.mudarVisualizacao = function(visu) {
            $timeout(function() {
                vm.enviarVisualizando = Base64.encode(vm.visualizando);
            });
        }

        vm.carregando = true;

        // COMBOBOX VISUALIZACAO --- FIM

    }

})();