/*
* @Author: Glauber Funez
* @Date:   2018-03-19 09:34:38
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:52
*/

(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelLeaderboardController', gerarRelLeaderboardController)


  gerarRelLeaderboardController.$inject = ['$routeParams', 'mensagem', '$location', 'categoriasService', 'relatoriosService', 'negociosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas'];

  function gerarRelLeaderboardController($routeParams, mensagem, $location, categoriasService, relatoriosService, negociosService, $http, API, $filter, $timeout, loadSaas, validarSaas) {

        var vm = this;

        vm.url = API.url;
        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        var now = new Date;
        vm.anoatual = now.getFullYear();

        vm.periodo = '';
        vm.periodo_inicio = '';
        vm.periodo_fim = '';

        vm.options = { width: 100, height: 200, 'bar': 'bbb' };
        vm.data = [0, 2, 3];

        vm.adm = false;
        vm.verificarAdm = function() {
          categoriasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
            if (response.data == 'Administrador') {
              vm.adm = true;
            } else {
              vm.adm = false;
            }
          }, function(error) {
            console.log('Erro: ', error);
          });
        };

        vm.rangePeriodo = function() {
          function cb(start, end) {
            if (start._isValid) { // datas validas
              vm.periodo_inicio = start.format('DD_MM_YYYY');
              vm.periodo_fim = end.format('DD_MM_YYYY');;
            } else {

            }
          }
          $('.campo_periodo_neg').daterangepicker({
            //autoUpdateInput: false,
            'opens': 'center',
            'locale': {
              'format': 'DD/MM/YYYY',
              'cancelLabel': 'Limpar'
            },
            ranges: {
              'Hoje': [moment(), moment()],
              'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Há 7 dias': [moment().subtract(6, 'days'), moment()],
              'Há 30 dias': [moment().subtract(29, 'days'), moment()],
              'Este mês': [moment().startOf('month'), moment().endOf('month')],
              'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
          });
          //}, cb);
          $('.campo_periodo_neg').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
            vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
            vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
          });

          $('.campo_periodo_neg').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            vm.periodo = '';
            vm.periodo_inicio = '';
            vm.periodo_fim = '';
          });
        };

        vm.selectUsuarios = [];
        vm.selectedUsuarios = [];
        /*vm.buscaUsuarios = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
                    angular.forEach(response.data, function(value, key) {
                        if (value.user_avatar == null) {
                            var icon = '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                '<div class="cell table">' +
                                '<div class="row">' +
                                '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                '</span>' +
                                '</div>' +
                                '</div>' +
                                '</div>';
                        } else {
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 0) {
                            var name = value.user_nome + ' (Inativo)';
                        } else {
                            name = value.user_nome;
                        }
                        vm.selectUsuarios.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                    });
                    vm.selecionadosInicio = [];
                    angular.forEach(vm.selectUsuarios, function(value, key) {
                        vm.selecionadosInicio.push(value.slug);
                        vm.usuarioSelecionado = vm.selecionadosInicio.join();
                    });
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaUsuarios();*/
        vm.buscaUsuarios = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid
            };
            relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
              var array = [];

              var local = window.localStorage.getItem('5c479de2');
              var valLocal = JSON.parse(API.funilRC.decrypt(local));
              var localNeg = window.localStorage.getItem('isModNegociacao');
              var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

              vm.roleVisualiza = parseInt(valLocalNeg.visualiza);

              /* GESTOR --- INICIO*/
              if (vm.roleVisualiza == 1) {
                var gestores = $filter('filter')(response.data.usuarios, { fk_perfil_id: '1' });
                if (gestores != '') {
                  array.push({ name: "Gestor", msGroup: true });
                  angular.forEach(gestores, function(value, key) {
                    if (value.user_avatar == null) {
                      var icon =
                        '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                        '<div class="cell table">' +
                        '<div class="row">' +
                        '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                        '</span>' +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    } else {
                      var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                    }
                    if (value.user_ativo == 0) {
                      var name = value.user_nome + ' (Inativo)';
                    } else {
                      name = value.user_nome;
                    }
                    if (value.user_hash == Cookies.get('crm_funil_hash')) {
                      var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                    }
                    array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                  });
                  array.push({ msGroup: false });
                }
              }
              /* GESTOR --- FIM*/

              vm.montarSelectUsuarios(array, response.data.usuarios, response.data.grupos);
            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.buscaUsuarios();

        vm.montarSelectUsuarios = function(array, usuarios, grupos) {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var grupo_id = valLocal[0].fk_gru_id;
          var localNeg = window.localStorage.getItem('isModNegociacao');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
          if (vm.roleVisualiza != 4) {
            /* GRUPOS --- INICIO*/
            if (vm.roleVisualiza == 2 || vm.roleVisualiza == 1) {
              if (grupos != '') {
                angular.forEach(grupos, function(value, key) {
                  if (vm.roleVisualiza == 2) {
                    if (grupo_id == value.gru_id) {
                      array.push({ name: value.gru_nome, msGroup: true });
                      if (value.usuarios.length != '') {
                        angular.forEach(value.usuarios, function(value, key) {
                          if (value.user_avatar == null) {
                            var icon =
                              '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                              '<div class="cell table">' +
                              '<div class="row">' +
                              '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                              '</span>' +
                              '</div>' +
                              '</div>' +
                              '</div>';
                          } else {
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                          }
                          if (value.user_ativo == 0) {
                            var name = value.user_nome + ' (Inativo)';
                          } else {
                            name = value.user_nome;
                          }
                          if (value.user_hash == Cookies.get('crm_funil_hash')) {
                            var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                          }
                          array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                        });
                      } else {
                        array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                      }
                      array.push({ msGroup: false });
                    }
                  } else {
                    array.push({ name: value.gru_nome, msGroup: true });
                    if (value.usuarios.length != '') {
                      angular.forEach(value.usuarios, function(value, key) {
                        if (value.user_avatar == null) {
                          var icon =
                            '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                            '<div class="cell table">' +
                            '<div class="row">' +
                            '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                            '</span>' +
                            '</div>' +
                            '</div>' +
                            '</div>';
                        } else {
                          var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 0) {
                          var name = value.user_nome + ' (Inativo)';
                        } else {
                          name = value.user_nome;
                        }
                        if (value.user_hash == Cookies.get('crm_funil_hash')) {
                          var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                        }
                        array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                      });
                    } else {
                      array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                    }
                    array.push({ msGroup: false });
                  }

                });
              }
            }
            /* GRUPOS --- FIM*/

            if (vm.roleVisualiza == 1) {
              var representantes = $filter('filter')(usuarios, { fk_perfil_id: '5' });
              if (representantes != '') {
                array.push({ name: 'Representante', msGroup: true });
                angular.forEach(representantes, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });

              }
              var gerentesGerais = $filter('filter')(usuarios, { fk_perfil_id: '3' });
              if (gerentesGerais != '') {
                array.push({ name: 'Gerente Geral', msGroup: true });
                angular.forEach(gerentesGerais, function(value, key) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                });
                array.push({ msGroup: false });
              }
            }

            if (vm.roleVisualiza == 3) {
              angular.forEach(usuarios, function(value, key) {
                if (value.user_hash == Cookies.get('crm_funil_hash')) {
                  if (value.user_avatar == null) {
                    var icon =
                      '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                      '<div class="cell table">' +
                      '<div class="row">' +
                      '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                      '</span>' +
                      '</div>' +
                      '</div>' +
                      '</div>';
                  } else {
                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                  }
                  if (value.user_ativo == 0) {
                    var name = value.user_nome + ' (Inativo)';
                  } else {
                    name = value.user_nome;
                  }
                  if (value.user_hash == Cookies.get('crm_funil_hash')) {
                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                  }
                  array.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                }
              });
            }

            vm.selectUsuarios = array;

            vm.somente_users = $filter('filter')(vm.selectUsuarios, { slug: '' });
            //return null;
            vm.quantUsuarios = vm.somente_users.length;
            vm.selecionadosInicio = [];
            angular.forEach(vm.somente_users, function(value, key) {
              vm.selecionadosInicio.push(value.slug);
              vm.usuarioSelecionado = vm.selecionadosInicio.join();
            });
          }
        }

        //TRADUÇÃO DO INPUT SELECT DE USUÁRIOS
        vm.ptbr = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar usuários'
        };

        vm.fSelectAll = function() {
          vm.novoTodos = [];
          angular.forEach(vm.somente_users, function(value, key) {
            vm.novoTodos.push(value.slug);
            vm.usuarioSelecionado = vm.novoTodos.join();
          });
        };

        vm.fClick = function(data) {
          vm.usuarioSelecionado = [];
          vm.novo = [];
          angular.forEach(vm.selectedUsuarios, function(value, key) {
            vm.novo.push(value.slug);
            vm.usuarioSelecionado = vm.novo.join();
          });
        };

        vm.fSelectNone = function() {
          vm.novoTodos = [];
          angular.forEach(vm.selectUsuarios, function(value, key) {
            vm.novoTodos.splice(key);
            vm.usuarioSelecionado = vm.novoTodos.join();
          });
        };

        vm.expandirAcc = function() { //EXPANDIR ACCORDION
          $('.body_acc').slideToggle();
          $('.seta').toggleClass('fa-angle-down fa-angle-up');
        };

        vm.expandirAcc2 = function(user_slug) { //EXPANDIR ACCORDION
          $timeout(function() {
            $('.body_acc2_' + user_slug).fadeToggle();
            $('.seta_' + user_slug).toggleClass('fa-angle-down fa-angle-up');
          });
        };

        //vm.nomeColuna = 'nome';
        //vm.reverse = false;
        vm.nomeColuna = 'valor_ganhas_user';
        vm.nomeColuna2 = 'valor_criadas_user';
        vm.nomeColuna3 = 'valor_perdidas_user';
        vm.reverse = true;
        vm.sortBy2 = function(nomeColuna) {
          vm.reverse = (vm.nomeColuna === nomeColuna) ? !vm.reverse : true;
          vm.nomeColuna = nomeColuna;
        };

        vm.imprimir = function() {
          window.print();
        };

        vm.carregando_mais_inf = false;

        vm.limite = 30;

        vm.carregar_tudo = true;
        vm.maisinf = function() {
          vm.carregando_mais_inf = true;
          //vm.carregar_tudo = true;
          $timeout(function() {
            vm.limite += 30;
            vm.carregando_mais_inf = false;
          }, 500);
        };

        vm.selecionarTodas = true;

        vm.situacoes = [
          { name: 'Criadas', selecionado: true },
          { name: 'Perdidas', selecionado: true },
          { name: 'Ganhas', selecionado: true },
          { name: 'Abertas', selecionadoA: false },
          { name: 'Exibir Descontos', selecionadoA: false },
        ];

        var arrSituacoes = $routeParams.situacoes.split('-');
        vm.mostrarMedDescontos = arrSituacoes.filter( function(elem) {
          return elem == 'Exibir Descontos';
        } )

        if (vm.mostrarMedDescontos.length) {
          vm.situacoes[4].selecionadoA = true;
        }

        vm.desabilitar = true;

        vm.mudar = function(qual, item) {
          if (qual) {
            vm.desabilitar = true;
            vm.situacoesSelecionadas = function() {
              return 'Criadas-Ganhas-Perdidas';
            };
            angular.forEach(vm.situacoes, function(item) {
              item.selecionado = true;
            });
          } else {
            vm.situacoesSelecionadas = function() {
              return vm.situacoes.filter(function(item) {
                return item.selecionado;
              }).map(function(item) {
                return item.name;
              }).join('-');
            };
            angular.forEach(vm.situacoes, function(item) {
              item.selecionado = false;
            });
            vm.desabilitar = false;
          }
        };

        vm.situacoesSelecionadas = function() {
          return vm.situacoes.filter(function(item) {
            return item.selecionado;
          }).map(function(item) {
            return item.name;
          }).join('-');
        };

        vm.situacoesSelecionadasA = function() {
          return vm.situacoes.filter(function(item) {
            return item.selecionadoA;
            item.selecionadoA;
          }).map(function(item) {
            return item.name;
          }).join('-');
        };

        angular.forEach(arrSituacoes, function(value) {
          if (value.match(/Abertas/)) {
            vm.abertas = value;
          } else if (value == 'Criadas') {
            vm.criadas = value;
          } else if (value == 'Perdidas') {
            vm.perdidas = value;
          } else if (value == 'Ganhas') {
            vm.ganhas = value;
          } else if (value == 'Ganhas, Perdidas, Criadas') {
            vm.todas = 'Ganhas, Perdidas, Criadas';

          }
        });

        if ($routeParams) {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            vm.tituloPrincipal = 'Leaderboard';
            vm.tituloFiltro = 'Leaderboard';
            vm.subtitulo = 'Leaderboard';
            vm.usuariosSelecionados = $routeParams.usuarios;
            vm.criadasExibir = vm.criadas;
            vm.perdidasExibir = vm.perdidas;
            vm.ganhasExibir = vm.ganhas;
            vm.abertasExibir = vm.abertas;
            vm.todasExibir = vm.todas;
            if ($routeParams.inicio) { // PERIODO SELECIONADO
              vm.inicio = $routeParams.inicio;
              vm.fim = $routeParams.fim;
              vm.inicioExibir = vm.inicio.replace('_', '/').replace('_', '/');
              vm.fimExibir = vm.fim.replace('_', '/').replace('_', '/');
              var dados = {
                'saasid': vm.saasid,
                'slug_usuarios': vm.usuariosSelecionados,
                'criadas': vm.criadasExibir,
                'ganhas': vm.ganhasExibir,
                'perdidas': vm.perdidasExibir,
                'todas': vm.todasExibir,
                'abertas': vm.abertasExibir,
                'inicio': vm.inicio,
                'fim': vm.fim
              };
            } else {
              var dados = {
                'saasid': vm.saasid,
                'slug_usuarios': vm.usuariosSelecionados,
                'criadas': vm.criadasExibir,
                'ganhas': vm.ganhasExibir,
                'perdidas': vm.perdidasExibir,
                'todas': vm.todasExibir,
                'abertas': vm.abertasExibir
              };
            }

            var _situacaoDescontoMostrar = $routeParams.situacoes
                                                      .split('-')
                                                      .filter(function(elem) {
                                                        return elem == 'Exibir Descontos';
                                                      });

            if (_situacaoDescontoMostrar.length) {
              dados.mostrarMedDesconto = true;
            } else {
              dados.mostrarMedDesconto = false;
            }

            relatoriosService.buscaUsuariosFiltro(dados).then(function(response) {
              vm.usuarios = (response.data);
            }, function(error) {
              console.log('Erro ', error);
            });

            dados.user_hash = Cookies.get('crm_funil_hash');
            relatoriosService.buscaNegociacoesUsuarios(dados).then(function(response) {

              if (!_situacaoDescontoMostrar.length) {                
                  for (var i = 0; i < response.data.length; i++) {
                    delete response.data[i].med_desc_percent;
                    delete response.data[i].med_desc_real;
                  }
              }

              if (response.data != 'null' && response.data.length != 0) {
                vm.listaNegUsuarios = response.data;
              } else {
                vm.listaNegUsuarios = '';
                vm.carregando = false;
              }
            }, function(error) {
              console.log('Erro: ', error);
            });
          });
        }

        vm.carregando = true;

        vm.acabou = function() {
          vm.carregando = false;
          vm.carregando_mais_inf = false;
        };

        vm.verificarNavegador = function() {
          // Opera 8.0+
          vm.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
          // Firefox 1.0+
          vm.isFirefox = typeof InstallTrigger !== 'undefined';
          // At least Safari 3+: "[object HTMLElementConstructor]"
          vm.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
          // Internet Explorer 6-11
          vm.isIE = /*@cc_on!@*/ false || !!document.documentMode;
          // Edge 20+
          vm.isEdge = !vm.isIE && !!window.StyleMedia;
          // Chrome 1+
          vm.isChrome = !!window.chrome && !!window.chrome.webstore;
          // Blink engine detection
          vm.isBlink = (vm.isChrome || vm.isOpera) && !!window.CSS;
        };
        vm.verificarNavegador();

        vm.verifDadosUser = function() {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var localNeg = window.localStorage.getItem('isModNegociacao');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          if (valLocalNeg.visualiza) {
            vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
          }
        }
        vm.verifDadosUser();

      }

  })();
